import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routers, RoutersInfo } from 'root';
import { Colors } from 'utils';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { UserInfo } from './core';
import { Images, Svg } from 'assets/images';
import { useHistory, useLocation } from 'react-router-dom';
import { authActions, PaymentActions } from '../../store';
import { GoBackButton, AlertSubscription } from 'components';
import { Accordion, AccordionDetails, AccordionSummary, ListItem } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const drawerWidth = 224;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function ExpandMoreIcon() {
  return null;
}

export const DrawerMenu = ({ myProfile }) => {
  /**
   * Hooks.
   */

  const dispatch = useDispatch();
  const router = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const pathPage = location?.pathname;
  const windowLoc = window.location.pathname;
  const linkPage = location?.pathname;
  const routersList = RoutersInfo(myProfile?.auth?.userType);

  const { activePackage } = useSelector((state) => ({
    activePackage: state.payment.activePackage,
  }));

  useEffect(() => {
    dispatch(authActions.getMyProfile());
    dispatch(PaymentActions.getMyPackage());
  }, []);

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  const handleOpenLeftMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleNavigate = (path) => {
    router.push(path);
    setOpenMenu(false);
  };
  const pathName = (name) => {
    switch (name) {
      case '/users':
        return 'Users';
      case '/trucks':
        return 'Trucks';
      case '/trailers':
        return 'Trailers';
      case '/drivers':
        return 'Drivers';
      case '/customers':
        return 'Customers';
      case '/loads':
        return 'Load';
      case '/billing':
        return 'Billing';
      case '/analytics':
        return 'Analytics';
        case '/payroll':
        return 'Payroll';
      default:
        return '';
    }
  };

  const renderLok = (type) => {
    if (windowLoc === type) {
      return true;
    } else if (windowLoc.slice(0, 5) === type.slice(0, 5)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {activePackage?.package === 'free' && <AlertSubscription />}

      <Box className='menu-bar-wrapper' sx={{ display: 'flex' }}
           marginTop={activePackage?.package === 'free' ? '56px' : ' 0'}>
        <div className={`header-wrapper`}>
          <div className='desktop-logo'>
            <picture className={`logo  ${open ? 'logo-open' : 'logo-close'} `} style={{ width: '220px' }}>
              <img src={Svg.LogoGreen} alt='Sistem-Logo' className={'logo-img'} />
            </picture>
            <div className='desktop-link'>
              <span className='page-name'>{pathName(pathPage)}</span>
            </div>
            <>
              {
                (linkPage !== '/trucks' &&
                  linkPage !== '/users' &&
                  linkPage !== '/trailers' &&
                  linkPage !== '/drivers' &&
                  linkPage !== '/customers' &&
                  linkPage !== '/loads' &&
                  linkPage !== '/analytics' &&
                  linkPage !== '/payroll' &&
                  linkPage !== '/billing')
                  ? (
                    <div>
                      <GoBackButton title={pathName(pathPage)} />
                    </div>
                  )
                  : null
              }
            </>
          </div>

          <div className='mobile-logo'>
            <button className='hanburger-btn' onClick={handleOpenLeftMenu}>
              {openMenu ? (
                <img src={Svg.HanburgerClose} alt='hanburger-close.svg' />
              ) : (
                <img src={Svg.DrawerMenu} alt='drawer-menu.svg' />
              )}
            </button>
            <div className='desktop-link'>
              <span className='page-name'>{pathName(pathPage)}</span>
            </div>
          </div>

          <UserInfo />
        </div>

        {/* Mobile Version Drawer  */}
        <SwipeableDrawer
          open={openMenu}
          onOpen={handleOpenLeftMenu}
          onClose={handleOpenLeftMenu}
          className='mobile-drawer'
          // marginTop={activePackage?.package === 'free' ? '120px' : '80px'}
        >
          <List className='list-style'>
            <ListItem className='logo-item'>
              <ListItemIcon>
                <picture className='logo'>
                  <img src={Svg.LogoGreen} alt='Sistem-Logo' className={'logo-img'} />
                </picture>
              </ListItemIcon>
            </ListItem>
            <div className='link-list' />

            <div className='mobile-link-list' style={{ padding: ' 0 8px' }}>
              {routersList.map((i, k) =>
                i.name === 'Billing' ? (
                  <div key={k} className={'accordion-styles'}>
                    <Accordion>
                      <AccordionSummary
                        className={
                          windowLoc === '/plansAndPricing' || windowLoc === '/payment' ? 'active-accordion' : ''
                        }
                        onClick={() => setOpen(true)}
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'>
                        <div className={'accordion-title'}>
                          <div>
                            {windowLoc === '/plansAndPricing' || windowLoc === '/payment' ? (
                              <img src={i.activeIcon} alt={i.alt} />
                            ) : (
                              <img src={i.icon} alt={i.alt} />
                            )}
                          </div>
                          {open && (
                            <p
                              style={{
                                color:
                                  windowLoc === '/plansAndPricing' || windowLoc === '/payment'
                                    ? Colors.theme.main
                                    : Colors.theme.gunmetal,
                                opacity: open ? 1 : 0,
                              }}>
                              {i.name}
                            </p>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ opacity: open ? 1 : 0 }} className='accordion-links'>
                          <button
                            className={
                              windowLoc === '/plansAndPricing' ? 'buttons-style-active' : 'buttons-style'
                            }
                            onClick={() => handleNavigate('/plansAndPricing')}>
                            Plans and Pricing
                          </button>
                          {activePackage?.isPackageActive !== false && (
                            <button
                              className={windowLoc === '/payment' ? 'buttons-style-active' : 'buttons-style'}
                              onClick={() => handleNavigate('/payment')}>
                              Payment
                            </button>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ) : (
                  <div key={k}>
                    <ListItemButton
                      onClick={() => handleNavigate(i.path)}
                      className={renderLok(i.path) ? 'items-wrapper-active' : 'items-wrapper'}
                      sx={{
                        px: 2.5,
                        background: renderLok(i.path) ? '#F4E5F3 0% 0% no-repeat padding-box' : 'transparent',
                      }}>
                      <ListItemIcon className='list-item-icon' sx={{ mr: open ? '8px' : '2px' }}>
                        {renderLok(i.path) ? (
                          <img src={i.activeIcon} alt={i.alt} />
                        ) : (
                          <img src={i.icon} alt={i.alt} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        className={'list-item-text'}
                        style={{
                          opacity: 1,
                          color: renderLok(i.path) ? '#0E9594' : '#506C84',
                        }}
                        primary={i.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </div>
                ),
              )}
            </div>
          </List>
        </SwipeableDrawer>

        {/* Desktop Version Drawer */}
        <Drawer
          variant='permanent'
          open={open}
          className='desktop-drawer'
          marginTop={activePackage?.package === 'free' ? '56px' : ' 0'}>
          <DrawerHeader sx={{ marginTop: activePackage?.package === 'free' ? 6 : 0 }}>
            <div>
              <IconButton className='icon-button' onClick={handleDrawerOpenClose}>
                <img src={open ? Images.CloseDrawer : Images.OpenDrawer} alt='Drawer-icon' />
              </IconButton>
            </div>
          </DrawerHeader>
          <List style={open ? { padding: '16px' } : { padding: '16px 8px' }} className='list-style'>
            {routersList.map((i, k) =>
              i.name === 'Billing' ? (
                <div key={k} className={'accordion-styles'}>
                  <Accordion>
                    <AccordionSummary
                      className={
                        windowLoc === '/plansAndPricing' || windowLoc === '/payment' ? 'active-accordion' : ''
                      }
                      onClick={() => setOpen(true)}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'>
                      <div className={'accordion-title'}>
                        <div>
                          {windowLoc === '/plansAndPricing' || windowLoc === '/payment' ? (
                            <img src={i.activeIcon} alt={i.alt} />
                          ) : (
                            <img src={i.icon} alt={i.alt} />
                          )}
                        </div>
                        {open && (
                          <p
                            style={{
                              color:
                                windowLoc === '/plansAndPricing' || windowLoc === '/payment'
                                  ? Colors.theme.main
                                  : Colors.theme.gunmetal,
                              opacity: open ? 1 : 0,
                            }}>
                            {i.name}
                          </p>
                        )}
                        {open ? (
                          <div className='biling-down'>
                            {windowLoc === '/plansAndPricing' || windowLoc === '/payment' ? (
                              <img src={Svg.BillingUp} alt='Billing-Up' />
                            ) : (
                              <img src={Svg.BillingDown} alt='Billing-Down' />
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ opacity: open ? 1 : 0 }} className='accordion-links'>
                        <button
                          className={windowLoc === '/plansAndPricing' ? 'buttons-style-active' : 'buttons-style'}
                          onClick={() => handleNavigate('/plansAndPricing')}>
                          Plans and Pricing
                        </button>
                        {activePackage?.isPackageActive !== false && (
                          <button
                            className={windowLoc === '/payment' ? 'buttons-style-active' : 'buttons-style'}
                            onClick={() => handleNavigate('/payment')}>
                            Payment
                          </button>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) : (
                <div key={k}>
                  <ListItemButton
                    onClick={() => handleNavigate(i.path)}
                    className={renderLok(i.path) ? 'items-wrapper-active' : 'items-wrapper'}
                    sx={{
                      px: 2.5,
                      background: renderLok(i.path) ? '#F4E5F3 0% 0% no-repeat padding-box' : 'transparent',
                    }}>
                    <ListItemIcon className='list-item-icon' sx={{ mr: open ? '8px' : '2px' }}>
                      {renderLok(i.path) ? <img src={i.activeIcon} alt={i.alt} /> : <img src={i.icon} alt={i.alt} />}
                    </ListItemIcon>
                    <ListItemText
                      className={'list-item-text'}
                      style={{ color: renderLok(i.path) ? Colors.theme.main : Colors.theme.gunmetal }}
                      primary={i.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </div>
              ),
            )}
          </List>
        </Drawer>

        <Box className='body-wrapper' component='main' sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader style={{ minHeight: '50px' }} />
          <Routers />
        </Box>
      </Box>
    </>
  );
};
