import {
  CREATE_PAYROLL_TIMESHEET, GET_PAYROLL_DRIVER_TIMESHEET,
  GET_PAYROLL_SINGLE_TIMESHEET,
  GET_PAYROLL_TIMESHEET, SEND_TO_PROCESS_PAYROLL_TIMESHEET,
  UPDATE_PAYROLL_TIMESHEET,
} from './payroll.type';
import { GET_DRIVER_TIMESHEET } from '../drivers/drivers.type';

/** Payroll Timesheet */

export const getPayrollTimesheet = (params) => {
  return {
    type: GET_PAYROLL_TIMESHEET,
    payload: {
      params,
    },
  };
};

export const getPayrollSingleTimesheet = (id) => {
  return {
    type: GET_PAYROLL_SINGLE_TIMESHEET,
    payload: {
      id,
    },
  };
};

export const createPayrollTimesheet = (data) => {
  return {
    type: CREATE_PAYROLL_TIMESHEET,
    payload: {
      data,
    },
  };
};

export const updatePayrollTimesheet = (id, data) => {
  return {
    type: UPDATE_PAYROLL_TIMESHEET,
    payload: {
      id,
      data,
    },
  };
};
export const sendToProcessing = (payrollIds) => {
  return {
    type: SEND_TO_PROCESS_PAYROLL_TIMESHEET,
    payload: {
      payrollIds,
    },
  };
};

export const getPayrollDriverTimesheet = (driverId, params) => {
  return {
    type: GET_PAYROLL_DRIVER_TIMESHEET,
    payload: {
      driverId,
      params,
    },
  };
};