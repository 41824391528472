import styled, { keyframes } from 'styled-components';
import { flash } from 'react-animations';

export const Container = styled.div`
    flex: ${({ open }) => (open ? 1 : 0)}!important;
    transform: translateX(${({ open }) => (open ? 0 : -5000)});
    max-width: 578px;
    transition: all 0.5s;
    padding: 32px 32px 0 32px;
    margin-top: -33px;
    margin-right: -33px;
    width: 100%;
    height: calc(80vh + 100px);
    background-color: #ffffff;
    overflow: auto;
    border-top: 1px solid  #E7EFFB;
    border-left: 1px solid #E7EFFB;
  
  @media (max-width: 1024px) {
    padding: 32px;
    margin-top: -16px;
    margin-right: -16px;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
  
    h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0;
        text-align: left;
    }
    ${({ open }) =>
        !open &&
        `
        padding:0 !important; 
        margin:0 !important;
        h4,
        h5,
        span,
        div{
         font-size: 0 !important;
         width:0; 
         height:0;
         margin:0 !important;
         box-shadow:none;
        };
        img{
          display:none !important
         }`};
    img {
    cursor: pointer;
    }
    @media (max-width: 768px) {
        position: fixed;
        right: 0;
        box-shadow: none !important;
        height: ${({ open }) => (open ? '100vh' : 0)};
        top: 0;
        z-index: 100001;
    }
`;
export const Head = styled.h5`
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #506c84;
`;
export const Details = styled.div`
    display: grid;
    grid-row-gap: ${({ open }) => (open ? 14 : 0)}px;
    margin-top: 42px;
    //margin-bottom: 22px;
    border: ${({ openModal }) => (openModal ? 1 : 0)}px solid #e7effb;
    border-radius: 8px;
    padding: ${({ openModal }) => (openModal ? 16 : 0)}px;
    .flex {
        margin-bottom: 24px;
    }
    span {
        font-size: ${({ open }) => (open ? 16 : 0)}px;
        line-height: ${({ open }) => (open ? 16 : 0)}px;
        letter-spacing: 0;
        text-align: left;
        color: #506c84;
        transition: font-size 0.6s;
      
      &.text-overflow-ellipsis {
        line-height: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
        &:first-child {
            font-weight: 600;
        }
        &:last-child {
            font-weight: 400;
        }
    }
    .flex img {
        cursor: pointer;
        transform: rotate(${({ open }) => (open ? 0 : 180)}deg);
        transition: transform 0.6s;
    }
    > .details {
        display: grid;
        grid-template-columns: ${({ open }) => (open ? '146px auto' : 0)};
        transition: height 0.6s;
        text-overflow: ellipsis;
        max-width: max-content;
        overflow: hidden;
    }
    > .show-hide {
        display: ${({ open }) => (open ? 'grid' : 'none')};
    }
    > .docs {
        grid-template-columns: ${({ open }) => (open ? '36px auto 25px' : 0)};
        grid-column-gap: ${({ open }) => (open ? '16px' : 0)};
        align-items: center;
    }
`;

export const Card = styled.div`
    padding: 16px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 12px;
    cursor: pointer;
    > div {
        > span {
            color: #41586d;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            &:first-child {
                font-weight: 700;
            }
        }
    }
    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const CardContainer = styled.div`
    padding: 16px;
`;

export const UploadContainer = styled.div`
    margin-bottom: 16px;
    width: 100%;
    padding: 0 16px;

  @media (min-width: 768px) {
    padding:0 32px;
  }

    > div {
        height: 200px;
        overflow-y: scroll;
        > div {
            padding: 16px 21px 16px;
            border-radius: 4px;
            border: 1px solid #e7effb;
            display: grid;
            grid-template-columns: 32px auto 10px;
            grid-column-gap: 16px;
            align-items: center;
            > img:last-child {
                cursor: pointer;
            }
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
    @media (max-width: 768px) {
        width: auto;
    }
`;
export const UploadLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border: 4px dashed #b3dee1;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    height: 228px;
    .loading {
        width: 100%;
    }
    > div {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #324557;
        > span {
            color: #0e9594;
        }
    }
`;

export const Loading = () => (
    <LoadAnim>
        <div></div>
        <div></div>
        <div></div>
    </LoadAnim>
);

const loading_type = keyframes`${flash}`;

const LoadAnim = styled.div`
    animation-name: ${loading_type};
    animation-iteration-count: infinite;
    animation-duration: 2s;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    div {
        background-color: #f5f7f9;
        &:first-child {
            border-radius: 35px;
            width: 80px;
            padding: 25px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            border-radius: 8px;
            width: 100%;
        }
    }
`;

export const NoFiles = styled.div`
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${({ open }) => (open ? '100%' : 0)};
    > img {
        display: ${({ open }) => (open ? 'block' : 'none')};
    }
    > p {
        font-size: ${({ open }) => (open ? '14px' : 0)};
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: #41586d;
        margin-top: 16px;
    }
`;

export const ImagesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    > div {
        width: 100%;
        height: 116px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        border: 1px solid #e7effb;
        > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #20313f;
            opacity: 0;
            transition: all 0.3s;
            > a {
                margin-bottom: -5px;
                margin-left: 16px;
            }
            > a,
            > img {
                cursor: pointer;
            }
            .MuiCircularProgress-root {
                margin: 0 !important;
            }
        }
        &:hover {
            > div {
                opacity: 0.6;
            }
        }
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
