import React from 'react';
import { Button } from '@mui/material';

export const MoreLessButton = ({ text, onClock, show, Svg, className, classNameButton, classNameSvg }) => {
  return (
    <div className={`more-less-button-wrapper ${className ? className : ''}`}>
      <Button
        variant='contained'
        onClick={onClock}
        className={`more-less-button ${classNameButton ? classNameButton : ''}`}
      >
        <p className='more-less-button-text'>
          {text}
        </p>
        <Svg className={`more-less-button-svg ${show ? 'show' : ''} ${classNameSvg ? classNameSvg : ''}`} />
      </Button>
    </div>
  );
};

