import { Route, Switch } from 'react-router-dom';
import {
  UsersPage,
  TrucksPage,
  TruckInfo,
  TrailersPage,
  TrailerInfo,
  DriversPage,
  DriverDetails,
  LoadBoard,
  LoadBoardInfo,
  CustomerPage,
  CustomerInfo,
  CheckoutPage,
  ActivePayment,
  AnalyticsPage,
  PayrollPage,
} from 'pages';
import { PlansAndPricing } from '../fragments';
import { useSelector } from 'react-redux';

export const Routers = () => {
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));

    return (
        <Switch>
            <Route exact path="/users" key={1} component={UsersPage} />
            <Route path="/trucks" component={TrucksPage} />
            <Route path={`/truck/:id`} component={TruckInfo} />
            <Route path={`/trailers`} component={TrailersPage} />
            <Route path={`/trailer/:id`} component={TrailerInfo} />
            <Route path={`/drivers`} component={DriversPage} />
            <Route path={`/driver/:id`} component={DriverDetails} />
            <Route path={`/customers`} component={CustomerPage} />
            <Route path={`/customer/:id`} component={CustomerInfo} />
            <Route path={`/loads`} component={LoadBoard} />
            <Route path={`/load/:id`} component={LoadBoardInfo} />
            <Route path={`/payroll`} component={PayrollPage} />
            <Route path="/plansAndPricing" component={PlansAndPricing} />

            {activePackage?.isPackageActive !== false && <Route path="/payment" component={ActivePayment} />}
            <Route path="/checkout" component={CheckoutPage} />
            <Route path="/analytics" component={AnalyticsPage} />
        </Switch>
    );
};
