import { call, put, takeLatest } from 'redux-saga/effects';
import { payrollService } from './payroll.service';

import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import {
  CREATE_PAYROLL_TIMESHEET, GET_PAYROLL_DRIVER_TIMESHEET,
  GET_PAYROLL_SINGLE_TIMESHEET,
  GET_PAYROLL_SINGLE_TIMESHEET_SUCCESS, GET_PAYROLL_TIMESHEET,
  GET_PAYROLL_TIMESHEET_SUCCESS, SEND_TO_PROCESS_PAYROLL_TIMESHEET, UPDATE_PAYROLL_TIMESHEET,
} from './payroll.type';


function* getPayrollTimesheet({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));

  try {
    const { data } = yield call(payrollService.getPayrollTimesheet, payload.params);
    yield put({
      type: GET_PAYROLL_TIMESHEET_SUCCESS,
      payload: {
        data,
      },
    });

    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getPayrollSingleTimesheet({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));

  try {
    const { data } = yield call(payrollService.getPayrollSingleTimesheet, payload?.id);
    yield put({
      type: GET_PAYROLL_SINGLE_TIMESHEET_SUCCESS,
      payload: {
        data,
      },
    });


    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* createPayrollTimesheet({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnLoadActions.appendLoading(GET_PAYROLL_SINGLE_TIMESHEET));

  try {
    const { data } = yield call(payrollService.createPayrollTimesheet, payload?.data);

    yield put({
      type: GET_PAYROLL_SINGLE_TIMESHEET_SUCCESS,
      payload: {
        data,
      },
    });

    yield put(httpRequestsOnLoadActions.removeLoading(GET_PAYROLL_SINGLE_TIMESHEET));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnLoadActions.removeLoading(GET_PAYROLL_SINGLE_TIMESHEET));
  }
}

function* updatePayrollTimesheet({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));

  yield put(httpRequestsOnLoadActions.appendLoading(GET_PAYROLL_SINGLE_TIMESHEET));

  try {
    const { data } = yield call(payrollService.updatePayrollTimesheetService, payload?.id, payload?.data);

    yield put({
      type: GET_PAYROLL_SINGLE_TIMESHEET_SUCCESS,
      payload: {
        data,
      },
    });

    yield put(httpRequestsOnLoadActions.removeLoading(GET_PAYROLL_SINGLE_TIMESHEET));

    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnLoadActions.removeLoading(GET_PAYROLL_SINGLE_TIMESHEET));
  }
}


function* sendToProcessPayrollTimesheet({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));

  try {
    yield call(payrollService.sendToProcessingService, payload?.payrollIds);

    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getPayrollDriverTimesheet({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));

  yield put(httpRequestsOnLoadActions.appendLoading(GET_PAYROLL_TIMESHEET));


  try {
    const { data } = yield call(payrollService.getPayrollDriverTimesheet, payload?.driverId, payload.params);

    yield put({
      type: GET_PAYROLL_TIMESHEET_SUCCESS,
      payload: {
        data
      },
    });

    yield put(httpRequestsOnLoadActions.removeLoading(GET_PAYROLL_TIMESHEET));

    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchPayrolls = function* watchPayrolls() {
  /** Payrolls */
  yield takeLatest(GET_PAYROLL_TIMESHEET, getPayrollTimesheet);
  yield takeLatest(GET_PAYROLL_SINGLE_TIMESHEET, getPayrollSingleTimesheet);
  yield takeLatest(CREATE_PAYROLL_TIMESHEET, createPayrollTimesheet);
  yield takeLatest(UPDATE_PAYROLL_TIMESHEET, updatePayrollTimesheet);
  yield takeLatest(SEND_TO_PROCESS_PAYROLL_TIMESHEET, sendToProcessPayrollTimesheet);
  yield takeLatest(GET_PAYROLL_DRIVER_TIMESHEET, getPayrollDriverTimesheet);
};