import { Checkbox } from '@mui/material';
import { DateRow, PriceRow, TextRow } from 'components';

export const payrollDriverHeader = ({ onChange, checked, active }) => {
  if (active) {
    return [
      {
        component: <Checkbox checked={checked} onChange={(e) => onChange(e, '', 'all')} />,
        width: '52px',
        maxWidth: '52px',
      },

      {
        name: 'driver',
        title: 'Driver',
        width: `15%`,
      },
      {
        name: 'date',
        title: 'Date',
        width: `27%`,
      },
      {
        name: 'totalHours',
        title: 'Total  Hours/ml',
        width: `26%`,
      },
      {
        name: 'totalPay',
        title: 'Total Pay',
        width: `26%`,
      },
    ];
  } else {
    return [
      {
        name: 'driver',
        title: 'Driver',
        width: `15%`,
      },
      {
        name: 'date',
        title: 'Date',
        width: `25%`,
      },
      {
        name: 'totalHours',
        title: 'Total  Hours/ml',
        width: `25%`,
      },
      {
        name: 'totalPay',
        title: 'Total Pay',
        width: `25%`,
      },
    ];
  }


};

export const payrollTimesheetHeader = ({ active, checked, onChange }) => {
  if (active) {
    return [
      {
        component: <div />,
      },
      {
        name: 'date',
        title: 'Date',
      },
      {
        name: 'totalHours',
        title: 'Total  Hours/ml',
      },
      {
        name: 'totalPay',
        title: 'Total Pay',
      },
    ];
  } else {
    return [
      {
        name: 'date',
        title: 'Date',
      },
      {
        name: 'totalHours',
        title: 'Total  Hours/ml',
      },
      {
        name: 'totalPay',
        title: 'Total Pay',
      },
    ];
  }


};

export const payrollDriverBody = ({ onChange, selectedDriverList, active }) => {
  if (!active) {
    return [
      {
        rowText: (item) => <TextRow name={item?.driverId} />,
        width: `15%`,
      },
      {
        rowText: (item) => <DateRow date={item?.createdDate} />,
        width: `27%`,
      },
      {
        rowText: (item) => <TextRow name={item?.hours} />,
        width: `26%`,
      },
      {
        rowText: (item) => <PriceRow place='suffix' info={+item?.totalAmount} />,
        width: `26%`,
      },
    ];
  } else {
    return [
      {
        button: (item, info, load) =>
          <Checkbox
            checked={selectedDriverList.includes(info?.id)} onChange={(e) => onChange(e, info, '')}
          />,
        width: '52px',
        minWidth: '52px',

      },
      {
        rowText: (item) => <TextRow name={item?.driverId} />,
        width: `15%`,
      },
      {
        rowText: (item) => <DateRow date={item?.createdDate} />,
        width: `27%`,
      },
      {
        rowText: (item) => <TextRow name={item?.hours} />,
        width: `26%`,
      },
      {
        rowText: (item) => <PriceRow place='suffix' info={+item?.totalAmount} />,
        width: `26%`,
      },
    ];
  }
};

export const payrollTimesheetBody = ({ onChange, selectedList, active }) => {

  if (!active) {
    return [
      { rowText: (item) => <TextRow name={item?.totalHours} /> },
      { rowText: (item) => <TextRow name={item?.totalHours} /> },
      { rowText: (item) => <PriceRow place='suffix' info={+item?.totalPay} /> }];
  } else {
    return [
      {
        button: (item, info, load) =>
            <Checkbox
              checked={selectedList.includes(info?.id)} onChange={(e) => onChange(e, info, '')}
            />
          ,
        width:'42px'
      },
      { rowText: (item) => <TextRow name={item?.totalHours} /> },
      { rowText: (item) => <TextRow name={item?.totalHours} /> },
      { rowText: (item) => <PriceRow place='suffix' info={+item?.totalPay} /> },
    ];
  }
};