import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { customersAction } from 'store/customers';
import { truckActions } from 'store';
import {
    FullTable,
    TableWrapper,
    useModal,
    MobileCard,
    Loader,
    PaginationItem,
    FilterMenu,
    NoDataComponent,
    CreateMobileButton,
} from 'components';
import { ACTION_TYPE, customersHead, customerBody, CustomerMenu, customerStatus } from './constants';
import { FindLoad, MODAL_NAMES, SaveParams, SendPageSave } from 'utils';

const title = 'Customers';

export const CustomerPage = () => {
    /**
     * Customers Page.
     */

    const { customerList } = useSelector((state) => ({
        customerList: state?.customer?.customerList,
    }));
    const loader = FindLoad(ACTION_TYPE);
    const { open } = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const dispatch = useDispatch();

    const handleCreateCustomer = () => {
        open(MODAL_NAMES.ADD_CUSTOMER, { modalTitle: 'Add Customer' });
    };

    const handleClick = (item, name) => {
        if (name === 'DELETE_DRIVER') {
            dispatch(truckActions.deleteTruck(item?.id));
        } else if (name === 'Activate' || name === 'Inactivate') {
            const status = name === 'Activate';
            dispatch(truckActions.changeTruckStatus(status, item?.id));
        }
    };

    const handleFilterStatus = (event) => {
        SaveParams(history, { ...info, status: event?.target?.defaultValue });
    };

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 10;
        if (info?.status && info?.status !== 'ALL') {
            filteredInfo.status = info.status;
        } else {
            delete filteredInfo.status;
        }
        delete filteredInfo.page;
        dispatch(customersAction.getCustomer({ ...filteredInfo }));
    }, [info]);

    return (
        <div className="customers-container page-container">
            <div className="desktop-view">
                <TableWrapper
                    firstButton={'Active'}
                    secondButton={'Inactive'}
                    addButton={'Add Customer'}
                    buttonsTab={true}
                    buttonsTabAddButton={true}
                    handleIniteUser={handleCreateCustomer}
                    wrapperTitile={title}>
                    <FullTable
                        head={customersHead}
                        body={customerBody}
                        loadingType={ACTION_TYPE}
                        list={customerList?.customers}
                        listCount={customerList?.count}
                        noText={title}
                        handleClickButton={handleClick}
                        handleClick={(id) => history.push({ pathname: `/customer/${id}` })}
                    />
                </TableWrapper>
            </div>
            <div className="mobile-view">
                <div className="wrapper-header">
                    <div className="header-title">
                        <h4 className="title">{'Customers'}</h4>
                    </div>
                    <div className="header-btn">
                        <div className="filter-menu">
                            <FilterMenu
                                title={' Filter the Status'}
                                list={customerStatus}
                                status={info?.status}
                                handleFilterStatus={handleFilterStatus}
                            />
                        </div>
                        <CreateMobileButton handleClick={handleCreateCustomer} />
                    </div>
                </div>
                {loader?.length ? (
                    <Loader />
                ) : customerList?.customers?.length ? (
                    <div className="mobile-card">
                        {customerList?.customers?.map((item, index) => (
                            <div key={index} className="card-box">
                                <MobileCard
                                    poperList={CustomerMenu}
                                    actionType={ACTION_TYPE}
                                    item={item}
                                    handleClickButton={handleClick}
                                    handleClick={(id) => history.push({ pathname: `/customer/${item.id}` })}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div style={{ height: '100%' }}>
                        <NoDataComponent />
                    </div>
                )}
            </div>
            {customerList?.count !== 0 && (
                <div className="mobile-pagination">
                    <PaginationItem
                        listLength={customerList?.count}
                        page={info?.page}
                        handleReturn={(number) => SendPageSave(number, info, history)}
                        count={customerList?.count}
                    />
                </div>
            )}
        </div>
    );
};
