import React, { forwardRef, useState } from 'react';
import { Checkbox } from '@mui/material';
import { Svg } from 'assets/images';
import { MoreLessButton, SubmitButton, TooltipMain } from 'components';
import moment from 'moment';
import { formatAmount } from 'utils';
import { PriceConvertor } from '../../components/table';

export const TimesheetCard = (
  {
    onChange,
    checked,
    lineClampNotes,
    allowLength,
    active = true,
    item,
    onEdit,
    onProcessingClick,
    actionType,
    path,
  }) => {
  const [toggleShow, setShow] = useState(false);

  const handleClick = () => {
    setShow(prev => !prev);
  };
  return (<div className='timesheet-drive-card-wrapper'>
      <div className={`timesheet-drive-card-header ${!active ? 'timesheet-drive-card-header-padding' : ''}`}>
        <div className='price-and-text'>

          {active && <Checkbox
            className='price-and-text-checkbox'
            checked={checked}
            onChange={onChange}
          />}

          <p className='price' style={{ color: path === 'payroll' ? '#049494' : '' }}>
            {path === 'payroll' ? 'Driver ID:' : 'Total Price:'}
          </p>

          <p className='text' style={{ color: path === 'payroll' ? '#049494' : '' }}>
            {path === 'payroll' ? item?.driverId : formatAmount(item?.totalAmount)}
          </p>
        </div>
        <img
          className='edit-btn'
          src={Svg.EditLoad}
          onClick={onEdit}
          alt='edit' />
      </div>
      <div className='timesheet-drive-card-main-content'>
        <div className='less-detail'>
          <div className='timesheet-drive-card-items-block'>
            <div className='timesheet-drive-card-item'>
             <span className='timesheet-drive-card-item-name'>
                  Date:
             </span>
              <span className='timesheet-drive-card-item-params'>
                  {item?.createdDate ? moment(item?.createdDate).format('DD/MM/YYYY') : 'Not Set'}
             </span>
            </div>

            <div className='timesheet-drive-card-item'>
             <span className='timesheet-drive-card-item-name'>
                  Total Hours:
             </span>
              <span className='timesheet-drive-card-item-params'>
                {item?.hours ? item?.hours : 'Not Set'}
             </span>
            </div>
          </div>
        </div>

        <div className={`show-more-detail ${toggleShow ? 'hide-more-detail' : ''}`}>
          <div className='more-detail'
          >
            <div className='timesheet-drive-card-items-block'>
              <div className='timesheet-drive-card-item'>
             <span className='timesheet-drive-card-item-name'>
                  Truck Number:
             </span>
                <span className='timesheet-drive-card-item-params'>
                  {item?.truckNumber ? item?.truckNumber : 'Not Set'}
             </span>
              </div>

              <div className='timesheet-drive-card-item'>
             <span className='timesheet-drive-card-item-name'>
                  Load ID:
             </span>
                <span className='timesheet-drive-card-item-params'>
                {item?.loadId ? item?.loadId : 'Not Set'}
             </span>
              </div>

              <div className='timesheet-drive-card-item'>
             <span className='timesheet-drive-card-item-name'>
                  Start Date/Time:
             </span>
                <span className='timesheet-drive-card-item-params'>
                  {item?.startDate ? `${moment(item?.startDate).format('DD/MM/YYYY')} ${moment(item?.startDate).format('h:mm A')}` : 'Not Set'}
             </span>
              </div>

              <div className='timesheet-drive-card-item'>
             <span className='timesheet-drive-card-item-name'>
                  End Date/Time:
             </span>
                <span className='timesheet-drive-card-item-params'>
                  {item?.endDate ? `${moment(item?.endDate).format('DD/MM/YYYY')} ${moment(item?.endDate).format('h:mm A')}` : 'Not Set'}

             </span>
              </div>
              {
                path === 'payroll'
                &&
                <div className='timesheet-drive-card-item'>
             <span className='timesheet-drive-card-item-name'>
                  Total Price:
             </span>
                  <span className='timesheet-drive-card-item-params'>
                   {item?.totalAmount ? PriceConvertor({ price: item?.totalAmount }) : 'Not Set'}

             </span>
                </div>
              }

              <div className='timesheet-drive-card-item'>
             <span className='timesheet-drive-card-item-name'>
                 Price:
             </span>

                <span className='timesheet-drive-card-item-params'>
                 {item?.price ? PriceConvertor({ price: item?.price }) : 'Not Set'}
              </span>
              </div>
            </div>
          </div>

          <div className='timesheet-drive-card-notes-block'>
            <span style={{ marginRight: '5px' }} className='timesheet-drive-card-item-name'>
              Note:
            </span>

            <TooltipMain
              text={item?.note}
              content={`${item?.note || 'Not Set'}`}
              lineClamp={lineClampNotes}
              allowLength={allowLength}
            />
          </div>

          {
            active
            &&
            <SubmitButton
              actionType={actionType}
              classNameWrapper='timesheet-card-send-btn'
              onClick={() => onProcessingClick(item?.id)}
              icon={Svg.LoadingSvg}
              title='Send to processing'
            />
          }
        </div>


      </div>

      <MoreLessButton
        onClock={handleClick}
        className='timesheet-card-more-less'
        Svg={Svg.ArrowDownSvg}
        show={toggleShow}
        text={!toggleShow ? 'More Details' : 'Less Details'}
      />
    </div>
  );
};




