/** Combined Sagas */

import { fork } from 'redux-saga/effects';
import { watchAuth } from 'store/auth';
import { watchDrivers } from 'store/drivers';
import { watchTruck } from 'store/truck';
import { watchUser } from 'store/user';
import { watchLoad } from 'store/load';
import { watchTrailer } from 'store/trailers';
import { watchCustomers } from 'store/customers';
import { watchUserPayment } from 'store/payment';
import { watchNotes } from 'store/notes';
import { watchAnalytics } from 'store/analytics';
import { watchPayrolls } from '../payroll';

export const appSaga = function* startForman() {
    yield fork(watchAuth);
    yield fork(watchUserPayment);
    yield fork(watchUser);
    yield fork(watchTruck);
    yield fork(watchDrivers);
    yield fork(watchLoad);
    yield fork(watchTrailer);
    yield fork(watchCustomers);
    yield fork(watchNotes);
    yield fork(watchAnalytics);
    yield fork(watchPayrolls)
};
