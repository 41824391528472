import React, { useEffect } from 'react';
import { ButtonsTab, ButtonWithSvg, CalendarRange, CustomSelect } from 'components';
import { useWindowDimensions } from 'utils';
import { driversActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';


export const FilterPayroll = (
  {
    onClickTabs, onClickAddButton, control, handleSaveDate, handleCancelDate,
    handleChangeDate, showCalendar, setShowCalendar, date,

  }) => {
  const { driversList } = useSelector((state) => state?.drivers);
  const { drivers } = driversList;
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();


  useEffect(() => {
    dispatch(driversActions?.getDriver());
  }, [dispatch]);

  return (
    <div className='payroll-buttons-calendar-input-block'>

      {width >= 1279 &&
        <>
          <ButtonsTab
            first={'Active'}
            second={'Processing'}
            width={'118px'}
            onClick={onClickTabs}
          />

          <div className='payroll-calendar-block'>
            <CalendarRange
              handleSaveDate={handleSaveDate}
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              handleChangeDate={handleChangeDate}
              date={date}
              handleCancelDate={handleCancelDate}
            />

            <CustomSelect
              name='driverId'
              rules={{ required: true }}
              control={control}
              listSelect={drivers}
              displayValue={'id'}
              displayName={'firstName'}
              placeholder='Choose Driver Type'
            />

            <ButtonWithSvg
              text='Add Timesheet'
              onClick={onClickAddButton}
            />

          </div>
        </>
      }


      {(width < 1279 && width > 767) &&
        <div className='payroll-buttons-calendar-input-block-tablet'>
          <div className='payroll-tabs-button-tablet'>
            <ButtonsTab
              first={'Active'}
              second={'Processing'}
              width={'118px'}
              onClick={onClickTabs}
            />
            <div className='payroll-calendar-block'>
              <ButtonWithSvg
                text='Add Timesheet'
                onClick={onClickAddButton}
              />
            </div>
          </div>

          <div className='payroll-input-calendar'>
            <div className='payroll-filter-search'>
              <CustomSelect
                name='driverId'
                rules={{ required: true }}
                control={control}
                listSelect={drivers}
                displayValue={'id'}
                displayName={'firstName'}
                placeholder='Choose Driver Type'
              />
            </div>


            <CalendarRange
              className='payroll-buttons-calendar-input-block-tablet_calendar'
              handleSaveDate={handleSaveDate}
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              handleChangeDate={handleChangeDate}
              date={date}
              handleCancelDate={handleCancelDate}
            />
          </div>

        </div>
      }

      {width <= 767 &&

        <div className='payroll-buttons-calendar-input-block-mobile'>
          <div className='payroll-calendar-button-mobile'>
            <CalendarRange
              handleSaveDate={handleSaveDate}
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              handleChangeDate={handleChangeDate}
              date={date}
              handleCancelDate={handleCancelDate}
            />

            <ButtonWithSvg
              text='Add Timesheet'
              onClick={onClickAddButton}
            />
          </div>

          <CustomSelect
            name='driverId'
            rules={{ required: true }}
            control={control}
            listSelect={drivers}
            displayValue={'id'}
            displayName={'firstName'}
            placeholder='Choose Driver Type'
          />

          <ButtonsTab
            first={'Active'}
            second={'Processing'}
            width={'118px'}
            onClick={onClickTabs}
          />
        </div>
      }
    </div>
  );
};

