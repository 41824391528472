import React, { useState, Fragment, useContext, createContext } from 'react';
import { CustomModal } from '../../components';

export const ModalContext = createContext();

export const CustomModalProvider = ({ children }) => {
    const [activeModal, setActiveModal] = useState('');
    const [currentComponent, setCurrentComponent] = useState(null);
    const [params, setParams] = useState(null);

    return (
        <Fragment>
            <ModalContext.Provider
                value={{
                    activeModal,
                    setActiveModal,
                    params,
                    setParams,
                    currentComponent,
                    setCurrentComponent
                }}
            >
                {children}
            </ModalContext.Provider>
        </Fragment>
    );
};

export const useModalBase = () => {
    const { params, setParams, currentComponent, setCurrentComponent } = useContext(ModalContext);

    return {
        openModal: ( comp = {}, params = {}) => {
            setCurrentComponent(comp)
            setParams(params);
        },
        closeModal: () => {
          setCurrentComponent(null);
          setParams({});
        },
      currentComponent,
      params
    };
};


export const CustomModals = () => {
    const { currentComponent } = useModalBase();

    return (
      <>
        {currentComponent &&
          <CustomModal
          openDefault={!!currentComponent}
          content={
            <div>
              {currentComponent}
            </div>
          }
        />}

      </>
    );
};