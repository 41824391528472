import React, { useMemo } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useSelector } from 'react-redux';
import * as Styled from '../styled';
import dayjs from 'dayjs';

const Chart = () => {
    const { analyticsList } = useSelector(({ analytics }) => analytics.analytics);
    const { activeTab } = useSelector(({ analytics }) => analytics);

    const chartData = useMemo(() => {
        const labels = analyticsList?.map((item) => dayjs(item.key).format("MMM"));

        const data = {
            data: analyticsList?.map((item) => item?.value[activeTab]) || [],
            color: '#0E9594',
            curve: 'linear',
        };

        for (let item of analyticsList) {
            data.label = (
                <Styled.Flex dir="column">
                    <Styled.Flex jc="flex-start">
                        <span>Loads:</span>
                        <span>{item?.value?.loadQty}</span>
                    </Styled.Flex>
                    <Styled.Flex jc="flex-start">
                        <span>Profit:</span>
                        <span>{item?.value?.profit}</span>
                    </Styled.Flex>
                    <Styled.Flex jc="flex-start">
                        <span>Revenue:</span>
                        <span>{item?.value?.revenue}</span>
                    </Styled.Flex>
                    <Styled.Flex jc="flex-start">
                        <span>Cost:</span>
                        <span>{item?.value?.cost}</span>
                    </Styled.Flex>
                </Styled.Flex>
            );
        }
        return { data, labels };
    }, [analyticsList, activeTab]);

    return (
        <div className="chart">
            <LineChart
                height={300}
                series={chartData?.labels?.length ? [chartData?.data] : []}
                xAxis={[{ scaleType: 'point', data: chartData?.labels }]}
                sx={{
                    '.MuiLineElement-root, .MuiMarkElement-root': {
                        strokeWidth: 2,
                    },
                    '.MuiChartsLegend-root': {
                        display: 'none',
                    },
                    '.MuiLineElement-series-uvId': {
                        strokeDasharray: '3 8 4 4',
                    },
                    '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)': {
                        fill: '#fff',
                    },
                    '& .MuiMarkElement-highlighted': {
                        stroke: 'none',
                    },
                    '.MuiChartsAxis-tickLabel': {
                        fill: '#41586D',
                    },
                }}
            />
        </div>
    );
};

export default Chart;
