import { Button } from '@mui/material';
import { SubmitButton } from './submitButton';
import { useModal } from '../modal';
import { useModalBase } from 'utils';

export const CreateCancel = ({ actionType, title, maxWidth, onConfirm, onCancel }) => {
    const { close } = useModal();
    const { closeModal } = useModalBase();

    const handleClose = () => {
        close();
        closeModal();
        if (onCancel) onCancel();
    };

    return (
        <div className="create-cancel-wrapper" style={maxWidth ? { maxWidth: maxWidth } : {}}>
            <Button className="cancel-button" color="info" onClick={handleClose}>
                <span className="button-text">Cancel</span>
            </Button>
            <SubmitButton title={title} type="submit" actionType={actionType} onClick={onConfirm} />
        </div>
    );
};
