import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MinLoader } from 'components';
import { FindLoad, FindSuccess, useModalBase } from 'utils';
import { httpRequestsOnSuccessActions } from 'store';
import { ModalHeaderNoPadding } from './modalHeader';

export const CustomDelete = ({ actionType, text, handleDelete, doYou }) => {
    const { closeModal } = useModalBase();
    const loader = FindLoad(actionType);
    const success = FindSuccess(actionType);
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
            closeModal();
        }
    }, [success]);


    return (
        <div className='delete-modal'>
            <ModalHeaderNoPadding />
            <div className='delete-modal-title'>
                <p className='want-delete'>{doYou} </p>
                <span className='delete-title'>{text}</span>
            </div>
            {/*<div className='delete-subtitle'>{params?.subTitle}</div>*/}
            <div className='delete-modal-buttons-wrapper'>
                <button className='cancel-button' onClick={() => closeModal()}>
                    Cancel
                </button>
                <button className='delete-button' onClick={handleDelete}>
                    {loader ? <MinLoader color={'white'} /> : 'Delete'}
                </button>
            </div>
        </div>
    );
};
