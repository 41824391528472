import { call, put, takeLatest } from 'redux-saga/effects';
import { driversService } from './drivers.service';
import {
    GET_DRIVERS,
    GET_DRIVERS_SUCCESS,
    CHANGE_DRIVER_STATUS,
    CREATE_DRIVERS,
    EDIT_DRIVER_DETAILS,
    GET_DRIVER_DETAILS,
    GET_DRIVER_DETAILS_SUCCESS,
    DELETE_DRIVERS,
    EDIT_DRIVERS_RATE,
    GET_DRIVERS_RATE,
    GET_DRIVERS_RATE_SUCCESS,
    CREATE_DRIVER_DOCUMENT,
    EDIT_DRIVER_DOCUMENT_ARCHIVE,
    DELETE_DRIVER_DOCUMENT,
    GET_DRIVER_DOCUMENT,
    GET_DRIVER_DOCUMENT_SUCCESS,
    DRIVER_CREATE_FUEL,
    DRIVER_EDIT_FUEL,
    DRIVER_DELETE_FUEL,
    REIVITE_DRIVERS,
    CREATE_DRIVER_TIMESHEET,
    GET_DRIVER_TIMESHEET,
    GET_DRIVER_TIMESHEET_SUCCESS,
    GET_DRIVER_SINGLE_TIMESHEET_SUCCESS,
    GET_DRIVER_SINGLE_TIMESHEET,
    UPDATE_DRIVER_TIMESHEET,
    SEND_TO_PROCESS_DRIVER_TIMESHEET,
} from './drivers.type';
import { httpRequestsOnErrorsActions } from 'store/http_requests_on_errors';
import { httpRequestsOnLoadActions } from 'store/http_requests_on_load';
import { httpRequestsOnSuccessActions } from 'store/http_requests_on_success';

/** Driver Actions */
function* createDrivers({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.createDriver, payload?.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getDriversList({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(driversService.getDriver, payload?.body);
        yield put({
            type: GET_DRIVERS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* changeDriverStatus({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.changeDriverStatus, payload.id, payload.status);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editDriverDetails({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(driversService.editDriverDetails, payload.body, payload.id);
        yield put({
            type: GET_DRIVER_DETAILS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getDriversDetails({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(driversService.getDriverDetails, payload?.body, payload.id);
        yield put({
            type: GET_DRIVER_DETAILS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteDriver({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.deleteDriver, payload);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End */

/** Driver Rate */
function* editDriversRate({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.editDriverRate, payload.body, payload.id);
        yield put({
            type: GET_DRIVER_DETAILS,
            payload: { id: payload?.id, load: 'noLoad' },
        });

        yield put({
            type: GET_DRIVERS_RATE,
            payload: { id: payload?.id, load: 'noLoad' },
        });
        yield put({
            type: GET_DRIVER_DETAILS_SUCCESS,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getDriversRate({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(driversService.getDriverRate, payload?.body, payload.id);
        yield put({
            type: GET_DRIVERS_RATE_SUCCESS,
            payload: res.data.reverse(),
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End */

/** Driver Fuel */
function* createDriverFuel({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.createDriverFuel, payload.body, payload?.id);
        yield put({
            type: GET_DRIVER_DETAILS,
            payload: { id: payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editDriverFuel({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.editDriverFuel, payload.body, payload?.driverId, payload.id);
        yield put({
            type: GET_DRIVER_DETAILS,
            payload: { id: payload?.driverId, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteDriverFuel({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.deleteDriverFuel, payload.driverId, payload.id);
        yield put({
            type: GET_DRIVER_DETAILS,
            payload: { id: payload?.driverId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End */

/** Driver Documents */
function* createDriverDocument({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.createDriverDocument, payload?.id, payload?.body);
        yield put({
            type: GET_DRIVER_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getDriverDocument({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(driversService.getDriverDocument, payload?.id);
        yield put({
            type: GET_DRIVER_DOCUMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editDriverDocumentArchive({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.editDriverDocumentArchive, payload.id, payload.docId, payload.archive);
        yield put({
            type: GET_DRIVER_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteDriverDocument({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.deleteDriverDocument, payload.id, payload.docId);
        yield put({
            type: GET_DRIVER_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End */

// ReInvite Driver.

function* reInvite({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(driversService.reInvite, payload.id);
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getDriverTimesheet({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    try {
        const { data } = yield call(driversService.getDriverTimesheet, payload?.driverId, payload.params);
        yield put({
            type: GET_DRIVER_TIMESHEET_SUCCESS,
            payload: {
                data
            },
        });

        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getDriverSingleTimesheet({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    try {
        const { data } = yield call(driversService.getDriverSingleTimesheet, payload?.id);
        yield put({
            type: GET_DRIVER_SINGLE_TIMESHEET_SUCCESS,
            payload: {
                data
            },
        });


        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* createDriverTimesheet({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(GET_DRIVER_SINGLE_TIMESHEET));

    try {
        const { data } =  yield call(driversService.createDriverTimesheet, payload?.data);
        yield put({
            type: GET_DRIVER_SINGLE_TIMESHEET_SUCCESS,
            payload: {
                data
            },
        });

        yield put(httpRequestsOnLoadActions.removeLoading(GET_DRIVER_SINGLE_TIMESHEET));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnLoadActions.removeLoading(GET_DRIVER_SINGLE_TIMESHEET));
    }
}

function* updateDriverTimesheet({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    yield put(httpRequestsOnLoadActions.appendLoading(GET_DRIVER_SINGLE_TIMESHEET));

    try {
        const { data } =  yield call(driversService.updateDriverTimesheetService, payload?.id, payload?.data);

        yield put({
            type: GET_DRIVER_SINGLE_TIMESHEET_SUCCESS,
            payload: {
                data
            },
        });

        yield put(httpRequestsOnLoadActions.removeLoading(GET_DRIVER_SINGLE_TIMESHEET));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnLoadActions.removeLoading(GET_DRIVER_SINGLE_TIMESHEET));
    }
}


function* sendToProcessDriverTimesheet({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    try {
        yield call(driversService.sendToProcessingService, payload?.payrollIds);

        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}



export const watchDrivers = function* watchDriversAuth() {
    /** Driver Actions */
    yield takeLatest(GET_DRIVERS, getDriversList);
    yield takeLatest(CREATE_DRIVERS, createDrivers);
    yield takeLatest(CHANGE_DRIVER_STATUS, changeDriverStatus);
    yield takeLatest(EDIT_DRIVER_DETAILS, editDriverDetails);
    yield takeLatest(GET_DRIVER_DETAILS, getDriversDetails);
    yield takeLatest(DELETE_DRIVERS, deleteDriver);
    /** Driver Rate */
    yield takeLatest(EDIT_DRIVERS_RATE, editDriversRate);
    yield takeLatest(GET_DRIVERS_RATE, getDriversRate);
    /** Driver Fuel */
    yield takeLatest(DRIVER_CREATE_FUEL, createDriverFuel);
    yield takeLatest(DRIVER_EDIT_FUEL, editDriverFuel);
    yield takeLatest(DRIVER_DELETE_FUEL, deleteDriverFuel);
    /** Driver Documents */
    yield takeLatest(CREATE_DRIVER_DOCUMENT, createDriverDocument);
    yield takeLatest(GET_DRIVER_DOCUMENT, getDriverDocument);
    yield takeLatest(EDIT_DRIVER_DOCUMENT_ARCHIVE, editDriverDocumentArchive);
    yield takeLatest(DELETE_DRIVER_DOCUMENT, deleteDriverDocument);
    /** End */

    // ReInvite Driver.

    yield takeLatest(REIVITE_DRIVERS, reInvite);

    //** Driver Create Timesheet */
    yield takeLatest(GET_DRIVER_TIMESHEET, getDriverTimesheet)
    yield takeLatest(GET_DRIVER_SINGLE_TIMESHEET, getDriverSingleTimesheet)
    yield takeLatest(CREATE_DRIVER_TIMESHEET, createDriverTimesheet)
    yield takeLatest(UPDATE_DRIVER_TIMESHEET, updateDriverTimesheet)
    yield takeLatest(SEND_TO_PROCESS_DRIVER_TIMESHEET, sendToProcessDriverTimesheet)
};
