import React, { useState } from 'react';
import { UploadServices } from 'store';
import * as Styled from './styled';
import { Svg } from 'assets/images';
import { docLogo } from 'utils';
import { LinearProgress } from '@mui/material';
import NoUploadedFiles from './noUploadedFiles';


export const UploadDocument = ({ onChange, value, docs = [] }) => {
  const [loading, setLoading] = useState(false);
  const [hoveredClassName, setHoveredClassName] = useState('');
  const handleChange = async (e) => {
    try {
      setLoading(true);
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append('files', e.target.files[0]);
        const res = await UploadServices.UploadImage(formData);

        if (res) {
          onChange(res.data);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Styled.UploadContainer>
      <label
        className={`maintenance-file-upload-label ${hoveredClassName ? hoveredClassName : ''}`}
        htmlFor='maintenance-file-upload-input-id'
      >
        {loading ? (
          <div className='loading'>
            <LinearProgress style={{ background: '#E1F2F3' }} />
          </div>
        ) : (
          <>
            <input
              id='maintenance-file-upload-input-id'
              className='maintenance-file-upload-input'
              type='file'
              onChange={handleChange}
              onMouseEnter={() => setHoveredClassName('hovered-label')}
              onMouseLeave={() => setHoveredClassName('')}
              onDragEnter={() => setHoveredClassName('hovered-label')}
              onDragLeave={() => setHoveredClassName('')}
            />

            <div className='maintenance-right-modal-files'>
              <div className='maintenance-file-upload-content-img'>
                <img src={Svg.Upload} alt='Upload' />
              </div>

              <p className='maintenance-file-upload-content-browse-file'>
                Drag & Drop or <span>Browse</span> file
              </p>
              <span>Supported formats: pdf, jpeg, png</span>
            </div>

          </>
        )}
      </label>
      <div>
        {docs.length
          ? docs.map((item) => (
              <div key={item.id}>
                <img alt='doc' width='42px' height='42px' src={docLogo(item?.file?.url)} />
                <span>{item?.file?.name || item?.file?.originalName}</span>
                <img
                  alt='remove'
                  src={Svg.CloseRed}
                  onClick={() => {
                    onChange(item, 'remove');
                  }}
                />

              </div>
            ),
          ) : <NoUploadedFiles open={true} />
        }

      </div>
    </Styled.UploadContainer>
  );
};
