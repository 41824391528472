import {
  getPayrollTimesheet,
  getPayrollSingleTimesheet,
  createPayrollTimesheet,
  updatePayrollTimesheet,
  sendToProcessing,
  getPayrollDriverTimesheet
} from './payroll.action';

export { payrollReducer } from './payroll.reducer';
export { watchPayrolls } from './payroll.saga';

export const payrollActions = {
  /** Payroll Timesheet */
  getPayrollTimesheet,
  getPayrollSingleTimesheet,
  createPayrollTimesheet,
  updatePayrollTimesheet,
  sendToProcessing,
  getPayrollDriverTimesheet
};