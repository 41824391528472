import styled from 'styled-components';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { analyticActions } from '../../store/analytics';
import { Images } from 'assets/images';
export const Container = styled.div`
    padding: 42px 42px 0 42px;
    display: grid;
    position: relative;
    .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        color: #324557;
    }
    .analytics-switch {
        margin-bottom: 21px;
        margin-left: auto;
    }

    .desktop-view {
        padding-top: 36px;
    }
    @media (max-width: 768px) {
        > div:first-child {
            flex-direction: column;
            > div {
                position: relative;
            }
        }
    }
`;

export const Flex = styled.div`
    display: flex;
    width:${({ width }) => width || '100%'}
    align-items: ${({ ai = 'center' }) => ai};
    justify-content: ${({ jc = 'center' }) => jc};
    flex: ${({ flex }) => flex};
    gap: ${({ gap }) => gap};
    flex-direction: ${({ dir }) => dir || 'row'};
    margin: ${({ margin }) => margin};
    position:relative;
`;

export const OverviewCard = ({ num, children, active, title }) => {
    const dispatch = useDispatch();
    const { activeTab } = useSelector(({ analytics }) => analytics);
    return (
        <Card
            active={activeTab === active}
            dir="column"
            ai="flex-start"
            gap="4px"
            title={title}
            num={num}
            onClick={() => dispatch(analyticActions.changeAnalyticsActiveTab(active))}>
            {children}
        </Card>
    );
};

const Card = styled(Flex)`
    flex: 1;
    padding-inline: 24px;
    border-bottom: ${({ active }) => (active ? '4px solid #0E9594' : '4px solid #F5F7F9')};
    cursor: pointer;
    overflow: hidden;

    > span:first-child {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #41586d;
    }
    > span:last-child {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        color: ${({ num }) => (Number(num) >= 0 ? '#41586d' : '#D03325')};
    }
    > span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
    @media (max-width: 1250px) {
        padding-inline: 10px;
        > span {
            font-size: 24px !important;
        }
    } ;
`;

export const Tab = styled.div`
    padding: 6px 32px;
    background-color: ${({ active }) => (active ? '#0E9594' : 'transparent')};
    color: ${({ active }) => (active ? '#FFFFFF' : '#324557')};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    border-radius: 4px;
    cursor: pointer;
    text-transform: capitalize;
`;

export const Chart = styled.div`
    background-color: #fff;
    border-radius: 8px;
    padding: 32px;
    margin-top: 27px;
    margin-bottom: 21px;
    .chart {
        background-image: url(${Images.ChartGrid});
        background-size: calc(100% - 100px) 74%;
        background-repeat: no-repeat;
        background-position: 50px 28px;
        .MuiChartsAxisTooltipContent-row {
            background: red;
        }
    }
   
`;

export const TabContainer = styled(Flex)`
    background-color: #f5f7f9;
    padding: 4px;
    width: fit-content;
    border-radius: 8px;
`;

export const List = styled.div`
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
`;

export const PickerContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 8;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    height: ${({ show }) => (show ? '407px' : 0)};
    min-width: 430px;
    .date-range {
        position: absolute;
        z-index: 9;
        top: 16px;
        background-color: #ffffff;
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > img {
            margin-inline: 16px;
        }
        > span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #324557;
            white-space: nowrap;
            &:first-child {
                margin-right: 16px;
            }
        }
    }
    .create-cancel-wrapper {
        margin-left: auto;
        max-width: 300px;
        position: absolute;
        right: 16px;
        bottom: 0;
        .cancel-button {
            background: none;
        }
        .add-button {
            z-index: 1;
        }
    }
`;
export const Picker = styled(StaticDateRangePicker)`
    display: block;
    min-height: 350px;

    .MuiDateRangeCalendar-root {
        position: absolute;
        top: 0;
        right: 0;
        background: #ffffff;
        border-radius: 8px;
        > div {
            display: none !important;
        }
        .MuiDateRangeCalendar-monthContainer {
            display: block !important;
            border: none !important;
            margin-top: 40px;
        }
    }
    .MuiPickersToolbar-root {
        display: none !important;
    }
    .MuiList-root {
        max-width: 500px !important;
        margin-top: 16px;
        .MuiListItem-root {
            padding: 0 !important;
            .MuiButtonBase-root {
                border-radius: 0;
                width: 100%;
                justify-content: flex-start;

                padding: 0 !important;
                background: none;
                text-align: left;
                .MuiChip-label {
                    text-align: left;
                }
            }
        }
    }
    .MuiChip-label {
        width: 100%;
        line-height: 2.5;
    }
`;

export const PickerLabel = styled.label`
    font-size: 16px;
    font-weight: 400;
    display: block;
    letter-spacing: 0px;
    text-align: left;
    padding-inline: 24px;
    width: 100%;
    height: 100%;
    background-color: ${({ active }) => (active ? '#E1F2F3' : 'transparent')};
    color: ${({ active }) => (active ? '#0E9594' : '#324557')};
`;

export const TableCard = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    background: #f5f7f9;
    margin-top: 12px;
    border-radius: 4px;
    > div > span {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #41586d;
        &:last-child {
            font-weight: 400;
            margin-left: 8px;
            margin-right: 16px;
        }
    }
`;

export const Modal = styled.div`
    position: relative;
    background-color: #fff;
    height: auto;
    padding: 24px 24px 0 24px;
    align-items: center;
    .MuiDateRangeCalendar-root {
        position: relative;
    }
    .MuiDateRangeCalendar-monthContainer {
        margin-top: 0 !important;
    }
    .MuiDialogActions-root {
        display: none;
    }
    .create-cancel-wrapper {
        position: relative;
        margin: 0 auto 24px;
        height: 71px;
    }
`;
