import React, { useEffect, useState, Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FindLoad, FindSuccess, SendPageSave } from 'utils';
import { PaginationItem, Loader, NoDataComponent } from 'components';
import { useSelector } from 'react-redux';
import { Svg } from 'assets/images';

export const AccordionTable = (
  {
    head, body, list, loadingType, handleClick,
    noText, margin, listCount, handleClickButton,
    accordionDetailsComp, classNameWrapper,
    onSort,
  }) => {

  const [listInfo, setListInfo] = useState('');
  const [expanded, setExpanded] = useState(null);

  const { activePackage } = useSelector((state) => ({
    activePackage: state.payment.activePackage,
  }));

  const history = useHistory();
  const info = history?.location?.state;
  const loader = FindLoad(loadingType ? loadingType : '');
  const miniLoadSuccess = FindSuccess('MINI_LOAD');


  useEffect(() => {
    if (list) {
      setListInfo(list);
    }
  }, [list]);

  const changePage = (number) => {
    SendPageSave(number, info, history);
  };

  const handleName = (item, name) => {
    handleClickButton(item, name);
  };

  const renderItems = (i, item) => {
    if (item) {
      if (item?.button) {
        return item?.button((name) => handleName(i, name), i, miniLoadSuccess && miniLoadSuccess?.data === i?.auth?.id);
      } else {
        return item?.rowText(i, activePackage);
      }
    }
  };


  const handleChange = (id) => {
    const checkId = expanded === id ? null : id;
    setExpanded(checkId);
  };

  if (loader?.length) return <Loader />;

  return (
    <div className={`accordion-table-wrapper ${classNameWrapper ? classNameWrapper : ''}`}>

      <div className='accordion-table-header'>
        <div className='accordion-table-row'>
          {head?.map((i, j) => i !== false && (

              <Fragment key={j}>
                  <div className='accordion-table-call'
                       style={{
                         width: `${i?.width}` || 'auto',
                         maxWidth: `${i?.maxWidth}` || 'none',
                         minWidth: `${i?.minWidth}` || 'none',
                       }}
                  >
                    {(i.sortable && i?.component) ?
                      <>
                        <div className='accordion-table-component'>
                          {i.component}
                        </div>

                        <p className='accordion-table-text'>
                          {i?.title}
                        </p>

                        <img
                          src={Svg.Sort}
                          className='accordion-table-img'
                          onClick={() => {
                            if (i.onSort) i.onSort(i);
                          }}
                        />
                      </>
                      : i.sortable ?
                        <>
                          <p className='accordion-table-text'>
                            {i?.title}
                          </p>

                          <img
                            src={Svg.Sort}
                            className='accordion-table-img'
                            onClick={() => {
                              if (i.onSort) i.onSort(i);
                            }}
                          />
                        </>
                        : i?.component
                          ? <>
                            <div className='accordion-table-component'>
                              {i.component}
                            </div>

                            <p className='accordion-table-text'>
                              {i?.title}
                            </p>
                          </>
                          : <>
                            <p className='accordion-table-text'>
                              {i?.title}
                            </p>
                          </>
                    }

                  </div>

              </Fragment>
            ),
          )}
          <div style={{ width: '50px' }} className='accordion-table-call' />
        </div>

      </div>


      {listInfo?.length ? (
        listInfo?.map((i, j) => (
          <Fragment key={j}>
            <Accordion expanded={expanded === i?.id} onChange={(e) => handleChange(i?.id)}>
              <AccordionSummary
                expandIcon={<img src={Svg.ArrowDown} />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                {body?.map((item, ke) => {
                  if (item) {
                    return (
                      <div className='accordion-table-call'
                           key={ke}
                           style={{
                             width: `${item?.width}` || 'auto',
                             maxWidth: `${item?.maxWidth}` || 'none',
                             minWidth: `${item?.minWidth}` || 'none',
                           }}
                           onClick={() => item?.notClickable !== true && handleClick && handleClick(i.id)}>
                        {renderItems(i, item) || 'Not Set'}
                      </div>
                    );
                  }
                })}

              </AccordionSummary>
              <AccordionDetails>
                {accordionDetailsComp}
              </AccordionDetails>
            </Accordion>
          </Fragment>
        ))
      ) : (
        <div style={{ height: '100%', width: '100%' }}></div>
      )}

      {listInfo?.length === 0 && (
        <div style={{ height: '100%', width: '100%' }}>
          <NoDataComponent />
        </div>
      )}
      {listCount > 0 && (
        <PaginationItem
          listLength={list?.length}
          page={info?.page}
          handleReturn={(number) => changePage(number)}
          count={listCount}
        />
      )}

    </div>
  );
};
