import React, { useEffect, useState, Fragment } from 'react';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FindLoad, FindSuccess, SendPageSave } from 'utils';
import { PaginationItem, Loader, NoDataComponent } from 'components';
import BasicPopover from './poper';
import { useSelector } from 'react-redux';
import { Svg } from 'assets/images';

export const FullTable = (
  {
    head,
    body,
    list,
    loadingType,
    handleClick,
    noText,
    margin,
    listCount,
    handleClickButton,
    onSort,
    className,
    classNameTbodyRow,
  }) => {
  /**
   * Full Table.
   */

  const { activePackage } = useSelector((state) => ({
    activePackage: state.payment.activePackage,
  }));

  const history = useHistory();
  const info = history?.location?.state;
  const loader = FindLoad(loadingType ? loadingType : '');
  const miniLoadSuccess = FindSuccess('MINI_LOAD');
  const [listInfo, setListInfo] = useState('');

  useEffect(() => {
    if (list) {
      setListInfo(list);
    }
  }, [list]);

  const changePage = (number) => {
    SendPageSave(number, info, history);
  };

  const handleName = (item, name) => {
    handleClickButton(item, name);
  };

  const renderItems = (i, item) => {
    if (item) {
      if (item?.button) {
        return item?.button((name) => handleName(i, name), i, miniLoadSuccess && miniLoadSuccess?.data === i?.auth?.id);
      } else {
        return item?.rowText(i, activePackage);
      }
    }
  };

  if (loader?.length) return <Loader />;

  return (
    <div className={`full-table-styles ${className ? className : ''}`}>
      <div style={margin ? { margin: 0 } : {}} className='table-wrapper'>
        <TableContainer className='table-container' component={Paper}>
          <Table stickyHeader className='table' size='small' aria-label='a dense table'>
            <TableHead className='table-head'>
              <TableRow>
                {loadingType === 'GET_USER' && <TableCell />}
                {head &&
                  head.map(
                    (i, j) =>
                      i !== false && (
                        <TableCell
                          key={j}
                          sx={{
                            width: `${i?.width}` || 'auto',
                            maxWidth: `${i?.maxWidth}` || 'none',
                            minWidth: `${i?.minWidth}` || 'none',
                          }}
                        >
                          {i?.type === 'type' || i.type === 'status' ? (
                            <BasicPopover
                              name={i?.name}
                              title={i?.title}
                              list={i?.list}
                              labelTitle={i?.type === 'type' ? 'Filter the Type' : 'Filter the Status'}
                            />
                          ) : i?.component
                            ? <div className='sortable-table-row-component'
                                   style={i?.componentStyles ? { ...i?.componentStyles } : {}}
                            >
                              {i.component}
                            </div>
                            : i.sortable
                              ? (
                                <div
                                  className='sortable-table-row-item'
                                >
                                  <p
                                    className='sortable-table-row-text'
                                    style={i?.textStyles ? { ...i?.textStyles } : {}}
                                  >
                                    {i?.title}
                                  </p>

                                  <img
                                    src={Svg.Sort}
                                    className='sortable-table-row-img'
                                    onClick={() => {
                                      if (i.onSort) i.onSort(i);
                                    }}
                                  />
                                </div>
                              )
                              : (
                                <div
                                  className='sortable-table-row-item'
                                >
                                  <p
                                    className='sortable-table-row-text'
                                    style={i?.textStyles ? { ...i?.textStyles } : {}}
                                  >
                                    {i?.title}
                                  </p>
                                </div>
                              )}
                        </TableCell>
                      ),
                  )}
              </TableRow>
            </TableHead>
            <tbody>
            {listInfo?.length ? (
              listInfo?.map((i, j) => (
                <Fragment key={j}>
                  <TableRow className={`table-row ${classNameTbodyRow ? classNameTbodyRow : ''}`}>
                    {body?.map((item, ke) => {
                      if (item) {
                        return (
                          <TableCell
                            key={ke}
                            sx={{
                              width: `${item?.width}` || 'auto',
                              maxWidth: `${item?.maxWidth}` || 'none',
                              minWidth: `${item?.minWidth}` || 'none',
                            }}
                            onClick={() => item?.notClickable !== true && handleClick && handleClick(i.id)}>
                            {renderItems(i, item) || 'Not Set'}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                </Fragment>
              ))
            ) : (
              <div style={{ height: '100%', width: '100%' }}></div>
            )}
            </tbody>
          </Table>
          {listInfo?.length === 0 && (
            <div style={{ height: '100%', width: '100%' }}>
              <NoDataComponent />
            </div>
          )}
          {listCount > 0 && (
            <PaginationItem
              listLength={list?.length}
              page={info?.page}
              handleReturn={(number) => changePage(number)}
              count={listCount}
            />
          )}
        </TableContainer>
      </div>
    </div>
  );

};
