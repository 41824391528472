import { GET_PAYROLL_SINGLE_TIMESHEET_SUCCESS, GET_PAYROLL_TIMESHEET_SUCCESS } from './payroll.type';


const initialState = {
  timesheetList: {
    count: 0,
    timesheets: []
  },
  singleTimesheet:{}
};

export const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    /** Driver Timesheet */
    case GET_PAYROLL_TIMESHEET_SUCCESS:
      return {
        ...state,
        timesheetList: action?.payload?.data,
      };
    case GET_PAYROLL_SINGLE_TIMESHEET_SUCCESS:
      return {
        ...state,
        singleTimesheet: action?.payload?.data,
      };
    /** End */

    default:
      return state;
  }
};