/** Driver Timesheet */
export const GET_PAYROLL_TIMESHEET = 'GET_PAYROLL_TIMESHEET';
export const GET_PAYROLL_TIMESHEET_SUCCESS = 'GET_PAYROLL_TIMESHEET_SUCCESS';

export const GET_PAYROLL_SINGLE_TIMESHEET = 'GET_PAYROLL_SINGLE_TIMESHEET';
export const GET_PAYROLL_SINGLE_TIMESHEET_SUCCESS = 'GET_PAYROLL_SINGLE_TIMESHEET_SUCCESS';

export const CREATE_PAYROLL_TIMESHEET = 'CREATE_PAYROLL_TIMESHEET';

export const UPDATE_PAYROLL_TIMESHEET = 'UPDATE_PAYROLL_TIMESHEET';

export const SEND_TO_PROCESS_PAYROLL_TIMESHEET = 'SEND_TO_PROCESS_PAYROLL_TIMESHEET'

export const GET_PAYROLL_DRIVER_TIMESHEET = 'GET_DRIVER_TIMESHEET';