import React from 'react';
import { Svg } from 'assets/images';

export const ButtonWithSvg = ({ text, onClick, className }) => {
  return (
    <button onClick={onClick} className={`button-with-svg ${className ? className : ''}`}>
      <Svg.PlusSvg className='svg-button' />
      <p className='text-button'>
        {text}
      </p>
    </button>
  );
};

