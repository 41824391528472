import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FullTable, NoDataComponent } from 'components';
import { table_head, table_body } from '../constants';
import { getAnalyticsCustomer, getAnalyticsDriver, getAnalyticsTrailer, getAnalyticsTruck } from 'store/analytics/analytics.action';
import Card from './card';
export const Tables = ({ active_table, filter }) => {
    const analytics_state = useSelector(({ analytics }) => analytics);
    const dispatch = useDispatch();

    useEffect(() => {
        switch (active_table) {
            case 'trucks': {
                dispatch(getAnalyticsTruck(filter));
                break;
            }
            case 'trailers': {
                dispatch(getAnalyticsTrailer(filter));
                break;
            }
            case 'customers': {
                dispatch(getAnalyticsCustomer(filter));
                break;
            }
            case 'drivers': {
                dispatch(getAnalyticsDriver(filter));
                break;
            }
            default: {
                dispatch(getAnalyticsCustomer(filter));
                break;
            }
        }
    }, [dispatch, active_table, filter]);

    const renderLoadingType = ( ) => {
        switch (active_table) {
            case 'trucks': {
                return 'GET_ANALYTICS_TRUCKS'
            }
            case 'trailers': {
              return 'GET_ANALYTICS_TRAILER'
            }
            case 'customers': {
               return 'GET_ANALYTICS_CUSTOMER'
            }
            case 'drivers': {
              return 'GET_ANALYTICS_DRIVER'
            }
            default: {
               return ''
            }
        }
    }

    return (
        <>
            <FullTable
                head={table_head[active_table]}
                body={table_body[active_table]}
                listCount={analytics_state?.[active_table] ? analytics_state[active_table]?.length : 0}
                list={analytics_state[active_table] ? analytics_state[active_table] : []}
                loadingType={renderLoadingType()}
            />
            <div className="mobile-view">
                {analytics_state?.[active_table]?.length === 0 ? (
                    <div style={{ height: '100%', width: '100%' }}>
                        <NoDataComponent />
                    </div>
                ) : (
                    <Card active_table={active_table} body={analytics_state[active_table]} />
                )}
            </div>
        </>
    );
};
