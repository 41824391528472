import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { CustomInputForCalendar } from './customInputs';
import { CreateCancel, SubmitButton } from '../buttons';
import { useModalBase, useWindowDimensions } from 'utils';
import { useHistory } from 'react-router-dom';
import { Svg } from '../../assets/images';

export const CalendarRange = (
  {
    date,
    showCalendar,
    setShowCalendar,
    handleChangeDate,
    handleSaveDate,
    handleCancelDate,
    className,
  }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [position, setPosition] = useState(0);
  const { openModal, closeModal } = useModalBase();
  const info = history?.location?.state;

  useEffect(() => {
    const closeModal = () => setShowCalendar(false);
    window.addEventListener('click', closeModal);

    return () => {
      window.removeEventListener('click', closeModal);
    };

  }, []);

  useEffect(() => {
    const elem = document.querySelector('.main-calendar-wrapper-and-buttons');
    if (elem) {
      const rect = elem.getBoundingClientRect();
      if (rect.left < 0) {
        setPosition((rect.left * -2));
      } else if (rect.right < 0) {
        setPosition(rect.right * -1.5);
      } else {
        setPosition(0);

      }
    }

  }, [width]);

  useEffect(() => {
    if (showCalendar && width <= 767) {
      openModal(
        <div className='main-calendar-wrapper-and-buttons position-static'
             onClick={(e) => {
               e.stopPropagation();
             }}
        >
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <img
              className='close-button'
              src={Svg.CloseBtn}
              alt='close-modal'
              onClick={() => {
                setShowCalendar(false);
                closeModal();
              }}
            />
          </div>

          <div style={{ padding: '0 0 20px' }}>
            <DatePicker
              onChange={handleChangeDate}
              startDate={date?.startDate}
              endDate={date?.endDate}
              selected={date?.startDate}
              selectsRange={true}
              monthsShown={1}
              inline
            />

            <div className='custom-datepicker-footer'>
              <SubmitButton
                title={'Done'}
                type='submit'
                actionType={''}
                onClick={handleSaveDate}
              />
            </div>
          </div>

        </div>,
      );
    }
  }, [showCalendar, info?.startDate, info?.endDate]);

  const showingCalendar = () => {
    setShowCalendar(true);
  };
  return (
    <div className={`main-calendar-wrapper ${className ? className : ''}`}>
      <CustomInputForCalendar
        onClick={showingCalendar}
        date={date}
      />
      {(showCalendar && width > 767) &&
        <div className='main-calendar-wrapper-and-buttons'
             style={{ transform: `translate(${position}px, 10px)` }}
             onClick={(e) => {
               e.stopPropagation();
             }}
        >
          <DatePicker
            onChange={handleChangeDate}
            customInput={<></>}
            startDate={date?.startDate}
            endDate={date?.endDate}
            selectsRange={true}
            monthsShown={2}
            inline
          />

          <div className='custom-datepicker-footer'>
            <CreateCancel
              title='Done'
              maxWidth='316px'
              onConfirm={handleSaveDate}
              onCancel={() => {
                handleCancelDate();
                setShowCalendar(false);
              }}
            />
          </div>
        </div>
      }
    </div>
  );
};

