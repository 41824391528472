import React, { useState } from 'react';
import * as Styled from './styled';
import { DateFilter, Overview, Chart, List } from './fragment';
import { Switch } from 'components';
import dayjs from 'dayjs';

const AnalyticsPage = () => {
    const [showPrev, setShowPrev] = useState(true);
    const today = dayjs();
    const [filter, setFilter] = useState({
        start: new Date(today.startOf('month').$d).toISOString(),
        end: new Date(today.$d).toISOString(),
    });

    return (
        <Styled.Container>
            <Styled.Flex jc="space-between" ai="center">
                <div className="title">
                  Overview
                </div>

                <DateFilter
                  filter={filter}
                  setFilter={setFilter}
                />
            </Styled.Flex>

            <Styled.Chart>
                <Overview
                  filter={filter}
                />
                <Chart filter={filter} showPrev={showPrev} />
            </Styled.Chart>
            <Switch onChange={() => setShowPrev((p) => !p)} value={showPrev} label="Compare Previous Data" className="analytics-switch" />
            <List filter={filter} />
        </Styled.Container>
    );
};

export default AnalyticsPage;
