export const table_head = {
    customers: [
        { name: 'customerID', title: 'Customer ID' },
        { name: 'loadsQty', title: 'Loads  Quantity' },
        { name: 'loadMile', title: 'Load Mile' },
        { name: 'paid', title: 'Paid' },
        { name: 'unpaid', title: 'Unpaid' },
    ],
    drivers: [
        { name: 'driverID', title: 'Driver' },
        { name: 'loadsQty', title: 'Loads  Quantity' },
        { name: 'loadMile', title: 'Load Mile' },
        { name: 'loadAmount', title: 'Loads Amount' },
        { name: 'paid', title: 'Paid' },
    ],
    trucks: [
        { name: 'truckID', title: 'Truck ID' },
        { name: 'loadsQty', title: 'Loads  Quantity' },
        { name: 'loadMile', title: 'Load Mile' },
        { name: 'loadAmount', title: 'Loads Amount' },
        { name: 'cost', title: 'Cost' },
    ],
    trailers: [
        { name: 'trailerID', title: 'Trailer ID' },
        { name: 'loadsQty', title: 'Loads  Quantity' },
        { name: 'loadMile', title: 'Load Mile' },
        { name: 'loadAmount', title: 'Loads Amount' },
        { name: 'cost', title: 'Cost' },
    ],
};

export const table_body = {
    customers: [
        { rowText: (item) => item.customerID },
        { rowText: (item) => item.loadsQty },
        { rowText: (item) => item.loadMile },
        { rowText: (item) => item.paid },
        { rowText: (item) => item.unpaid },
    ],
    drivers: [
        { rowText: (item) => item.driverID },
        { rowText: (item) => item.loadsQty },
        { rowText: (item) => item.loadMile },
        { rowText: (item) => item.loadAmount },
        { rowText: (item) => item.paid },
    ],
    trucks: [
        { rowText: (item) => item.truckID },
        { rowText: (item) => item.loadsQty },
        { rowText: (item) => item.loadMile },
        { rowText: (item) => item.loadAmount },
        { rowText: (item) => (typeof item.cost === 'number' ? `${item.cost}` : undefined) },
    ],
    trailers: [
        { rowText: (item) => item.trailerID },
        { rowText: (item) => item.loadsQty },
        { rowText: (item) => item.loadMile },
        { rowText: (item) => item.loadAmount },
        { rowText: (item) => (typeof item.cost === 'number' ? `${item.cost}` : undefined) },
    ],
};
