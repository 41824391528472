import React from 'react';
import { Svg } from 'assets/images';
import { Loader, SubmitButton, TooltipMain } from 'components';
import moment from 'moment';
import { PriceConvertor } from '../../table';

export const TimesheetRightModal = (
  {
    classNameWrapper,
    classNameHeader,
    classNameItems,
    classNameNotesWrapper,
    onClose,
    lineClampNotes,
    active,
    allowCloseButton = true,
    openModal,
    singleTimesheet,
    loading,
    onProcessingClick,
    actionType,
    path
  }) => {

  return (
    <div
      className={`timesheet-right-modal ${!active ? 'timesheet-right-modal-process' : ''} ${classNameWrapper ? classNameWrapper : ''}`}>
      {loading
        ? <Loader />
        : <>
          <div>
            <div className={`timesheet-right-modal-header ${classNameHeader ? classNameHeader : ''}`}>
              <p className='timesheet-right-modal-details'>
                Details
              </p>
              <div className='close-edit-btns'>
                {active &&
                  <img
                    className='dit-btn'
                    src={Svg.EditLoad}
                    onClick={openModal}
                    alt='edit' />
                }


                {allowCloseButton &&
                  <img
                    className='close-button'
                    src={Svg.CloseBtn}
                    alt='close-modal'
                    onClick={onClose}
                  />}

              </div>
            </div>

            <div className='timesheet-right-modal-main-content'>
              <div className='border-bottom'>
                <div className={`timesheet-right-modal-main-content-items ${classNameItems ? classNameItems : ''}`}>
                  <div className='items-1'>
                    <div className='flex'>
                      <p className='timesheet-right-modal-items-name'>
                        Truck Number
                      </p>

                      <p className='timesheet-right-modal-items-param'>
                        {singleTimesheet?.truckNumber ? singleTimesheet?.truckNumber : 'Not Set'}
                      </p>
                    </div>

                    <div className='flex'>
                      <p className='timesheet-right-modal-items-name'>
                        Load ID
                      </p>

                      <p className='timesheet-right-modal-items-param'>
                        {singleTimesheet?.loadId ? singleTimesheet?.loadId : 'Not Set'}
                      </p>
                    </div>

                    <div className='flex'>
                      <p className='timesheet-right-modal-items-name'>
                        Type
                      </p>

                      <div className='timesheet-right-modal-items-param'>
                        {
                          singleTimesheet?.type
                            ? singleTimesheet?.type[0].toUpperCase() + singleTimesheet?.type.slice(1).toLowerCase()
                            : 'Not Set'
                        }
                      </div>
                    </div>
                  </div>

                  <div className='items-2'>
                    <div className='flex'>
                      <p className='timesheet-right-modal-items-name'>
                        Start Date/Time
                      </p>

                      <p className='timesheet-right-modal-items-param'>
                        {singleTimesheet?.startDate
                          ? `${moment(singleTimesheet?.startDate).format('DD/MM/YYYY')} ${moment(singleTimesheet?.startDate).format('h:mm A')}`
                          : 'Not Set'
                        }
                      </p>
                    </div>

                    <div className='flex'>
                      <p className='timesheet-right-modal-items-name'>
                        End Date/Time
                      </p>

                      <p className='timesheet-right-modal-items-param'>
                        {singleTimesheet?.endDate
                          ? `${moment(singleTimesheet?.endDate).format('DD/MM/YYYY')} ${moment(singleTimesheet?.endDate).format('h:mm A')}`
                          : 'Not Set'
                        }
                      </p>
                    </div>

                    {
                      path === 'payroll'
                    &&
                      <div className='flex'>
                        <p className='timesheet-right-modal-items-name'>
                          Total Price
                        </p>

                        <p className='timesheet-right-modal-items-param'>
                          {singleTimesheet?.totalAmount
                            ? PriceConvertor({ price: singleTimesheet?.totalAmount })
                            : 'Not Set'
                          }
                        </p>
                      </div>
                    }

                    <div className='flex'>
                      <p className='timesheet-right-modal-items-name'>
                        Price
                      </p>

                      <p className='timesheet-right-modal-items-param'>
                        {singleTimesheet?.price
                          ? PriceConvertor({ price: singleTimesheet?.price })
                          : 'Not Set'
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <div className={`timesheet-right-modal-notes ${classNameNotesWrapper ? classNameNotesWrapper : ''}`}>
                <div className='timesheet-right-modal-notes-block'>
                  <div className='timesheet-right-modal-notes-image'>
                    <img src={Svg.NoteIcon} alt='notes' />
                  </div>

                  <div className='timesheet-right-modal-note'>
                    {`Note${classNameNotesWrapper ? ':' : ''}`}
                  </div>
                </div>

                <div className='timesheet-right-modal-note-text'>
                  <TooltipMain
                    text={`${singleTimesheet?.note || 'Not Set'}`}
                    content={`${singleTimesheet?.note || 'Not Set'}`}
                    lineClamp={lineClampNotes}
                    allowLength={109}
                  />
                </div>
              </div>
            </div>
          </div>
          {
            active &&
            <div className='timesheet-right-modal-send-btn-block'>
              <SubmitButton
                onClick={() => onProcessingClick(singleTimesheet?.id)}
                classNameWrapper='timesheet-right-modal-send-btn'
                icon={Svg.LoadingSvg}
                actionType={actionType}
                title='Send to processing'
              />
            </div>
          }
        </>
      }
    </div>
  );
};

