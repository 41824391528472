import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CustomModalProvider, CustomModals, initAxiosInterceptors } from 'utils';
import 'assets/styles/allStyles.scss';
import { ModalProvider } from 'components/modal/modalProvider';
import { AllModals } from 'fragments/componentModal';
import { ScrollToTop } from 'components';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

initAxiosInterceptors();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <BrowserRouter>
            <ScrollToTop />
            <Provider store={store}>
                <CustomModalProvider>
                    <ModalProvider>
                        <App />
                        <AllModals />
                        <CustomModals />
                    </ModalProvider>
                </CustomModalProvider>
            </Provider>
        </BrowserRouter>
);

reportWebVitals();
