/**  Service */
import axios from 'axios';

export const payrollService = {
  /** Payroll Timesheet */

  getPayrollTimesheet: (params) => axios.get(`/payroll`, {
    auth: true,
    params: { ...params },
  }),

  getPayrollSingleTimesheet: (id) => axios.get(`/payroll/timesheet/${id}`, {
    auth: true,
  }),

  createPayrollTimesheet: (body) => axios.post(`/payroll`, { ...body }, { auth: true }),

  updatePayrollTimesheetService: (id, body) => axios.patch(`/payroll/${id}`, { ...body }, { auth: true }),

  sendToProcessingService: (payrollIds) => axios.post(`/payroll/timesheet/process`,
    {
      payrollIds: [...payrollIds],
    }, { auth: true }),


  getPayrollDriverTimesheet: (driverId, params) => axios.get(`/payroll/timesheet/driver/${driverId}`, {
    auth: true,
    params: { ...params },
  }),
};
