import * as Styled from './styled';
export const Switch = ({ label, onChange, value, labelClass, switchClass, className, style }) => {
    return (
        <Styled.Container onClick={onChange} value={value} className={className} style={style}>
            <Styled.Switch checked={value} className={switchClass}>
                <div></div>
            </Styled.Switch>
            <span className={labelClass}>{label}</span>
        </Styled.Container>
    );
};
