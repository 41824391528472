import { call, put, takeLatest } from 'redux-saga/effects';
import { truckService } from './truck.service';
import {
    CREATE_TRUCK,
    GET_TRUCK,
    GET_TRUCK_SUCCESS,
    CHANGE_TRUCK_STATUS,
    EDIT_TRUCK_DETAILS,
    DELETE_TRUCK,
    CREATE_TRUCK_DOCUMENT,
    GET_TRUCK_DOCUMENT,
    GET_TRUCK_DOCUMENT_SUCCESS,
    EDIT_TRUCK_DOCUMENT_ARCHIVE,
    DELETE_TRUCK_DOCUMENT,
    GET_TRUCK_DETAILS,
    GET_TRUCK_DETAILS_SUCCESS,
    GET_TRUCK_LOADS,
    GET_TRUCK_LOADS_SUCCESS,
    CREATE_TRUCK_COSTS,
    GET_TRUCK_COSTS,
    GET_TRUCK_COSTS_SUCCCESS,
    GET_TRUCK_MAINTENANCE,
    UPLOAD_TRUCK_MAINTENANCE_DOCUMENT,
    GET_TRUCK_MAINTENANCE_SUCCESS,
    UPLOAD_TRUCK_MAINTENANCE_DOCUMENT_SUCCESS,
    GET_MAINTENANCE_SERVICE_BY_ID,
    GET_MAINTENANCE_SERVICE_BY_ID_SUCCESS,
    CREATE_MAINTENANCE_SERVICE,
    UPDATE_MAINTENANCE_SERVICE,
} from './truck.type';
import { httpRequestsOnErrorsActions } from 'store/http_requests_on_errors';
import { httpRequestsOnLoadActions } from 'store/http_requests_on_load';
import { httpRequestsOnSuccessActions } from 'store/http_requests_on_success';

/* Create Truck */
function* createTruck({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(truckService.createTruck, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Get Truck */
function* getTruckList({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(truckService.getTruck, payload?.body);
        yield put({
            type: GET_TRUCK_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Change Status Truck */
function* changeTruckStatus({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(truckService.changeTruckStatus, payload.id, payload.status);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Edit Truck Load */
function* editTruckDetails({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(truckService.editTruckDetails, payload.body, payload.id);
        yield put({
            type: GET_TRUCK_DETAILS,
            payload: { id: payload.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/* Get Truck Details */
function* getTruckDetails({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(truckService.getTruckDetails, payload?.id);
        yield put({
            type: GET_TRUCK_DETAILS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Delete Truck  */
function* deleteTruck({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(truckService.deleteTruck, payload);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getTruckLoads({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(truckService.getTruckLoadService, payload?.id, payload?.info);
        yield put({
            type: GET_TRUCK_LOADS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** Truck Documents */
function* createTruckDocument({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(truckService.createTruckDocument, payload?.id, payload?.body);
        yield put({
            type: GET_TRUCK_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getTruckDocument({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(truckService.getTruckDocument, payload?.id);
        yield put({
            type: GET_TRUCK_DOCUMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editTruckDocumentArchive({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(truckService.editTruckDocumentArchive, payload.id, payload.docId, payload.archive);
        yield put({
            type: GET_TRUCK_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteTruckDocument({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(truckService.deleteTruckDocument, payload.id, payload.docId);
        yield put({
            type: GET_TRUCK_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* createTruckCost({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(truckService.createTruckCosts, payload.id, payload.body);
        yield put({
            type: GET_TRUCK_COSTS,
            payload: { id: payload.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Get Truck Costs */
function* getTruckCosts({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(truckService.getTruckCosts, payload?.id, payload?.body);
        yield put({
            type: GET_TRUCK_COSTS_SUCCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getTruckMaintenance({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(truckService.getTruckMaintenance, payload?.id, payload?.skip, payload?.limit);
        yield put({
            type: GET_TRUCK_MAINTENANCE_SUCCESS,
            payload: res.data,
        });
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
function* uploadTrackMaintenanceDocument({ payload }) {
    const res = yield call(truckService.uploadTruckMaintenanceServiceDocument, payload?.id, payload?.file);
    yield put({
        type: UPLOAD_TRUCK_MAINTENANCE_DOCUMENT_SUCCESS,
        payload: res.data,
    });
}
function* createTruckMaintenanceService({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(truckService.createTruckMaintenanceService, payload);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put({
            type: GET_MAINTENANCE_SERVICE_BY_ID_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
function* updateTruckMaintenanceService({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(truckService.updateTruckMaintenanceService, payload.id, payload.values);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put({
            type: GET_MAINTENANCE_SERVICE_BY_ID_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getTruckSingleMaintenanceService({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(truckService.getMaintenanceById, payload.id);
        yield put({
            type: GET_MAINTENANCE_SERVICE_BY_ID_SUCCESS,
            payload: res.data,
        })
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));

    } catch (e){
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

export const watchTruck = function* watchTruckAuth() {
    yield takeLatest(CREATE_TRUCK, createTruck);
    yield takeLatest(GET_TRUCK, getTruckList);
    yield takeLatest(CHANGE_TRUCK_STATUS, changeTruckStatus);
    yield takeLatest(EDIT_TRUCK_DETAILS, editTruckDetails);
    yield takeLatest(GET_TRUCK_DETAILS, getTruckDetails);
    yield takeLatest(DELETE_TRUCK, deleteTruck);
    yield takeLatest(GET_TRUCK_LOADS, getTruckLoads);

    /** Truck Documents */
    yield takeLatest(CREATE_TRUCK_DOCUMENT, createTruckDocument);
    yield takeLatest(GET_TRUCK_DOCUMENT, getTruckDocument);
    yield takeLatest(EDIT_TRUCK_DOCUMENT_ARCHIVE, editTruckDocumentArchive);
    yield takeLatest(DELETE_TRUCK_DOCUMENT, deleteTruckDocument);
    /** End */

    /** Truck Costs */
    yield takeLatest(CREATE_TRUCK_COSTS, createTruckCost);
    yield takeLatest(GET_TRUCK_COSTS, getTruckCosts);
    //truck maintenance
    yield takeLatest(GET_TRUCK_MAINTENANCE, getTruckMaintenance);
    yield takeLatest(UPLOAD_TRUCK_MAINTENANCE_DOCUMENT, uploadTrackMaintenanceDocument);
    yield takeLatest(CREATE_MAINTENANCE_SERVICE, createTruckMaintenanceService);
    yield takeLatest(UPDATE_MAINTENANCE_SERVICE, updateTruckMaintenanceService);
    yield takeLatest(GET_MAINTENANCE_SERVICE_BY_ID, getTruckSingleMaintenanceService);
};
