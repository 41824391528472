import React, { useMemo } from 'react';
import * as Styled from '../styled';
import { Svg } from 'assets/images';
const Card = ({ body, active_table }) => {
    const dataMap = useMemo(() => {
        return dataGenerator(active_table, body);
    }, [body, active_table]);
    return (
        <div>
            {dataMap?.map((item, index) => (
                <Styled.TableCard key={index}>
                    {Object.keys(item)?.map((elem) => (
                        <Styled.Flex jc="flex-start" key={elem}>
                            <span>{elem}:</span>
                            <span>{item[elem]}</span>
                        </Styled.Flex>
                    ))}
                </Styled.TableCard>
            ))}
        </div>
    );
};

const Trend = ({ item }) => (
    <Styled.Flex>
        {item} <img style={{ marginLeft: '16px' }} src={Number(item) > 0 ? Svg.TrendUp : Svg.TrendDown} alt="progress-icon" />
    </Styled.Flex>
);

const dataGenerator = (type, body) => {
    switch (type) {
        case 'customers': {
            return body.map((item) => ({
                ID: item?.customerID,
                'Loads  Quantity': item?.loadsQty,
                'Load Mile': item?.loadMile,
                Paid: <Trend item={item?.paid} />,
                Unpaid: item?.unpaid,
            }));
        }
        case 'drivers': {
            return body.map((item) => ({
                ID: item?.driverID,
                'Loads  Qty': item?.loadsQty,
                'Load Mi': item?.loadMile,
                'Loads Amt': item?.loadAmount,
                Paid: item?.paid,
            }));
        }
        case 'trucks': {
            return body.map((item) => ({
                ID: item?.truckID,
                'Loads  Qty': item?.loadsQty,
                'Load Mi': item?.loadMile,
                'Loads Amt': item?.loadAmount,
                Cost: item?.cost,
            }));
        }
        case 'trailers': {
            return body.map((item) => ({
                ID: item?.trailerID,
                'Loads  Qty': item?.loadsQty,
                'Load Mi': item?.loadMile,
                'Loads Amt': item?.loadAmount,
                Cost: item?.cost,
            }));
        }
        default:
            return [];
    }
};
export default Card;
