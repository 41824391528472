import {
    getAnalytics,
    getAnalyticsCustomer,
    getAnalyticsDriver,
    getAnalyticsTruck,
    getAnalyticsTrailer,
    changeAnalyticsActiveTab,
} from './analytics.action';
export { watchAnalytics } from './analytics.saga';

export const analyticActions = {
    getAnalytics,
    getAnalyticsCustomer,
    getAnalyticsDriver,
    getAnalyticsTruck,
    getAnalyticsTrailer,
    changeAnalyticsActiveTab,
};
