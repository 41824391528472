import { TextRow, StatusTruck, DateRow, StatusBtnDriver, PhoneRow, PriceRow } from 'components';

import { Svg } from 'assets/images';
import { Checkbox } from '@mui/material';
import { MODAL_NAMES } from 'utils/constants';
import moment from 'moment';

export const ACTION_TYPE = 'GET_DRIVERS';

export const driversHead = [
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Name' },
  { name: 'email', title: 'Email' },
  { name: 'phoneNumber', title: 'Phone Number' },
  {
    name: 'status',
    title: 'Status',
    type: 'status',
    list: [
      {
        value: 'ALL',
        label: 'All',
      },
      {
        value: 'ACTIVE',
        label: 'Active',
      },
      {
        value: 'HOLD',
        label: 'Hold',
      },
      {
        value: 'TERMINATED',
        label: 'Terminated',
      },
      {
        value: 'RECRUITMENT',
        label: 'Recruitment',
      },
    ],
  },
  { name: 'appInvite', title: 'App Invite' },
];

export const driverStatus = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'HOLD',
    label: 'Hold',
  },
  {
    value: 'TERMINATED',
    label: 'Terminated',
  },
  {
    value: 'RECRUITMENT',
    label: 'Recruitment',
  },
];

export const dropdownStatus = [
  {
    svg: Svg.IndigoRoundedIcon,
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    svg: Svg.BlueGreyRoundedIcon,
    value: 'HOLD',
    label: 'Hold',
  },
  {
    svg: Svg.GreenRoundedIcon,
    value: 'TERMINATED',
    label: 'Terminated',
  },
  {
    svg: Svg.OrangeRoundedIcon,
    value: 'RECRUITMENT',
    label: 'Recruitment',
  },
];

export const driversBody = [
  { rowText: (item) => <TextRow name={item?.displayId} /> },
  { rowText: (item) => <TextRow name={item?.firstName} /> },
  { rowText: (item) => <TextRow name={item?.email} /> },
  {
    rowText: (item) => <PhoneRow phone={item?.phone} />,
    // <TextRow name={item?.phone} />
  },
  { rowText: (item) => <StatusTruck info={item?.status} /> },
  {
    button: (item, info, load) => (
      <div>
        <StatusBtnDriver handleClick={(type) => item(type)} info={info} load={load} />
      </div>
    ),
  },
];

export const DriversMenu = [
  {
    name: 'Edit',
    modalName: MODAL_NAMES.TRACK_MODAL,
  },
  {
    name: 'Inactivate',
    modalName: MODAL_NAMES.INACTIVATE_MODAL,
  },
  {
    name: 'Delete',
    modalName: MODAL_NAMES.DELETE_TRUCK,
    color: '#d03325',
  },
];

export const updateDriverRate = [
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'BI_WEEKLY',
    label: 'Bi Weekly',
  },
  {
    value: 'HOURLY',
    label: 'Hourly',
  },
  {
    value: 'MILEAGE',
    label: 'Mileage',
  },
];

export const pastHead = [
  { name: 'start', title: 'Start Date' },
  { name: 'End', title: 'End Date' },
  { name: 'rate', title: 'Rate' },
  { name: 'type', title: 'Type' },
];

export const pastBody = [
  { rowText: (item) => <DateRow date={item?.startDate} /> },
  { rowText: (item) => <DateRow date={item?.endDate} /> },
  { rowText: (item) => <TextRow name={item?.rate} /> },
  { rowText: (item) => <TextRow name={item?.mode} /> },
];

export const driverDocumentsList = [
  { name: 'Driver License', type: 'DRIVER_LICENSE' },
  { name: 'Other', type: 'OTHER' },
];


export const ratesType = (name) => {
  switch (name) {
    case 'MONTHLY':
      return 'Monthly';
    case 'WEEKLY':
      return 'Weekly';
    case 'BI_WEEKLY':
      return 'Bi Weekly';
    case 'HOURLY':
      return 'Hourly';
    case 'MILEAGE':
      return 'Mileage';
    default:
      return name;
  }
};

export const timesheetHeader = ({ onChange, checked, active }) => {
  if (active) {
    return [
      {
        component:
          <Checkbox checked={checked} onChange={(e) => onChange(e, '', 'all')} />,
        width: '52px',
        minWidth: '52px',
      },
      {
        name: 'date',
        title: 'Date',
        width: `calc(100% / 3)`,
      },
      {
        name: 'totalHours',
        title: 'Total  Hours/ml',
        width: `calc(100% / 3)`,
      },
      {
        name: 'totalPay',
        title: 'Total Pay',
        width: `calc(100% / 3)`,
      },
    ];
  } else {
    return [
      {
        name: 'date',
        title: 'Date',
        width: `calc(100% / 3)`,
      },
      {
        name: 'totalHours',
        title: 'Total  Hours/ml',
        width: `calc(100% / 3)`,
      },
      {
        name: 'totalPay',
        title: 'Total Pay',
        width: `calc(100% / 3)`,
      },
    ];
  }
};

export const timesheetBody = ({ onChange, selectedList, active }) => {
  moment()

  if (!active) {
    return [
      {
        rowText: (item) => <TextRow name={moment(item?.createdDate).format("DD.MM.YYYY")} />,
        width: `calc(100% / 3)`,
      },
      {
        rowText: (item) => <TextRow name={item?.hours} />,
        width: `calc(100% / 3)`,
      },
      {
        rowText: (item) => <PriceRow place='suffix' info={item?.totalAmount} />,
        width: `calc(100% / 3)`,
      }];
  } else {
    return [
      {
        width: '52px',
        minWidth: '52px',
        button: (item, info, load) =>
          <Checkbox
            checked={selectedList.includes(info?.id)} onChange={(e) => onChange(e, info)}
          />,

      },
      {
        rowText: (item) => <TextRow name={moment(item?.createdDate).format("DD.MM.YYYY")} />,
        width: `calc(100% / 3)`,
      },
      {
        rowText: (item) => <TextRow name={item?.hours} />,
        width: `calc(100% / 3)`,
      },
      {
        rowText: (item) => <PriceRow place='suffix' info={+item?.totalAmount} />,
        width: `calc(100% / 3)`,
      },
    ];
  }


};