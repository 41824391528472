import React, { useEffect } from 'react';
import { Svg } from 'assets/images';
import { CreateCancel, CustomInput, CustomSelect, CustomTextArea, DatePickerInput, Loader } from 'components';
import { useForm } from 'react-hook-form';
import { FindError, FindLoad, useModalBase } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { driversActions } from 'store';
import { updateDriverRate } from '../../constants';

export const AddTimesheet = ({ headerText }) => {
  const dispatch = useDispatch();
  const { driversDetailsList, singleTimesheet } = useSelector((state) => state?.drivers);
  const { closeModal } = useModalBase();


  const ACTION_TYPE = headerText.includes('Edit') ? 'UPDATE_DRIVER_TIMESHEET' : 'CREATE_DRIVER_TIMESHEET';
  const backError = FindError(ACTION_TYPE);
  const isLoad = FindLoad('GET_DRIVER_SINGLE_TIMESHEET');

  const { control, handleSubmit, watch, reset, formState: { errors } } = useForm({
    defaultValues: {},
  });


  useEffect(() => {
    if (singleTimesheet?.id && headerText.includes('Edit')) {
      reset({
        loadId: singleTimesheet?.loadId,
        truckNumber: singleTimesheet?.truckNumber,
        startDate: new Date(singleTimesheet?.startDate),
        startTime: new Date(singleTimesheet?.startDate),
        endDate: new Date(singleTimesheet?.endDate),
        endTime: new Date(singleTimesheet?.endDate),
        note: singleTimesheet?.note,
        type: singleTimesheet?.type,
        price: singleTimesheet?.price,
        mileage: singleTimesheet?.mileage,
      });
    } else {
      reset({
        loadId: '',
        truckNumber: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        note: '',
        type: '',
        price: '',
        mileage: '',
      });
    }

  }, [singleTimesheet]);

  const getFullTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return {
      hours,
      minutes,
      seconds,
    };
  }

  const setFullTime = (date, fields) => {
    if (fields?.hours) date.setHours(fields?.hours);
    if (fields?.minutes) date.setMinutes(fields?.minutes);
    if (fields?.seconds) date.setSeconds(fields?.seconds);

    return date;
  }


  const onSubmit = (data) => {
    let isValid = Object.keys(errors).length === 0;

    const sendingData = () => {

      const startTime = getFullTime(data.startTime);
      delete data.startTime;

      const endTime = getFullTime(data.endTime);
      delete data.endTime;

      const sendData = {
        ...data,
        startDate: setFullTime(data?.startDate, startTime).toISOString(),
        endDate: setFullTime(data?.endDate, endTime).toISOString(),
      };

      if (headerText.includes('Edit')) {
        if (sendData?.driverId) delete sendData?.driverId;
        return {
          ...sendData,
        };
      } else {
        if (!sendData?.driverId) sendData.driverId = driversDetailsList?.id;
        return {
          ...sendData,
        };
      }
    };


    if (isValid) {
      if (headerText.includes('Edit')) {
        dispatch(driversActions?.updateDriverTimesheet(singleTimesheet.id, sendingData()));
      } else {
        dispatch(driversActions?.createDriverTimesheet(sendingData()));
      }
    }
  };

  return (
    <div className={`add-payroll-driver-modal-wrapper ${isLoad ? 'loading-width' : ''}`}>
      <div className='modal-header'>
        <p className='modal-header-text'>
          {headerText ? headerText : ''}
        </p>

        <img
          className='close-button'
          src={Svg.CloseBtn}
          alt='close-modal'
          onClick={closeModal}
        />
      </div>

      <div className='modal-body'>
        {
          isLoad
            ? <Loader />
            : <form onSubmit={handleSubmit(onSubmit)}>
              <div className='modal-body-inputs'>
                <CustomInput
                  name='loadId'
                  control={control}
                  rules={{ required: true }}
                  label='Load*'
                  placeholder='Write Load ID'
                  maxLength={40}
                />

                <CustomInput
                  name='truckNumber'
                  control={control}
                  label='Truck/Unit Number*'
                  placeholder={'Write Number'}
                  maxLength={40}
                />

                <CustomSelect
                  name='type'
                  control={control}
                  listSelect={updateDriverRate}
                  displayValue={'value'}
                  rules={{ required: true }}
                  displayName={'label'}
                  placeholder='Choose Type'
                  label='Type*'
                />

                <CustomInput
                  name='price'
                  control={control}
                  rules={{ required: true }}
                  type='number'
                  label='Price*'
                  placeholder='0.00'
                  dollar={true}
                  step={'numeric'}
                />
                <div className='dates-block'>
                  <DatePickerInput
                    name='startDate'
                    control={control}
                    rules={{ required: true }}
                    disabled={false}
                    type='date'
                    label='Start Date*'
                    placeholder={'mm/dd/yy'}
                  />


                  <DatePickerInput
                    name='startTime'
                    control={control}
                    rules={{ required: true }}
                    disabled={false}
                    label='Start Time*'
                    placeholder={'00:00'}
                  />
                </div>

                <div className='dates-block'>
                  <DatePickerInput
                    name='endDate'
                    control={control}
                    rules={{ required: true }}
                    disabled={false}
                    type='date'
                    label='End Date*'
                    placeholder={'mm/dd/yy'}
                    minDate={watch('startDate')}
                    errMessage={backError?.error === 'Start date must be less to end date' ? 'Start date must be less to end date' : ''}
                  />

                  <DatePickerInput
                    name='endTime'
                    control={control}
                    rules={{ required: true }}
                    disabled={false}
                    label='End Time*'
                    placeholder={'00:00'}
                  />
                </div>

                <CustomTextArea
                  name={'note'}
                  id={'note'}
                  classnameWrapper='maintenance-modal-textarea-wrapper'
                  control={control}
                  defaultValue={''}
                  label={'Notes'}
                  placeholder={'Write Instruction...'}
                  maxLength={1000}
                  height='normal'
                  showMaxCount={true}
                />
              </div>

              <div className='buttons-wrapper'>
                <CreateCancel
                  title='Save'
                  actionType={ACTION_TYPE}
                  closeType={ACTION_TYPE}
                />
              </div>

            </form>

        }
      </div>
    </div>
  );
};

