import React from 'react';
import { ButtonsTab, ButtonWithSvg, CalendarRange } from 'components';

export const FilterDriverTimesheet = (
  {
    onClickTabs,
    onClickAddButton,
    handleSaveDate,
    handleCancelDate,
    handleChangeDate,
    showCalendar,
    setShowCalendar,
    date,
  }) => {
  return (
    <div className='timesheet-driver-buttons-calendar-block'>

      <ButtonsTab
        first={'Active'}
        second={'Processing'}
        width={'118px'}
        onClick={onClickTabs}
      />

      <div className='timesheet-driver-calendar-button'>
        <CalendarRange
          handleCancelDate={handleCancelDate}
          handleSaveDate={handleSaveDate}
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          handleChangeDate={handleChangeDate}
          date={date}
        />

        <ButtonWithSvg
          text='Add Timesheet'
          onClick={onClickAddButton}
        />

      </div>
    </div>
  );
};



