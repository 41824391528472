import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledEngineProvider } from '@mui/material/styles';
import * as Styled from '../styled';
import { CreateCancel } from 'components';
import { Svg } from 'assets/images';
import { useModalBase } from 'utils';

const DatePicker = ({ setFilter, filter }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendars, setCalendars] = useState(0);
    const [value, setValue] = useState(filter);
    const [width, setWidth] = useState(window.innerWidth);
    const [selected, setSelected] = useState('This Month');
    const { openModal, closeModal } = useModalBase();

    useEffect(()=>{
        const closeModal = ()=> {
            setShowCalendar(false);
            setCalendars(0)
        }
       window.addEventListener("click",closeModal)

        return ()=>{
            window.removeEventListener("click",closeModal)
        }
    },[])

    const shortcutsItems = [
        {
            label: <Styled.PickerLabel active={selected === 'This Month'}>This Month</Styled.PickerLabel>,
            key: 'This Month',
            getValue: () => {
                const today = dayjs();
                return [today.startOf('month'), today];
            },
        },
        {
            label: <Styled.PickerLabel active={selected === 'Last Month'}>Last Month</Styled.PickerLabel>,
            key: 'Last Month',
            getValue: () => {
                const today = dayjs();
                const prevMonth = today.subtract(1, 'month');
                return [prevMonth.startOf('month'), prevMonth.endOf('month')];
            },
        },
        {
            label: <Styled.PickerLabel active={selected === 'This Year'}>This Year</Styled.PickerLabel>,
            key: 'This Year',
            getValue: () => {
                const today = dayjs();
                return [today.startOf('year'), today];
            },
        },
        {
            label: <Styled.PickerLabel active={selected === 'Last Year'}>Last Year</Styled.PickerLabel>,
            key: 'Last Year',
            getValue: () => {
                const today = dayjs();
                const prevYear = today.subtract(1, 'year');
                return [prevYear.startOf('year'), today.endOf('year')];
            },
        },
        {
            label: <Styled.PickerLabel active={selected === 'Custom Range'}>Custom Range</Styled.PickerLabel>,
            key: 'Custom Range',
            getValue: () => {
                return [null, null];
            },
        },
    ];

    const showInModal = () =>
        openModal(
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Styled.Modal>
                    <Styled.Picker
                        calendars={1}
                        onChange={(value, validation) => {
                            handleChangeDate(
                                value.map((item) => item?.$d),
                                validation?.shortcut?.key || 'Custom Range'
                            );
                        }}
                    />
                    <CreateCancel
                        title="Done"
                        maxWidth="300px"
                        onConfirm={handleSave}
                        onCancel={() => {
                            setCalendars(0);
                            setShowCalendar(false);
                        }}
                    />
                </Styled.Modal>
            </LocalizationProvider>
        );

    const handleChangeDate = (value, shortcut) => {
        setSelected(shortcut);

        if (shortcut === 'Custom Range') {
            if (width > 768) {
                setCalendars(2);
            } else {
                openModal(
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Styled.Modal>
                            <Styled.Picker
                                calendars={1}
                                onChange={(values) => {
                                    const value = values.map((item) => item?.$d);
                                    setValue({ start: value?.[0], end: value?.[1] });
                                }}
                            />
                            <CreateCancel
                                title="Done"
                                maxWidth="300px"
                                onConfirm={handleSave}
                                onCancel={() => {
                                    setCalendars(0);
                                    setShowCalendar(false);
                                }}
                            />
                        </Styled.Modal>
                    </LocalizationProvider>
                );
            }
        } else {
            if (selected !== 'Custom Range') setCalendars(0);
            setShowCalendar(false);
            setFilter({ start: new Date(value?.[0]).toISOString(), end: new Date(value?.[1]).toISOString() });
            setValue({ start: value?.[0], end: value?.[1] });
        }
    };

    const handleSave = () => {
        setCalendars(0);
        setShowCalendar(false);
        setFilter({ start: new Date(value?.start).toISOString(), end: new Date(value?.end).toISOString() });
        closeModal();
    };
    const handleCast = (value) => {
        if (value) return new Date(value).toISOString().split('T')[0];
    };

    useEffect(() => {

        const resize = (e) => {
          const width = e.target.innerWidth;
          setWidth(width);
        }

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        if (selected === 'Custom Range') {
            if (width < 768) {
                setCalendars(0);
                showInModal();
            } else {
                setCalendars(2);
                closeModal();
            }
        }
    }, [width, selected]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Styled.PickerContainer show={showCalendar}>
                <div className="date-range" onClick={(e) => {
                  e.stopPropagation()
                  setShowCalendar(true);
                }}>
                    <span>{selected}</span>
                    <span>{handleCast(value.start) || 'Start Date'} </span>
                    <img src={Svg.ArrowRight} alt="arrow-right" />
                    <span>{handleCast(value.end) || 'End Date'}</span>
                    <img src={Svg.ArrowDown} alt="arrow-down" />
                </div>

                {showCalendar && (
                    <div onClick={(e)=>{
                        e.stopPropagation()
                    }}>
                        <Styled.Picker
                            slotProps={{
                                shortcuts: {
                                    items: shortcutsItems,
                                },
                                actionBar: { actions: [] },
                            }}
                            calendars={calendars}
                            onChange={(value, validation) => {
                                handleChangeDate(
                                    value.map((item) => item?.$d),
                                    validation?.shortcut?.key || 'Custom Range'
                                );
                            }}
                        />
                        {selected === 'Custom Range' && calendars !== 0 && (
                            <CreateCancel
                                title="Done"
                                maxWidth="300px"
                                onConfirm={handleSave}
                                onCancel={() => {
                                    setCalendars(0);
                                    setShowCalendar(false);
                                }}
                            />
                        )}
                    </div>
                )}
            </Styled.PickerContainer>
        </LocalizationProvider>
    );
};

const  DateP = (props) => (
    <StyledEngineProvider injectFirst>
        <DatePicker {...props} />
    </StyledEngineProvider>
);

export default DateP;
