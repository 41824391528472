import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_ANALYTICS,
    GET_ANALYTICS_CUSTOMER,
    GET_ANALYTICS_DRIVER,
    GET_ANALYTICS_TRUCKS,
    GET_ANALYTICS_TRAILER,
    GET_ANALYTICS_SUCCESS,
    GET_ANALYTICS_CUSTOMER_SUCCESS,
    GET_ANALYTICS_DRIVER_SUCCESS,
    GET_ANALYTICS_TRUCK_SUCCESS,
    GET_ANALYTICS_TRAILER_SUCCESS,
} from './analytics.types';
import { analyiticService } from './analytics.service';
import { httpRequestsOnErrorsActions } from 'store/http_requests_on_errors';
import { httpRequestsOnLoadActions } from 'store/http_requests_on_load';
import { httpRequestsOnSuccessActions } from 'store/http_requests_on_success';

function* getAnalytics({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(analyiticService.getAnalytics, payload);
        yield put({
            type: GET_ANALYTICS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getAnalyticsCustomers({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(analyiticService.getAnalyticsCustomer, payload);
        yield put({
            type: GET_ANALYTICS_CUSTOMER_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getAnalyticsDrivers({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(analyiticService.getAnalyticsDriver, payload);
        yield put({
            type: GET_ANALYTICS_DRIVER_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getAnalyticsTrucks({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(analyiticService.getAnalyticsTruck, payload);
        yield put({
            type: GET_ANALYTICS_TRUCK_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getAnalyticsTrailers({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(analyiticService.getAnalyticsTrailer, payload);
        yield put({
            type: GET_ANALYTICS_TRAILER_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchAnalytics = function* watchAnalyticsAuth() {
    yield takeLatest(GET_ANALYTICS, getAnalytics);
    yield takeLatest(GET_ANALYTICS_CUSTOMER, getAnalyticsCustomers);
    yield takeLatest(GET_ANALYTICS_DRIVER, getAnalyticsDrivers);
    yield takeLatest(GET_ANALYTICS_TRUCKS, getAnalyticsTrucks);
    yield takeLatest(GET_ANALYTICS_TRAILER, getAnalyticsTrailers);
};
