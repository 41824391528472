export const GET_ANALYTICS = 'GET_ANALYTICS';
export const GET_ANALYTICS_CUSTOMER = 'GET_ANALYTICS_CUSTOMER';
export const GET_ANALYTICS_DRIVER = 'GET_ANALYTICS_DRIVER';
export const GET_ANALYTICS_TRUCKS = 'GET_ANALYTICS_TRUCKS';
export const GET_ANALYTICS_TRAILER = 'GET_ANALYTICS_TRAILER';
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_CUSTOMER_SUCCESS = 'GET_ANALYTICS_CUSTOMER_SUCCESS';
export const GET_ANALYTICS_DRIVER_SUCCESS = 'GET_ANALYTICS_DRIVER_SUCCESS';
export const GET_ANALYTICS_TRUCK_SUCCESS = 'GET_ANALYTICS_TRUCK_SUCCESS';
export const GET_ANALYTICS_TRAILER_SUCCESS = 'GET_ANALYTICS_TRAILER_SUCCESS';
export const CHANGE_ANALYTICS_ACTIVE_TAB = 'CHANGE_ANALYTICS_ACTIVE_TAB';
