import { useEffect, useMemo, useRef, useState } from 'react';
import { AddMaintenance } from './addMaintenance';
import { FindLoad, FindSuccess, useModalBase } from 'utils';
import * as Styled from './styled';
import { Svg } from 'assets/images';
import DownloadFile from './downloadFile';
import NoUploadedFiles from './noUploadedFiles';
import moment from 'moment';
import { Loader, TooltipMain } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getTruckSingleMaintenanceService } from 'store/truck/truck.action';
import {
    GET_MAINTENANCE_SERVICE_BY_ID,
    UPDATE_MAINTENANCE_SERVICE,
} from 'store/truck/truck.type';



export const RightModal = ({ open, setOpen, id, onEdit }) => {
    const { openModal } = useModalBase();
    const [openDetails, setOpenDetails] = useState(true);
    const [openDocs, setOpenDocs] = useState(true);
    const getSingleMaintenance = FindLoad(GET_MAINTENANCE_SERVICE_BY_ID);
    const update_truck_maintenance_service = FindLoad(UPDATE_MAINTENANCE_SERVICE);
    const { singleMaintenance } = useSelector(({ truck }) => truck);
    const dispatch = useDispatch()


    useEffect(() => {
        if(id) {
            dispatch(getTruckSingleMaintenanceService(id))
        }
    }, [id]);



    const iter = useMemo(() => {
        const images = [];
        const files = [];
        if (!singleMaintenance?.documents?.length) return { images, files };

        for (let item of singleMaintenance?.documents) {
            const file_type = item?.file?.url?.split('.')?.at(-1);
            if (file_type === 'svg' || file_type === 'png' || file_type === 'jpeg' || file_type === 'jpg') images.push(item);
            else files.push(item);
        }

        return { images, files };
    }, [singleMaintenance?.documents]);


    return (
        <Styled.Container open={open} className="right-modal">

            {(getSingleMaintenance || update_truck_maintenance_service) ? (
                <Loader />
            ) : (
                <>
                  <div className="flex space-between">
                    <h4 className="title">
                      {singleMaintenance?.serviceName}
                    </h4>

                    <img
                      src={Svg.CloseBtn}
                      alt="close-modal"
                      onClick={() => setOpen(false)}
                    />
                  </div>

                  <div
                    className="flex flex-end mt-16"
                    onClick={() => openModal(
                      <AddMaintenance
                        initialValues={{ ...singleMaintenance, serviceDate: new Date(singleMaintenance.serviceDate) }}
                        classNameWrapper="maintenance-modal-wrapper"
                        classNameBody="maintenance-modal-body-wrapper"
                      />
                    )}>
                    <img src={Svg.EditLoad} onClick={onEdit} alt="edit" />
                    Edit
                  </div>
                    <Styled.Details openModal={open} open={openDetails && open}>
                        <div className="flex space-between">
                            <Styled.Head>Details</Styled.Head>
                            <img src={Svg.ArrowTop} onClick={() => setOpenDetails((p) => !p)} alt="^" />
                        </div>
                        <div className="details">
                            <span>Service name:</span>
                            <span>{singleMaintenance?.serviceName || 'Not Set'}</span>
                        </div>
                        <div className="details">
                            <span>Service Date:</span>
                            <span>{singleMaintenance?.serviceDate ? moment(singleMaintenance?.serviceDate).format('MM/DD/YYYY') : 'Not Set'}</span>
                        </div>

                        <div className="details">
                            <span>Total Cost:</span>
                            <span> {singleMaintenance?.totalCost ? `$ ${singleMaintenance?.totalCost}` : 'Not Set'}</span>
                        </div>

                        <div className="details">
                            <span>Note:</span>
                            <TooltipMain
                              text={singleMaintenance?.note || 'Not Set'}
                              content={singleMaintenance?.note || 'Not Set'}
                              allowLength={109}
                            />
                        </div>
                    </Styled.Details>
                    <Styled.Details openModal={open} open={openDocs && open}>
                        <div className="flex space-between">
                            <Styled.Head>Files</Styled.Head>
                            <img src={Svg.ArrowTop} onClick={() => setOpenDocs((p) => !p)} alt="^" />
                        </div>
                        {!iter.images.length && !iter.files.length ? (
                            <NoUploadedFiles open={openDocs && open} />
                        ) : (
                            <>
                                {iter.files.map((item) => (
                                    <div className="docs show-hide maintenance-right-modal-main-files" key={item?.file?.id}>
                                        <div className="maintenance-right-modal-main-file-image">
                                            <img src={Svg.File} alt="doc" />
                                        </div>

                                        <span>{item?.file?.name || item?.file?.originalName}</span>
                                        <DownloadFile item={item} />
                                    </div>
                                ))}
                                <Styled.ImagesContainer className="show-hide">
                                    {iter.images.map((item) => (
                                        <div key={item?.file?.id}>
                                            <img src={item?.file?.url} alt="doc" />
                                            <div>
                                                <DownloadFile icon={Svg.UploadWhite} item={item} />
                                                <a href={item?.file?.url} target="_blank" rel="noreferrer">
                                                    <img src={Svg.EyeOpenWhite} alt="eye" />
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </Styled.ImagesContainer>
                            </>
                        )}
                    </Styled.Details>
                </>
            )}
        </Styled.Container>
    );
};
