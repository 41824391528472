import styled from 'styled-components';

export const Container = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #0e9594;
    }
`;

export const Switch = styled.div`
    background-color: ${({ checked }) => (checked ? '#0E9594' : '#BFBFBF')};
    padding: 2px;
    border-radius: 16px;
    width: 44px;
    height: 22px;
    margin-inline: 8px;
    > div {
        width: 18px;
        height: 18px;
        transform: translatex(${({ checked }) => (checked ? 'calc(100% + 4px)' : '0px')});
        border-radius: 1500px;
        background-color: #ffffff;
        transition: transform 0.2s;
    }
`;
