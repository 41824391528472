import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { SlicedText } from '../messages';
import { HtmlTooltip } from '../messages/htmlTool';
import { useModal } from 'components/modal';
import { MODAL_NAMES } from 'utils';
import { useDispatch } from 'react-redux';
import { loadActions } from 'store';
import { MinLoader } from '../loaders';
import { NumericFormat } from 'react-number-format';
import { Checkbox } from '@mui/material';

export const IconAndTitle = ({ icon, title, textWidth, imgClass }) => {
    const ref = useRef();
    return (
        <div ref={ref} style={{ width: '100%' }} className="info-and-image">
            {/*<img src={icon} alt="Icon"/>*/}
            <div style={{ fontWeight: 'bold' }}>{RenderText(ref?.current?.offsetWidth - 40, title, textWidth)}</div>
        </div>
    );
};

// export const IconAndTitle = ({ icon, title, size }) => {
//     return (
//         <div className="info-and-image">
//             <img src={icon} alt="Icon" />
//             <SlicedText type={'name'} size={size ? size : 17} data={title} />
//         </div>
//     );
// };

export const IdRow = ({ id }) => {
    return <SlicedText type={'id'} size={10} data={id} />;
};

export const LinkRow = ({ id, loadId }) => {
    const history = useHistory();
    return (
        <button className="hyper-link" onClick={() => history.push(`/load/${loadId}`)}>
            <p>{id}</p>
            <SlicedText type={'id'} size={15} data={id} />
        </button>
    );
};

export const DateRow = ({ date }) => {
    return <p style={{ color: '#506C84' }}>{date ? moment(date).format('MM/DD/YYYY') : 'N/A'}</p>;
};

export const DateCheckboxRow = ({ date, onChange, checked, classNameCheckbox }) => {
    return (
      <p style={{ color: '#506C84' }}>
          <Checkbox className={classNameCheckbox?classNameCheckbox:''} checked={checked} onChange={onChange}/>
        {date ? moment(date).format('MM/DD/YYYY') : 'N/A'}
    </p>
    )
};

export const ProfitRow = ({ info }) => {
    return <div className="">{info}</div>;
};

export const TextRow = ({ name, textWidth, color }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback((node) => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';
    const text = (name ? name : '...') + '';

    return (
        <div style={name ? { color: '#506C84' } : { color: '#8CA2B8' }} ref={curr} className="text-style">
            {RenderText(width, text, textWidth, color)}
        </div>
    );
};

export const SlicedRow = ({ name, textWidth, color, fontWeight }) => {
    const text = (name ? name : 'Not Set') + '';
    const ref = useRef();

    if (text === 'Not Set') {
        return <p style={{ color: '#41586D', fontSize: '14px' }}>Not Set</p>;
    } else if (text) {
        const count = Math.ceil(ref?.current?.offsetWidth / (textWidth ? textWidth : 10));
        const slicedText = '' + text?.slice(0, count);
        return (
            <div
                ref={ref}
                style={{
                    width: '100%',
                    fontWeight: fontWeight ? fontWeight : '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: color ? color : '#2B273C',
                }}
                className="text-style">
                {text?.length > count ? (
                    <HtmlTooltip title={<p>{text}</p>} placement="top-start">
                        <p style={{ textOverflow: 'ellipsis' }}>{`${slicedText}...`}</p>
                    </HtmlTooltip>
                ) : (
                    <p>{text}</p>
                )}
            </div>
        );
    } else {
        return '...';
    }
};

export const PriceRow = ({ info, textWidth, className, place }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback((node) => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 250;
    const price = (info ? info.toFixed(2) : 0) + '';

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + price?.slice(0, count);
        return (
            <div ref={curr} className={`text-style ${className}`}>
                {price?.length > count ? (
                    <HtmlTooltip
                        title={
                            <p className='price-row-style'>
                                <NumericFormat
                                  value={price}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={!place ? '$': ''}
                                  suffix={place === 'suffix'? '$': ''}
                                />
                            </p>
                        }
                        placement="top-start">
                        <p className='price-row-style'>
                            <NumericFormat
                              value={slicedText}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={!place ? '$': ''}
                              suffix={place === 'suffix'? '$': ''}
                            />
                            ...
                        </p>
                    </HtmlTooltip>
                ) : (
                    <p className='price-row-style'>
                        {<NumericFormat
                      value={price}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={!place ? '$': ''}
                      suffix={place === 'suffix'? '$': ''}
                    />}
                    </p>
                )}
            </div>
        );
    } else {
        return '...';
    }
};

export const PhoneRow = ({ phone }) => {
    if (phone) {
        const converted = '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6, 6);
        return <p style={{ color: 'rgb(80, 108, 132)' }}>{converted}</p>;
    } else {
        return <p style={{ color: 'rgb(80, 108, 132)' }}>Not Set</p>;
    }
};

export const TypeRow = ({ text }) => {
    if (text !== undefined) {
        return text?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
        });
    }
};

export const StatusRow = ({ status }) => {
    return (
        <div
            style={{
                background: status ? '#FFF3E0' : '#E7EFFB',
                color: status ? '#FF9A00' : '#5D7A95',
            }}
            className="status-wrapper">
            {status ? 'Pending' : 'Inactive'}
        </div>
    );
};

export const StatusBtn = ({ handleClick, info, load }) => {
    return (
        <div className="flex">
            {info?.auth?.status === 'PENDING' ? (
                <div className="flex">
                    <div className="pending-btn">Pending</div>
                    <button className="resend-button" onClick={() => handleClick('RESEND')}>
                        {load === true ? <MinLoader color={'#0e9594'} /> : 'Resend'}
                    </button>
                </div>
            ) : info?.auth?.status === 'INACTIVE' ? (
                <button type="button" className="inactive-btn">
                    Inactive
                </button>
            ) : info?.auth?.status === 'ACTIVE' ? (
                <div className="active-status">Active</div>
            ) : (
                ''
            )}
        </div>
    );
};

export const StatusBtnDriver = ({ handleClick, info, load }) => {
    return (
        <div className="flex">
            {info?.appState === 'PENDING' ? (
                <div className="flex">
                    <div className="pending-btn">Pending</div>
                    <button className="resend-button" onClick={() => handleClick('RESEND')}>
                        {load === true ? <MinLoader color={'#0e9594'} /> : 'Resend'}
                    </button>
                </div>
            ) : info?.appState === 'INACTIVE' ? (
                <button type="button" className="invite-inactive">
                    Inactive
                </button>
            ) : info?.appState === 'ACTIVE' ? (
                <div className="invite-active">Active</div>
            ) : info?.appState === 'HOLD' ? (
                <div className="invite-hold">Hold</div>
            ) : info?.appState === 'TERMINATED' ? (
                <div className="invite-terminated">Terminated</div>
            ) : info?.appState === 'RECRUITMENT' ? (
                <div className="invite-recruitmend">Recruitment</div>
            ) : (
                ''
            )}
        </div>
    );
};

export const StatusBtnNoPermission = ({ handleClick, info, load }) => {
    return (
        <div className="flex">
            {info?.auth?.status === 'PENDING' ? (
                <div className="flex">
                    <div className="pending-btn">Pending</div>
                    {/*<button className="resend-button" onClick={() => handleClick('RESEND')}>*/}
                    {/*    Resend*/}
                    {/*</button>*/}
                </div>
            ) : info?.auth?.status === 'INACTIVE' ? (
                <div className="inactive-btn">Inactive</div>
            ) : info?.auth?.status === 'ACTIVE' ? (
                <div className="active-btn">Active</div>
            ) : (
                ''
            )}
        </div>
    );
};

export const StatusTruck = (info) => {
    return (
        <div style={{ height: '24px' }} className="flex status-box">
            {info?.info === 'ACTIVE' && <div className="active-text"> Active</div>}
            {info?.info === 'AVAILABLE' && <div className="active-text"> Available</div>}
            {info?.info === 'PENDING' && <div className="pending-text"> Pending</div>}
            {info?.info === 'INACTIVE' && <div className="inactive-text"> Inactive</div>}
            {info?.info === 'IN_TRANSIT' && <div className="transit-text"> In Transit</div>}
            {info?.info === 'MAINTENANCE' && <div className="maintenance-text"> Maintenance</div>}
            {info?.info === 'TERMINATED' && <div className="terminated-text">Terminated</div>}
            {info?.info === 'RECRUITMENT' && <div className="recruitment-text">Recruitment</div>}
            {info?.info === 'HOLD' && <div className="hold-text">Hold</div>}
            {info?.info === 'DO_NOT_USE' && <div className="not-use-text">Do Not Use</div>}
            {info?.info === 'CANCELED' && <div className="canceled-text">Canceled</div>}
            {info?.info === 'DELIVERED' && <div className="recruitment-text">Delivered</div>}
            {info?.info === 'COMPLETE' && <div className="maintenance-text">Complete</div>}
        </div>
    );
};

export const ActionTransaction = (info) => {
    const { open } = useModal();
    const dispatch = useDispatch();

    const handleVoid = () => {
        open(MODAL_NAMES.DELETE_MODAL, {
            noTitle: true,
            title: `Do you want void    ${info?.name?.displayId}`,
            subTitle: 'Voiding a load transaction will permanently void it from the system',
            actionType: 'EDIT_TRANSACTION_ACTION',
            deleteButton: 'Void',
            // historyPush: '/customers',
            handleDelete: () => handleActiveVoid('EDIT_TRANSACTION_ACTION', info?.name?.id),
        });
    };

    const handleActiveVoid = (_, voidId) => {
        dispatch(loadActions.editTxnAction(voidId));
    };

    return (
        <div className="flex action-box">
            {info?.name?.status === 'ACTIVE' ? (
                <div className="action-danger" onClick={handleVoid}>
                    Void
                </div>
            ) : (
                <div className="action-gunmetal">Voided</div>
            )}
        </div>
    );
};

const RenderText = (width, text, textWidth, color) => {
    if (text) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + text?.slice(0, count);
        return (
            <div className="text-style" style={color ? { color: color } : {}}>
                {text?.length > count ? (
                    <HtmlTooltip title={<p>{text}</p>} placement="top-start">
                        <p style={{ textOverflow: 'ellipsis' }}>{`${slicedText}...`}</p>
                    </HtmlTooltip>
                ) : (
                    <p>{text}</p>
                )}
            </div>
        );
    } else {
        return '...';
    }
};

export const PriceConvertor = ({ price }) => {
    const convertedPrice = price ? +price.toFixed(2) : 0;
    return <NumericFormat
      value={convertedPrice ? convertedPrice : '0'}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />;
};
