import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccordionTable,
  FullTable,
  Loader, MinLoader,
  NoDataComponent,
  PaginationItem,
  TimesheetRightModal,
} from 'components';
import { TimesheetCard } from 'fragments';
import { FindLoad, FindSuccess, SendPageSave, useModalBase, useWindowDimensions } from 'utils';
import { timesheetBody, timesheetHeader } from '../constants';
import { Svg } from 'assets/images';
import { FilterDriverTimesheet } from './filterDriverTimesheet';
import { driversActions, httpRequestsOnSuccessActions } from 'store';
import { AddTimesheet } from './modals';


const GET_DRIVER_SINGLE_TIMESHEET = 'GET_DRIVER_SINGLE_TIMESHEET';
const CREATE_DRIVER_TIMESHEET = 'CREATE_DRIVER_TIMESHEET';
const GET_DRIVER_TIMESHEET = 'GET_DRIVER_TIMESHEET';
const UPDATE_DRIVER_TIMESHEET = 'UPDATE_DRIVER_TIMESHEET';
const SEND_TO_PROCESS_DRIVER_TIMESHEET = 'SEND_TO_PROCESS_DRIVER_TIMESHEET';

export const TimesheetDriver = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [currentId, setCurrenId] = useState('');
  const [date, setDate] = useState({
    startDate: null,
    sendData: null,
  });
  const { driversDetailsList, timesheetList, singleTimesheet } = useSelector((state) => state?.drivers);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const { openModal, closeModal } = useModalBase();

  const loader = FindLoad(GET_DRIVER_TIMESHEET);
  const rightModalLoader = FindLoad(GET_DRIVER_SINGLE_TIMESHEET);
  const createdSuccess = FindSuccess(CREATE_DRIVER_TIMESHEET);
  const updatedSuccess = FindSuccess(UPDATE_DRIVER_TIMESHEET);
  const processingSuccess = FindSuccess(SEND_TO_PROCESS_DRIVER_TIMESHEET);
  const processLoading = FindLoad(SEND_TO_PROCESS_DRIVER_TIMESHEET);
  const info = history?.location?.state;


  const sendData = useMemo(() => {
    const filteredInfo = {
      ...info,
    };

    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 10;
    filteredInfo.status = info?.status === 'Processing' ? 'PROCESSED' : 'ACTIVE';

    date?.startDate ? filteredInfo.startDate = date?.startDate : delete filteredInfo.startDate;

    date?.endDate ? filteredInfo.endDate = date?.endDate : delete filteredInfo.endDate;

    delete filteredInfo?.page;

    return filteredInfo;

  }, [info, date]);


  useEffect(() => {
    if (createdSuccess?.type || updatedSuccess?.type || processingSuccess?.type) {
      closeModal();
      dispatch(driversActions.getDriverTimesheet(driversDetailsList?.id, sendData));
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_DRIVER_TIMESHEET));
      dispatch(httpRequestsOnSuccessActions.removeSuccess(UPDATE_DRIVER_TIMESHEET));
      dispatch(httpRequestsOnSuccessActions.removeSuccess(SEND_TO_PROCESS_DRIVER_TIMESHEET));
    }
  }, [createdSuccess, updatedSuccess, processingSuccess]);


  useEffect(() => {
    dispatch(driversActions.getDriverTimesheet(driversDetailsList?.id, sendData));
  }, [info?.status, info?.page, info?.limit, info?.skip]);


  const handleChangeDate = (dates) => {
    const [start, end] = dates;

    setDate({
      startDate: start,
      endDate: end,
    });
  };

  const handleSaveDate = () => {
    dispatch(driversActions.getDriverTimesheet(driversDetailsList?.id, sendData));
    setShowCalendar(false);
    closeModal();
  };

  const handleCancelDate = () => {
    const params = { ...sendData };
    if (params?.startDate) delete params?.startDate;
    if (params?.endDate) delete params?.endDate;

    setDate({
      startDate: null,
      sendData: null,
    });

    dispatch(driversActions.getDriverTimesheet(driversDetailsList?.id, params));
    setShowCalendar(false);
    closeModal();
  };

  const handleClickActiveTabs = () => {
    setCurrenId('');
  };

  const handleCheck = (ev, item, name) => {
    if (name === 'all') {
      const allList = [];
      if (ev?.target?.checked || ev === true) {
        timesheetList?.timesheets.map((i) => allList.push(i?.id));
      } else {
        setSelectedList([]);
      }

      setSelectedList(allList);
    } else {

      const list = [...selectedList];

      if (ev.target.checked) {
        list.push(item?.id);
      } else {
        list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
      }
      setSelectedList(list);
    }
  };

  const getRightModalDetails = (id) => {
    dispatch(driversActions.getDriverSingleTimesheet(id));
    setCurrenId(id);
  };

  const changePage = (number) => {
    SendPageSave(number, info, history);
    setSelectedList([]);
    setCurrenId('');
  };

  const sendToProcessingSingle = (id) => {
    dispatch(driversActions.sendToProcessing([id]));
    setSelectedList([]);
    setCurrenId('');
  };

  const sendToProcessing = (selectedList) => {
    dispatch(driversActions.sendToProcessing(selectedList));
    setSelectedList([]);
    setCurrenId('');
  };

  return (
    <div className='timesheet-driver-wrapper'>
      <div style={{ width: '100%' }}>
        <div className='timesheet-driver-filters-block'>
          {(selectedList.length > 0 && selectedList.length === timesheetList?.timesheets.length && width >= 768)
            ? <div className='items-are-selected'>
              <div className='items-are-selected-svg-text'>
                <Svg.CloseButton
                  className='items-are-selected-svg'
                  onClick={(e) => handleCheck(e, '', 'all')}
                />

                <p className='items-are-selected-text'>
                  {selectedList.length} items are selected.
                </p>
              </div>
              <button
                className='items-are-selected-button'
                disabled={processLoading}
                onClick={() => sendToProcessing(selectedList)}
              >
                {processLoading
                  ? <MinLoader />
                  : 'Send to processing'
                }

              </button>
            </div>
            : ((selectedList.length > 0 && selectedList.length < timesheetList?.timesheets.length) && width < 768)
              ? <div className='select-all-cancel-buttons'>
                <button
                  className='select-all-button'
                  onClick={() => handleCheck(true, {}, 'all')}
                >
                  Select All
                </button>

                <button
                  className='cancel-button'
                  onClick={(e) => handleCheck(e, {}, 'all')}
                >
                  Cancel
                </button>
              </div>
              : ((selectedList.length > 0 && selectedList.length === timesheetList?.timesheets.length) && width < 768)
                ? <div className='select-all-cancel-buttons'>
                  <button
                    className='select-all-button'
                    onClick={() => sendToProcessing(selectedList)}
                  >
                    {processLoading
                      ? <MinLoader />
                      : 'Send to Processing'
                    }

                  </button>

                  <button
                    className='cancel-button'
                    onClick={(e) => handleCheck(e, {}, 'all')}
                  >
                    Cancel
                  </button>
                </div>

                : <FilterDriverTimesheet
                  onClickTabs={handleClickActiveTabs}
                  onClickAddButton={() => openModal(<AddTimesheet headerText='Add Time' />)}
                  handleChangeDate={handleChangeDate}
                  handleSaveDate={handleSaveDate}
                  handleCancelDate={handleCancelDate}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                  date={date}
                />
          }

        </div>

        {(width >= 1279) &&
          <div className='main-content-desktop'>
            <FullTable
              handleClickButton={(id) => {
                setCurrenId(id);
              }}
              handleClick={(id) => getRightModalDetails(id)}
              head={
                timesheetHeader({
                  onChange: handleCheck,
                  checked: timesheetList?.timesheets.length > 0 && selectedList.length === timesheetList?.timesheets.length,
                  active: !(info?.status?.toUpperCase() === 'PROCESSING'),
                })
              }
              body={
                timesheetBody({
                  onChange: handleCheck,
                  active: !(info?.status?.toUpperCase() === 'PROCESSING'),
                  selectedList: selectedList,
                })
              }
              list={timesheetList?.timesheets}
              loadingType={GET_DRIVER_TIMESHEET}
            />

            {(currentId && width > 1279)
              &&
              <TimesheetRightModal
                singleTimesheet={singleTimesheet}
                loading={rightModalLoader}
                onClose={() => setCurrenId('')}
                active={!(info?.status?.toUpperCase() === 'PROCESSING')}
                openModal={() => openModal(<AddTimesheet headerText='Edit Time' />)}
                onProcessingClick={sendToProcessingSingle}
                actionType={SEND_TO_PROCESS_DRIVER_TIMESHEET}
              />
            }

          </div>
        }
        {
          (((width < 1279 && width > 767)))
          &&
          <div className='main-content-tablet'>
            <AccordionTable
              handleClickButton={(id) => {
                setCurrenId(id);
              }}
              handleClick={(id) => getRightModalDetails(id)}
              head={
                timesheetHeader({
                  onChange: handleCheck,
                  checked: timesheetList?.timesheets.length > 0 && selectedList.length === timesheetList?.timesheets.length,
                  active: !(info?.status?.toUpperCase() === 'PROCESSING'),
                })}
              body={timesheetBody({
                onChange: handleCheck,
                active: !(info?.status?.toUpperCase() === 'PROCESSING'),
                selectedList: selectedList,
              })}
              list={timesheetList?.timesheets}
              loadingType={GET_DRIVER_TIMESHEET}
              accordionDetailsComp={
                <TimesheetRightModal
                  classNameWrapper='timesheet-details-accordion'
                  classNameHeader='timesheet-details-header-accordion'
                  classNameItems='timesheet-details-items-wrapper'
                  classNameNotesWrapper='timesheet-details-notes-wrapper'
                  lineClampNotes={1}
                  loading={rightModalLoader}
                  actionType={SEND_TO_PROCESS_DRIVER_TIMESHEET}
                  singleTimesheet={singleTimesheet}
                  allowCloseButton={false}
                  openModal={() => openModal(<AddTimesheet headerText='Edit Time' />)}
                  active={!(info?.status?.toUpperCase() === 'PROCESSING')}
                  onProcessingClick={sendToProcessingSingle}
                />
              }
            />
          </div>
        }
        {
          (width <= 767)
          &&
          <div className='main-content-mobile'>
            {loader ? <Loader />
              : (timesheetList?.timesheets.length > 0)
                ? timesheetList?.timesheets.map((item) => (
                  <TimesheetCard
                    key={item?.id}
                    item={item}
                    active={!(info?.status?.toUpperCase() === 'PROCESSING')}
                    actionType='SEND_TO_PROCESS_DRIVER_TIMESHEET'
                    onChange={(e) => handleCheck(e, item)}
                    checked={selectedList.includes(item?.id)}
                    onProcessingClick={sendToProcessingSingle}
                    onEdit={() => {
                      dispatch(driversActions.getDriverSingleTimesheet(item?.id));
                      setCurrenId(item?.id);
                      openModal(<AddTimesheet headerText='Edit Time' />);
                    }}
                  />
                ))
                : <div style={{ height: '100%', width: '100%' }}>
                  <NoDataComponent />
                </div>
            }
          </div>
        }
      </div>
      {!!timesheetList?.timesheets?.length && !loader &&
        <div style={{ marginTop: 20, width: '100%' }}>
          <PaginationItem
            page={info?.page}
            listLength={timesheetList?.timesheets?.length}
            handleReturn={(number) => changePage(number)}
            count={timesheetList?.count}
          />
        </div>
      }
    </div>

  )
    ;
};

