import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useModal,
  DropdownSelect,
  Documents,
  Loader,
  GoBackButton,
  PaginationItem,
  FilterMenu,
  NoDataComponent,
} from 'components';
import { MODAL_NAMES, SendPageSave, FindLoad, SaveParams, CheckCoastPermission } from 'utils';
import { Box, Tab, Tabs, Button } from '@mui/material';
import { DetailsFragment, LoadsFragment, CostsFragment, Maintenance } from './fragments';
import { driversActions, trailerActions, truckActions, loadActions } from 'store';
import { truckDocumentsList, TruckLoadMenu, truckStatusDropdown, trackLoadStatus } from './constants';
import { ACTION_TYPE } from '../loadBoard/constants';
import { LoadsCard } from '../loadBoard/fragments';
import { NotesSection } from '../../fragments';

export const TruckInfo = () => {
  const {
    truckDetailsList,
    trailerList,
    driversList,
    truckDocument,
    truckLoads,
    truckCosts,
    activePackage,
  } = useSelector((state) => ({
    truckDetailsList: state?.truck?.truckDetailsList,
    truckLoads: state?.truck?.truckLoads,
    truckDocument: state?.truck?.truckDocument,
    trailerList: state?.trailer?.trailerList,
    driversList: state?.drivers?.driversList,
    truckCosts: state?.truck?.truckCosts,
    activePackage: state.payment.activePackage,
  }));
  const { open } = useModal();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const paramsRouter = useParams();
  const info = history?.location?.state;
  const [valueTab, setValueTab] = useState('loads');
  const [status, setStatus] = useState('');
  const loader = FindLoad('GET_TRUCK_LOADS');

  useEffect(() => {
    dispatch(truckActions.getTruckDocument(paramsRouter.id));
    dispatch(truckActions.getTruckDetails(paramsRouter.id));
    dispatch(loadActions.getLoadTruckId(paramsRouter?.id));
    dispatch(trailerActions.getTrailer({ status: 'ACTIVE' }));
    dispatch(driversActions.getDriver({ status: 'ACTIVE' }));
  }, []);

  useEffect(() => {
    if (activePackage && CheckCoastPermission(activePackage)) {
      dispatch(truckActions.getTruckCosts(paramsRouter.id));
    }
  }, [activePackage]);

  useEffect(() => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 10;
    if (info?.status && info?.status !== 'ALL') {
      filteredInfo.status = info.status;
    } else {
      delete filteredInfo.status;
    }
    delete filteredInfo.page;
    dispatch(truckActions.truckLoads(paramsRouter.id, { ...filteredInfo }));
  }, [info]);

  useEffect(() => {
    if (truckDetailsList) {
      setStatus(truckDetailsList?.status);
    }
  }, [truckDetailsList]);

  const changePage = (number) => {
    SendPageSave(number, info, history);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    const active = event.target.value;
    dispatch(truckActions.changeTruckStatus(paramsRouter.id, active));
  };

  const handleChange = (event, newValueTab) => {
    setValueTab(newValueTab);
  };

  const handleClick = (item, name) => {
    if (item === 'DELETE_TRUCK') {
      dispatch(truckActions.deleteTruck(name));
    } else if (item === 'Activate' || item === 'Inactivate') {
      const status = item === 'Activate';
      dispatch(truckActions.changeTruckStatus(status, name?.id));
    }
  };

  const handleDelete = () => {
    open(MODAL_NAMES.DELETE_MODAL, {
      title: `Truck  ${truckDetailsList?.displayId} `,
      subTitle: 'Deleting a truck will permanently remove it from the system',
      actionType: 'DELETE_TRUCK',
      historyPush: '/trucks',
      handleDelete: () => handleDeleteTruck('DELETE_TRUCK'),
    });
  };

  const handleDeleteTruck = () => {
    dispatch(truckActions.deleteTruck(paramsRouter.id));
  };

  const handleUploadFile = (dock) => {
    const file = {
      file: {
        ...dock?.file,
      },
      status: 'CURRENT',
      type: dock?.type,
    };
    dispatch(truckActions.createTruckDocument(paramsRouter.id, file));
  };

  const handleDeleteUpload = (dock) => {
    dispatch(truckActions.deleteTruckDocument(paramsRouter.id, dock?.id));
  };

  const handleSetStatus = (dock) => {
    const status = dock?.status === 'CURRENT';
    dispatch(truckActions.editTruckDocumentArchive(paramsRouter.id, dock?.id, status));
  };

  const handleFilterStatus = (event) => {
    SaveParams(history, {
      ...info,
      status: event?.target?.defaultValue,
    });
  };
  return (
    <>
      {loader?.length
        ? <Loader height={'90vh'} />

        : <div className='truck-info info-container'>
          <div className='truck-header info-header'>
            <div className='info-router'>
              <GoBackButton title={'Truck'} link={'/trucks'} />
            </div>
            <div className='truck-banner info-banner'>
              <div className='header-title'>
                <h4 className='title'>
                  Truck <span>{truckDetailsList?.displayId}</span>
                </h4>
              </div>

              <div className='header-btn'>
                  <div className='popper-desktop'>
                    <div className='popper-btn'>
                      <div className='header-select'>
                        <DropdownSelect
                          value={status}
                          setStatus={setStatus}
                          handleChange={handleChangeStatus}
                          options={truckStatusDropdown}
                          idInput={'status'}
                        />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className='truck-tab info-tab'>
              <Box className='tab-btns info-btns'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={valueTab} onChange={handleChange} aria-label='basic tabs example'>
                    <Tab label='Loads' value='loads' />
                    <Tab label='Details' value='details' />
                    {CheckCoastPermission(activePackage) && <Tab label='Costs' value='costs' />}
                    <Tab label='Maintenance' value='maintenance' />
                    <Tab label='Notes' value='notes' />
                  </Tabs>
                </Box>
              </Box>
            </div>
          </div>
          <div className='truck-main info-main'>
            <Box className='tab-btns info-btns'>
              {valueTab === 'loads' && (
                <>
                  <div className='loads-filtering'>
                    <div className='filter-menu'>
                      <FilterMenu
                        title={'Filter the Type'}
                        list={trackLoadStatus}
                        status={info?.status}
                        handleFilterStatus={handleFilterStatus}
                      />
                    </div>
                  </div>

                  <div className='desktop-loads'>
                    <LoadsFragment loadList={truckLoads} ACTION_TYPE={'GET_LOAD'} />
                  </div>
                  <div className='mobile-loads'>
                    {loader?.length ? (
                      <Loader />
                    ) : truckLoads?.loads?.length ? (
                      <LoadsCard
                        poperList={TruckLoadMenu}
                        loadList={truckLoads}
                        handleClickButton={handleClick}
                        actionType={ACTION_TYPE}
                        activePackage={activePackage}
                      />
                    ) : (
                      <div style={{ height: '100%' }}>
                        <NoDataComponent />
                      </div>
                    )}
                    {truckLoads?.count !== 0 && (
                      <div className='mobile-pagination'>
                        <PaginationItem
                          listLength={truckLoads?.count}
                          page={page}
                          handleReturn={(number) => changePage(number)}
                          count={truckLoads?.count}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
              {valueTab === 'details' && (
                <>
                  <DetailsFragment detailsList={truckDetailsList} trailerList={trailerList} driversList={driversList} />
                  <div className='document-container'>
                    <Documents
                      docks={truckDocument?.length ? truckDocument : []}
                      actionType={'CREATE_TRUCK_DOCUMENT'}
                      deleteType={'DELETE_TRUCK_DOCUMENT'}
                      changeStatus={'EDIT_TRUCK_DOCUMENT_ARCHIVE'}
                      activeStatus={'CURRENT'}
                      typeList={truckDocumentsList}
                      handleDelete={handleDeleteUpload}
                      handleUpload={handleUploadFile}
                      handleSetStatus={handleSetStatus}
                    />
                  </div>

                  <div className='box-footer'>
                    <Button variant='text' color='error' onClick={handleDelete}>
                      <span className='text-error'>Delete Truck</span>
                    </Button>
                  </div>
                </>
              )}
              {CheckCoastPermission(activePackage) && valueTab === 'costs' && <CostsFragment truckCosts={truckCosts} />}
              {valueTab === 'notes' && <NotesSection type={'truck'} />}
              {valueTab === 'maintenance' && <Maintenance />}
            </Box>
          </div>
        </div>
      }
    </>
  );
};
