import React from 'react';
import * as Styled from '../styled';
const tabs = ['customers', 'drivers', 'trucks', 'trailers'];

const TabFilter = ({ active, onChange }) => {
    return (
        <Styled.TabContainer width="fit-content" jc="flex-start">
            {tabs?.map((item) => (
                <Styled.Tab active={active === item} onClick={() => onChange(item)}>
                    {item}
                </Styled.Tab>
            ))}
        </Styled.TabContainer>
    );
};

export default TabFilter;
