import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SavePage } from 'utils';
import { Paper, Tab, Tabs } from '@mui/material';

export const ButtonsTab = ({ first, second, width, onClick }) => {
  const [value, setValue] = useState(0);
  const history = useHistory();
  const info = history?.location?.state;

  useEffect(() => {
    if (info) {
      const statusType = info?.status === first ? 0 : 1;
      setValue(info?.status ? statusType : 0);
    }
  }, [info]);

  const handleChange = (event, newValue) => {
    const statusType = newValue === 0 ? first : second;
    setValue(newValue);
    const infoItem = { ...info, page: 1, skip: 0, limit: 10 };
    infoItem.status = statusType;
    SavePage(history, info, { ...infoItem });
    onClick(event, newValue)
  };

  return (
    <Paper
      style={{
        display:'flex',
        alignItems:'center',
        width: '248px',
        height: '40px',
        border: 'none',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '4px',
        padding: '4px',
      }}
      square
    >
      <Tabs
        className='buttons-tab-wrapper'
        value={value}
        indicatorColor='primary'
        textColor='primary'
        onChange={handleChange}
        aria-label='disabled tabs example'>
        <Tab style={{ width: width ? width : '100px' }} label={first} />
        <Tab style={{ width: width ? width : '100px' }} label={second} />
      </Tabs>
    </Paper>
  );
};
