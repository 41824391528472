import React, { useState } from 'react';
import TabFilter from './tabFilter';
import * as Styled from '../styled';
import { Tables } from './tables';

const List = ({ filter }) => {
    const [activeTab, setActiveTab] = useState('customers');
    return (
        <Styled.List>
            <TabFilter onChange={(value) => setActiveTab(value)} active={activeTab} />
            <Tables active_table={activeTab} filter={filter} />
        </Styled.List>
    );
};

export default List;
