import React, { forwardRef } from 'react';
import { Svg } from 'assets/images';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const CustomInputForCalendar = forwardRef((({ value, onClick, startDate, endDate, date }, ref) => {
  const history = useHistory();
  const info = history?.location?.state;

  return (
    <div
      className='custom-input-for-calendar-wrapper'
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      ref={ref}
    >
      <p className='custom-input-for-calendar-text'>
          <span className='year'>
            {}
            {date?.startDate ?
              moment(date?.startDate).format('DD.MM.YYYY'):
              'Start Date'
            }
        </span>

      </p>
      <p>
        <img src={Svg.ArrowRight} alt='arrow-right' />
      </p>

      <p className='custom-input-for-calendar-text'>
        <span className='year'>
             {date?.endDate ?
               moment(date?.endDate).format('DD.MM.YYYY'):
               'End Date'
             }
        </span>
      </p>

      <Svg.CalendarSvg className='main-calendar-icon' />
    </div>
  );
}));