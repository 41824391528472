// IMG
import Quick from './img/quick-count.png';
import Manage from './img/manage-truck.png';
import Team from './img/team-work.png';
import Banner from './img/landing-banner.png';
import CarImg from './img/car-img.png';
import LoadingTruck from './img/loaded-truck.png';
import PageImg from './img/page-img.png';
import Pikap from './img/pikap-img.png';
import QuickCount from './img/quick-count.png';
import TeamImg from './img/team-img.png';
import TeamWork from './img/team-work.png';
import TimeImg from './img/time-img.png';
import UsersImg from './img/users-img.png';
import CheckGreen from './img/check-green.png';
import CheckRed from './img/check-red.png';
import Bg from './img/BG.png';
import StepOne from './img/Step-1.png';
import StepTwo from './img/Step-2.png';
import StepThree from './img/Step-3.png';
import Ellipse from './img/Ellipse.png';
import CloseDrawer from './img/Close-Drawer.png';
import OpenDrawer from './img/Open-Drawer.png';
import CongratsSet from './img/congrats-set.png';
import ResetKey from './img/reset-key.png';
import NumberTable from './img/number-table.png';
import EverythingLarg from './img/web-evrething.png';
import WeCanLarg from './img/we-can-larg.png';
import StepItem from './img/step-item.png';

// SVG
import Logo from './svg/Logo.svg';
import LogoWhite from './svg/logo-white.svg';
import LogoGreen from './svg/logo-green.svg';
import Burger from './svg/burger-menu.svg';
import CloseIcon from './svg/close-icon.svg';
import UserMenu from './svg/user-menu.svg';
import User from './svg/user.svg';
import UserActive from './svg/user-active.svg';
import Board from './svg/board.svg';
import BoardActive from './svg/board-active.svg';
import Trucks from './svg/trucks.svg';
import TrucksActive from './svg/trucks-active.svg';
import Payroll from './svg/Payroll.svg';
import PayrollActive from './svg/PayrollActive.svg';
import Billing from './svg/billing.svg';
import BillingActive from './svg/billing-active.svg';
import Notification from './svg/notification.svg';
import AddPhoto from './svg/add-photo.svg';
import UserInput from './svg/user-Input.svg';
import EmailInput from './svg/email-Input.svg';
import OutWhite from './svg/out-white.svg';
import EyeOpen from './svg/eye-open.svg';
import EyeClose from './svg/eye-close.svg';
import CloseModal from './svg/close-modal.svg';
import Lock from './svg/lock.svg';
import BurgerHorizontal from './svg/burger-horizontal.svg';
import DrawerMenu from './svg/drawer-menu.svg';
import HanburgerClose from './svg/hanburger-close.svg';
import PaymentItem from './svg/payment-item.svg';
import CircleRight from './svg/circle-right.svg';
import ArrowDown from './svg/arrow-down.svg';
import {ReactComponent as ArrowDownSvg} from './svg/arrow-down.svg';
import ArrowTop from './svg/arrow-top.svg';
import EditLoad from './svg/edit-load.svg';
import SmsRed from './svg/sms-red.svg';
import EyeRedClose from './svg/eye-redClose.svg';
import EyeRedOpen from './svg/eye-redOpen.svg';
import LockRed from './svg/lock-red.svg';
import ProfileRed from './svg/profile-red.svg';
import Error from './svg/error.svg';
import Success from './svg/success.svg';
import FilterBtn from './svg/filter-btn.svg';
import Drivers from './svg/drivers.svg';
import DriversActive from './svg/driversActive.svg';
import TrailersActive from './svg/trailers-active.svg';
import Trailers from './svg/trailers.svg';
import CloseBtn from './svg/close-button.svg';
import ActiveSvg from './svg/active-svg.svg';
import InactiveSvg from './svg/inactive-svg.svg';
import RedRoundedIcon from './svg/red-icon.svg';
import IndigoRoundedIcon from './svg/indigo-svg.svg';
import GreenRoundedIcon from './svg/green-icon.svg';
import BlueRoundedIcon from './svg/blue-icon.svg';
import BlueGreyRoundedIcon from './svg/blue-grey-icon.svg';
import AddStepIcon from './svg/add-step.svg';
import {ReactComponent as CalendarSvg} from './svg/CalendarSvg.svg';
import {ReactComponent as SearchSvg} from './svg/SearchSvg.svg';

import AddMobileIcon from './svg/add-mobile-icon.svg';
import Upload from './svg/upload.svg';
import DeleteItem from './svg/deleteItem.svg';
import DownloadItem from './svg/downloadItem.svg';
import OrangeRoundedIcon from './svg/orange-icon-svg.svg';
import AccordionIcon from './svg/accordion-icon.svg';
import RedDelete from './svg/red-delete.svg';
import OrangeEdit from './svg/orange-edit.svg';
import PickUpIcon from './svg/pick-up-icon.svg';
import OrangeTopIcon from './svg/orange-top-icon.svg';
import OrangeBottomIcon from './svg/orange-down.svg';
import DropOffIcon from './svg/drop-off-icon.svg';
import SecurityTime from './svg/security-time.svg';
import ReteIcon from './svg/rate-icon.svg';
import EndTime from './svg/end-time.svg';
import StartTime from './svg/start-time.svg';
import EditYellowIcon from './svg/edit-yellow-icon.svg';
import PayCard from './svg/pay-card.svg';
import GoBackIcon from './svg/go-back-icon.svg';
import Customer from './svg/customer-icon.svg';
import CustomerActive from './svg/customer-active.svg';
import MarkVector from './svg/markVector.svg';
import MarkRed from './svg/markRed.svg';
import DownArrowIcon from './svg/downArrowIcon.svg';
import EmptyBox from './svg/empty-box.svg';

import PerIcon from './svg/per-icon.svg';
import ParkingIcon from './svg/parking-icon.svg';
import OilIcon from './svg/oil-icon.svg';
import InsuranceIcon from './svg/insurance-icon.svg';
import DeprecationIcon from './svg/deprecation-icon.svg';
import BrakeIcon from './svg/brake-icon.svg';
import TireIcon from './svg/tire-icon.svg';
import CentsIcon from './svg/cents-icon.svg';
import FuelIcon from './svg/fuel-icon.svg';
import DollarIcon from './svg/dollar-icon.svg';

import PayDate from './svg/payDate.svg';
import PayStatus from './svg/payStatus.svg';
import ReceiptText from './svg/receiptText.svg';
import TruckTick from './svg/truckTick.svg';
import Visa from './svg/visa.svg';
import Master from './svg/master.svg';
import Download from './svg/download.svg';
import Show from './svg/show.svg';
import Info from './svg/info.svg';
import YellowCheck from './svg/yellowCheck.svg';
import Done from './svg/done.svg';
import Cancel from './svg/cancel.svg';
import BillingIcon from './svg/billingIcon.svg';
import BillingActiveIcon from './svg/billingActive.svg';
import NotificationOrange from './svg/notifiaction-orange.svg';
import NotificationFailed from './svg/notifiaction-failed.svg';
import CalendarIcon from './svg/calendar-icon.svg';
import TimeIcon from './svg/timer-icon.svg';
import BillingDown from './svg/billing-down.svg';
import BillingUp from './svg/billing-up.svg';
import ChartGrid from './img/chart-grid.png';

import DisabledOrange from './svg/disabled-orange.svg';
import DisabledAddStep from './svg/disabled-add-step.svg';
import DisabledDelete from './svg/disabled-delete.svg';
import OpenLink from './svg/openLink.svg';
import NoteIcon from './svg/noteIcon.svg';
import MileCoast from './svg/mile-coast.svg';
import FixCoast from './svg/fix-coast.svg';
import ReportIcon from './svg/report-Icons.svg';
import InfoGray from './svg/info-circle.svg';
import Pdf from './svg/pdf_icon.svg';
import Jpeg from './svg/jpeg_icon.svg';
import Png from './svg/png_icon.svg';
import File from './svg/file.svg';
import Analytics from './svg/analytics.svg';
import AnalyticsActive from './svg/analytics_active.svg';
import Sort from './svg/sort.svg';
import ArrowRight from './svg/arrow-right.svg';
import TrendUp from './svg/trend-up.svg';
import TrendDown from './svg/trend-down.svg';
import CloseRed from './svg/close-red-icon.svg';
import NoFiles from './svg/no-files.svg';
import UploadWhite from './svg/upload-white.svg';
import EyeOpenWhite from './svg/eye-open-white.svg';
import LoadingSvg from './svg/LoadingSvg.svg';
import {ReactComponent as CloseButton} from './svg/CloseButton.svg';
import {ReactComponent as PlusSvg} from './svg/PlusSvg.svg';

export const Images = {
    Logo,
    LogoWhite,
    Quick,
    Manage,
    Team,
    Banner,
    CarImg,
    LoadingTruck,
    PageImg,
    Pikap,
    QuickCount,
    TeamImg,
    TeamWork,
    TimeImg,
    UsersImg,
    CheckGreen,
    CheckRed,
    Bg,
    StepOne,
    StepTwo,
    StepThree,
    Ellipse,
    CloseDrawer,
    OpenDrawer,
    CongratsSet,
    ResetKey,
    NumberTable,
    EverythingLarg,
    WeCanLarg,
    StepItem,
    ChartGrid,
};

export const Svg = {
    InfoGray,
    NoteIcon,
    OpenLink,
    Done,
    Cancel,
    YellowCheck,
    Info,
    Download,
    Show,
    Visa,
    Master,
    PayDate,
    PayStatus,
    ReceiptText,
    TruckTick,
    DownArrowIcon,
    MarkVector,
    MarkRed,
    DeleteItem,
    DownloadItem,
    Upload,
    Burger,
    Logo,
    LogoWhite,
    CloseIcon,
    UserMenu,
    User,
    UserActive,
    Trucks,
    TrucksActive,
    Board,
    BoardActive,
    Billing,
    BillingActive,
    Notification,
    Payroll,
    PayrollActive,
    AddPhoto,
    EmailInput,
    UserInput,
    OutWhite,
    EyeOpen,
    EyeClose,
    CloseModal,
    Lock,
    BurgerHorizontal,
    DrawerMenu,
    HanburgerClose,
    PaymentItem,
    CircleRight,
    ArrowDown,
    ArrowDownSvg,
    ArrowTop,
    EditLoad,
    SmsRed,
    EyeRedClose,
    EyeRedOpen,
    LockRed,
    ProfileRed,
    Error,
    Success,
    FilterBtn,
    Drivers,
    DriversActive,
    TrailersActive,
    Trailers,
    CloseBtn,
    ActiveSvg,
    InactiveSvg,
    RedRoundedIcon,
    IndigoRoundedIcon,
    GreenRoundedIcon,
    BlueRoundedIcon,
    BlueGreyRoundedIcon,
    OrangeRoundedIcon,
    AddStepIcon,

    AddMobileIcon,
    AccordionIcon,
    RedDelete,
    OrangeEdit,
    PickUpIcon,
    OrangeTopIcon,
    OrangeBottomIcon,
    DropOffIcon,
    SecurityTime,
    ReteIcon,
    EndTime,
    StartTime,
    EditYellowIcon,
    PayCard,
    GoBackIcon,
    Customer,
    CustomerActive,
    EmptyBox,
    BillingIcon,
    BillingActiveIcon,
    PerIcon,
    ParkingIcon,
    OilIcon,
    InsuranceIcon,
    DeprecationIcon,
    BrakeIcon,
    TireIcon,
    CentsIcon,
    FuelIcon,
    DollarIcon,
    NotificationOrange,
    TimeIcon,
    CalendarIcon,
    NotificationFailed,
    BillingDown,
    BillingUp,
    LogoGreen,
    MileCoast,
    FixCoast,
    ReportIcon,
    Sort,
    ArrowRight,
    TrendUp,
    TrendDown,
    CloseRed,
    NoFiles,
    UploadWhite,
    EyeOpenWhite,
    // Disabled Icon
    DisabledOrange,
    DisabledAddStep,
    DisabledDelete,
    Pdf,
    Jpeg,
    Png,
    File,
    Analytics,
    AnalyticsActive,
    CalendarSvg,
    LoadingSvg,
    CloseButton,
    PlusSvg,
    SearchSvg
};
