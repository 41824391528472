import {
    GET_ANALYTICS_SUCCESS,
    GET_ANALYTICS_CUSTOMER_SUCCESS,
    GET_ANALYTICS_DRIVER_SUCCESS,
    GET_ANALYTICS_TRUCK_SUCCESS,
    GET_ANALYTICS_TRAILER_SUCCESS,
    CHANGE_ANALYTICS_ACTIVE_TAB,
} from './analytics.types';

const initialState = {
    analytics: {
        loadsQty: 0,
        profit: 0,
        cost: 0,
        revenue: 0,
        analyticsList: [],
    },
    customers: [],
    drivers: [],
    trucks: [],
    trailers: [],
    activeTab: 'profit',
};

export const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ANALYTICS_SUCCESS: {
            return {
                ...state,
                analytics: action.payload,
            };
        }
        case GET_ANALYTICS_CUSTOMER_SUCCESS: {
            return {
                ...state,
                customers: action.payload,
            };
        }
        case GET_ANALYTICS_DRIVER_SUCCESS: {
            return {
                ...state,
                drivers: action.payload,
            };
        }
        case GET_ANALYTICS_TRUCK_SUCCESS: {
            return {
                ...state,
                trucks: action.payload,
            };
        }
        case GET_ANALYTICS_TRAILER_SUCCESS: {
            return {
                ...state,
                trailers: action.payload,
            };
        }
        case CHANGE_ANALYTICS_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: action.payload,
            };
        }

        default:
            return state;
    }
};
