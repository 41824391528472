import React, { useEffect, useState } from 'react';
import { MinLoader, ModalHeader, CustomTextArea, CustomInput, DatePickerInput } from 'components';
import { FindLoad, FindSuccess, useModalBase } from 'utils';
import { useForm } from 'react-hook-form';
import { Box, Tab, Tabs } from '@mui/material';
import { useDispatch } from 'react-redux';
import { truckActions } from 'store';
import { useLocation } from 'react-router-dom';
import { httpRequestsOnSuccessActions } from 'store';
import { UploadDocument } from './uploadDocument';

const create_type = 'CREATE_MAINTENANCE_SERVICE';
const update_type = 'UPDATE_MAINTENANCE_SERVICE';

export const AddMaintenance = ({ initialValues, classNameWrapper, classNameBody }) => {
    const dispatch = useDispatch();
    const { closeModal } = useModalBase();
    const [valueTab, setValueTab] = useState('details');
    const location = useLocation();
    const truck_id = location.pathname.split('/').at(-1);

    const [documents, setDocuments] = useState(initialValues?.documents || []);
    const create_success = FindSuccess(create_type);
    const update_success = FindSuccess(update_type);
    const create_loading = FindLoad(create_type);
    const update_loading = FindLoad(update_type);
    const upload_image_loading = FindLoad('UPLOAD_IMAGE');

    const {
        control,
        handleSubmit,
        setValue,
        formState: {},
    } = useForm({
        defaultValues: {
            truckId: truck_id,
            ...initialValues,
        },
    });

    useEffect(() => {
        if (create_success?.type === create_type) {
            closeModal();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(create_type));
            dispatch(truckActions.getTruckMaintenance({ id: truck_id, skip: 0, limit: 20 }));
        } else if (update_success?.type === update_type){
            closeModal();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(update_type));
            dispatch(truckActions.getTruckMaintenance({ id: truck_id, skip: 0, limit: 20 }));
        }
    }, [create_success,  update_success]);


    const onSubmit = (values) => {
        if (!upload_image_loading) {
            const data = {
                ...values,
                serviceDate: new Date(values.serviceDate).toISOString(),
            };

            if (initialValues?.id) {
                dispatch(truckActions.updateTruckMaintenanceService(initialValues.id, data));
            } else {
                dispatch(truckActions.createTruckMaintenanceService(data));
            }
        }
    };

    const handleUploadFile = (dock, type) => {
        if (type === 'remove') {
            const new_array = documents.filter((elem) => elem.id !== dock.id);
            setDocuments(new_array);
            setValue('documents', new_array);
        } else {
            const file = {
                file: {
                    ...dock
                },
                id: dock?.id,
            };
            const docs = [...documents, file];
            setDocuments(docs);
            setValue('documents', docs);
        }
    };



    return (
        <div className={`add-note-wrapper ${classNameWrapper? classNameWrapper: ''}`}>
            <ModalHeader title={`${initialValues ? 'Edit' : 'Add'} Service`} />
            <div className={`truck-info ${classNameBody?  classNameBody: ''}`}>
                <div className="tab-padding">
                    <Tabs
                      className="maintenance-tabs info-btns"
                      value={valueTab}
                      onChange={(_, value) => setValueTab(value)}
                      aria-label="basic tabs example">
                        <Tab className="tabs-item" label="Details" value="details" />
                        <Tab className="tabs-item" label="Files" value="files" />
                    </Tabs>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    {valueTab === 'details' ? (
                        <div className="add-maintenance-inputs">
                            <CustomInput
                                name="serviceName"
                                control={control}
                                rules={{ required: true }}
                                disabled={false}
                                type="text"
                                label="Service Name*"
                                placeholder={'Write Service Name'}
                                maxLength={40}
                            />
                            <CustomInput
                                name="vendor"
                                control={control}
                                rules={{ required: true }}
                                disabled={false}
                                type="text"
                                label="Vendor*"
                                placeholder={'Write Service Vendor'}
                                maxLength={40}
                            />
                            <div className="flex space-between flex-gap-16">
                                <DatePickerInput
                                    name="serviceDate"
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={false}
                                    type="date"
                                    label="Service Date*"
                                    placeholder={'Add Date'}
                                />
                                <CustomInput
                                    name="totalCost"
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={false}
                                    type="number"
                                    label="Total Cost*"
                                    placeholder={'0.00'}
                                    dollar={true}
                                    step={'numeric'}
                                />
                            </div>

                            <CustomTextArea
                                name={'note'}
                                id={'note'}
                                classnameWrapper="maintenance-modal-textarea-wrapper"
                                control={control}
                                defaultValue={''}
                                label={'Notes'}
                                placeholder={'Write Instruction...'}
                                maxLength={1000}
                                height="normal"
                                showMaxCount={true}
                            />
                        </div>
                    ) : (
                        <UploadDocument onChange={handleUploadFile} docs={documents} />
                    )}

                    <div className="wrapper-btn flex space-between maintenance-modal-buttons-wrapper">
                        <button type="button" className="active-btn maintenance-modal-cancel-btn" style={{ height: '100%' }} onClick={() => closeModal()}>
                           Cancel
                        </button>

                        <button className="add-button-style">
                            {create_loading?.length || update_loading?.length ? (
                                <MinLoader color={'white'} />
                            ) : (
                                <span className="save-load">Save</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
