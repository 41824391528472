import {
    GET_ANALYTICS,
    GET_ANALYTICS_CUSTOMER,
    GET_ANALYTICS_DRIVER,
    GET_ANALYTICS_TRUCKS,
    GET_ANALYTICS_TRAILER,
    CHANGE_ANALYTICS_ACTIVE_TAB,
} from './analytics.types';

//-----------------------
//types for props;
//-----------------------
//skip?: number;
//limit?: number;
//start: date;  *required ---------------------------
//end: date;  *required ---> date.toISOString()  ----
//compare: date;  end here ↑ ------------------------
//-----------------------

export const getAnalytics = ({ skip, limit, start, end, compare }) => {
    return {
        type: GET_ANALYTICS,
        payload: { skip, limit, start: start, end: end, compare },
    };
};

export const getAnalyticsCustomer = ({ skip, limit, start, end, compare }) => {
    return {
        type: GET_ANALYTICS_CUSTOMER,
        payload: { skip, limit, start, end, compare },
    };
};
export const getAnalyticsDriver = ({ skip, limit, start, end, compare }) => {
    return {
        type: GET_ANALYTICS_DRIVER,
        payload: { skip, limit, start, end, compare },
    };
};
export const getAnalyticsTruck = ({ skip, limit, start, end, compare }) => {
    return {
        type: GET_ANALYTICS_TRUCKS,
        payload: { skip, limit, start, end, compare },
    };
};
export const getAnalyticsTrailer = ({ skip, limit, start, end, compare }) => {
    return {
        type: GET_ANALYTICS_TRAILER,
        payload: { skip, limit, start, end, compare },
    };
};

export const changeAnalyticsActiveTab = (value) => {
    return {
        type: CHANGE_ANALYTICS_ACTIVE_TAB,
        payload: value,
    };
};
