/**  Service */
import axios from 'axios';

export const truckService = {
    /* Create Trucks */
    createTruck: (body) => axios.post(`/trucks`, body, { auth: true }),

    /* Get Trucks */
    getTruck: (params) => axios.get(`/trucks`, { auth: true, params: { ...params } }),

    /* Change Trucks Status */
    changeTruckStatus: (id, status) => axios.patch(`/trucks/${id}/status/${status}`, {}, { auth: true }),

    /* Edit Truck Details */
    editTruckDetails: (body, id) => axios.patch(`/trucks/${id}/details`, { ...body }, { auth: true }),

    /* Get Truck Details */
    getTruckDetails: (id) => axios.get(`/trucks/${id}/details`, { auth: true }),

    /*  Delete Truck */
    deleteTruck: (id) => axios.delete(`/trucks/${id}`, { auth: true }),

    getTruckLoadService: (id, info) => axios.get(`/loads/truck/${id}`, { auth: true, params: { ...info } }),

    /** Truck Documents */
    createTruckDocument: (id, body) => axios.post(`/trucks/${id}/documents`, { ...body }, { auth: true }),

    getTruckDocument: (id) => axios.get(`/trucks/${id}/documents`, { auth: true }),

    editTruckDocumentArchive: (id, docId, archive) =>
        axios.patch(`/trucks/${id}/documents/${docId}/archive/${archive}`, {}, { auth: true }),

    deleteTruckDocument: (id, docId) => axios.delete(`/trucks/${id}/documents/${docId}`, { auth: true }),
    /** End */

    /** Truck Costs */
    createTruckCosts: (id, body) => axios.post(`/trucks/${id}/costs`, { ...body }, { auth: true }),

    getTruckCosts: (id) => axios.get(`/trucks/${id}/costs`, { auth: true }),

    /**  End */
    getTruckMaintenance: (id, skip, limit) => axios.get(`/maintenance/truck/${id}`, {auth: true, params: {skip, limit} }),

    createTruckMaintenanceService: (values) =>
        axios.post(`/maintenance`, values, {
            auth: true,
        }),
    updateTruckMaintenanceService: (id, values) => axios.patch(`/maintenance/${id}`, { ...values }, { auth: true }),

    getMaintenanceById: (id) => axios(`/maintenance/${id}`, { auth: true }),
};
