import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { analyticActions } from 'store/analytics';
import * as Styled from '../styled';

const Overview = ({ filter }) => {
    const dispatch = useDispatch();
    const { analytics } = useSelector(({ analytics }) => analytics);

    useEffect(() => {
        dispatch(analyticActions.getAnalytics({ start: filter?.start, end: filter?.end }));
    }, [dispatch, filter?.start, filter?.end]);

    return (
        <Styled.Flex margin="27px 0 24px 0" dir="column" gap="55px">
            <Styled.Flex jc="space-between">
                <Styled.OverviewCard num={analytics?.loadsQty} title={analytics?.loadsQty} active="loadQty">
                    <span>Loads</span>
                    <span>{analytics?.loadsQty}</span>
                </Styled.OverviewCard>
                <Styled.OverviewCard num={analytics?.profit} title={analytics?.profit} active="profit">
                    <span>Profit</span>
                    <span>{analytics?.profit}$</span>
                </Styled.OverviewCard>
                <Styled.OverviewCard num={analytics?.cost} title={analytics?.cost} active="cost">
                    <span>Cost</span>
                    <span>{analytics?.cost}$</span>
                </Styled.OverviewCard>
                <Styled.OverviewCard num={analytics?.revenue} title={analytics?.revenue} active="revenue">
                    <span>Revenue</span>
                    <span>{analytics?.revenue}$</span>
                </Styled.OverviewCard>
            </Styled.Flex>
        </Styled.Flex>
    );
};

export default Overview;
