import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CardBtn, CustomInput, CostCard, HtmlToolTip } from 'components';
import { httpRequestsOnSuccessActions, truckActions } from 'store';
import { FindLoad, FindSuccess } from 'utils';
import { Box, Grid } from '@mui/material';
import { Svg } from 'assets/images';

const REQUEST_TYPE = 'CREATE_TRUCK_COSTS';

export const CostsFragment = ({ truckCosts }) => {
    const perList = [
        {
            costId: 0,
            title: 'Tire Costs',
            svg: Svg.TireIcon,
            total: false,
            type: 'total',
            name: 'tireCosts',
        },
        {
            costId: 1,
            title: 'Brake Costs',
            svg: Svg.BrakeIcon,
            total: false,
            name: 'brakeCosts',
            type: 'total',
        },
        {
            costId: 2,
            title: 'Oil Costs',
            svg: Svg.OilIcon,
            total: false,
            type: 'total',
            name: 'oilCosts',
        },
        {
            costId: 3,
            title: 'Deprecation',
            svg: Svg.DeprecationIcon,
            total: false,
            type: 'total',
            name: 'deprecation',
        },
        {
            costId: 4,
            title: 'Other per miles cost',
            svg: Svg.PerIcon,
            total: false,
            name: 'otherMileCost',
        },
        {
            costId: 5,
            title: 'Total Amount',
            total: true,
            totalNumber: 'Per',
        },
    ];

    const fixList = [
        {
            costId: 0,
            title: 'Parking',
            svg: Svg.ParkingIcon,
            total: false,
            name: 'parkingCost',
            text: 'per month',
        },
        {
            constId: 1,
            title: 'Insurance',
            svg: Svg.InsuranceIcon,
            total: false,
            name: 'insuranceCost',
            text: 'per month',
        },

        {
            constId: 2,
            title: 'Other monthly Costs',
            svg: Svg.PerIcon,
            total: false,
            name: 'otherFixCost',
            text: 'per month',
        },

        {
            constId: 3,
            title: 'Total Amount',
            total: true,
            text: 'per month',
            totalNumber: 'Fix',
        },
    ];

    const dispatch = useDispatch();
    const paramsRouter = useParams();
    const [editInput, setEditInput] = useState(true);
    const loadSave = FindLoad(REQUEST_TYPE);
    const success = FindSuccess(REQUEST_TYPE);
    const [inputs, setInputs] = useState(truckCosts);

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            numberOfTire: Number(truckCosts?.numberOfTire),
            tireChangeMiles: Number(truckCosts?.tireChangeMiles),
            tireCost: Number(truckCosts?.tireCost),
            numberOfBrake: Number(truckCosts?.numberOfBrake),
            brakeChangeMiles: Number(truckCosts?.brakeChangeMiles),
            brakeCost: Number(truckCosts?.brakeCost),
            oilCost: Number(truckCosts?.oilCost),
            oilChangeMiles: Number(truckCosts?.oilChangeMiles),
            otherMileCost: Number(truckCosts?.otherMileCost),
            truckCost: Number(truckCosts?.truckCost),
            truckMilesLimit: Number(truckCosts?.truckMilesLimit),
            truckSalePrice: Number(truckCosts?.truckSalePrice),
            insuranceCost: Number(truckCosts?.insuranceCost),
            parkingCost: Number(truckCosts?.parkingCost),
            otherFixCost: Number(truckCosts?.otherFixCost),
            total: {
                tireCosts: Number(truckCosts?.total?.tireCosts),
                brakeCosts: Number(truckCosts?.total?.brakeCosts),
                oilCosts: Number(truckCosts?.total?.oilCosts),
                deprecation: Number(truckCosts?.total?.deprecation),
            },
            mpg: Number(truckCosts?.mpg),
        },
    });

    const handleEdit = () => {
        setEditInput(!editInput);
    };

    const handleChancel = () => {
        handleEdit();
        reset({
            numberOfTire: Number(truckCosts?.numberOfTire),
            tireChangeMiles: Number(truckCosts?.tireChangeMiles),
            tireCost: Number(truckCosts?.tireCost),
            numberOfBrake: Number(truckCosts?.numberOfBrake),
            brakeChangeMiles: Number(truckCosts?.brakeChangeMiles),
            brakeCost: Number(truckCosts?.brakeCost),
            oilCost: Number(truckCosts?.oilCost),
            oilChangeMiles: Number(truckCosts?.oilChangeMiles),
            otherMileCost: Number(truckCosts?.otherMileCost),
            truckCost: Number(truckCosts?.truckCost),
            truckMilesLimit: Number(truckCosts?.truckMilesLimit),
            truckSalePrice: Number(truckCosts?.truckSalePrice),
            insuranceCost: Number(truckCosts?.insuranceCost),
            parkingCost: Number(truckCosts?.parkingCost),
            otherFixCost: Number(truckCosts?.otherFixCost),
            total: {
                tireCosts: Number(truckCosts?.total?.tireCosts),
                brakeCosts: Number(truckCosts?.total?.brakeCosts),
                oilCosts: Number(truckCosts?.total?.oilCosts),
                deprecation: Number(truckCosts?.total?.deprecation),
            },
            mpg: Number(truckCosts?.mpg),
        });
    };

    const onSubmit = (data) => {
        const formatData = {
            ...data,
        };

        formatData.numberOfTire = Number(data?.numberOfTire);
        formatData.tireChangeMiles = Number(data?.tireChangeMiles);
        formatData.tireCost = Number(data?.tireCost);
        formatData.numberOfBrake = Number(data?.numberOfBrake);
        formatData.brakeChangeMiles = Number(data?.brakeChangeMiles);
        formatData.brakeCost = Number(data?.brakeCost);
        formatData.oilCost = Number(data?.oilCost);
        formatData.oilChangeMiles = Number(data?.oilChangeMiles);
        formatData.otherMileCost = Number(data?.otherMileCost);
        formatData.truckCost = Number(data?.truckCost);
        formatData.truckMilesLimit = Number(data?.truckMilesLimit);
        formatData.truckSalePrice = Number(data?.truckSalePrice);
        formatData.insuranceCost = Number(data?.insuranceCost);
        formatData.parkingCost = Number(data?.parkingCost);
        formatData.otherFixCost = Number(data?.otherFixCost);
        formatData.mpg = Number(data?.mpg);
        dispatch(truckActions.createTruckCosts(paramsRouter?.id, formatData));
    };

    useEffect(() => {
        if (success) {
            setEditInput(!editInput);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
        }
    }, [success]);

    useEffect(() => {
        if (truckCosts) {
            setInputs(truckCosts);
        }
    }, [truckCosts]);

    return (
        <div className="cost-container details-container">
            <div className="details-form cost-summary">
                <Box className="laod-space" />
                <Box className="load-form">
                    <Box className="basic-banner space-between">
                        <div className="load-title basic-title flex">
                            <h4 className="load-basic flex">Summary</h4>
                            <HtmlToolTip text={'information'} />
                        </div>
                    </Box>
                    <Box className="basic-banner fix-cost">
                        <div className="load-subtitle">
                            <h4 className="subtitile-basic">Per Miles costs</h4>
                        </div>
                    </Box>
                    <Grid container columnSpacing={3} rowSpacing={2}>
                        {perList?.map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6} lg={4} xl={3}>
                                    <CostCard
                                        svg={item.svg}
                                        title={item.title}
                                        total={item?.total}
                                        alt={item?.alt}
                                        totalAmount={truckCosts?.total}
                                        priceSymbol={'dollar'}
                                        value={item?.type === 'total' ? truckCosts?.total?.[item?.name] : truckCosts[item.name]}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Box className="basic-banner fix-cost">
                        <div className="load-subtitle">
                            <h4 className="subtitile-basic">Fix Cost</h4>
                        </div>
                    </Box>
                    <Grid container columnSpacing={3} rowSpacing={2}>
                        {fixList.map((item, index) => (
                            <Grid key={index} item xs={12} sm={6} lg={6} xl={3}>
                                <CostCard
                                    svg={item.svg}
                                    title={item.title}
                                    color={item?.color}
                                    alt={item?.alt}
                                    text={item?.text}
                                    totalAmount={inputs?.total}
                                    totalFix={inputs?.total}
                                    total={item.total}
                                    priceSymbol="dollar"
                                    value={item?.type === 'total' ? inputs?.total?.[item?.name] : inputs[item.name]}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </div>
            <div className="details-form cost-summary">
                <Box className="laod-space" />
                <Box className="load-form">
                    <Box className="basic-banner">
                        <div className="load-subtitle flex">
                            <h4 className="subtitile-basic">Fuel Consumption</h4>
                            <HtmlToolTip text={'information'} />
                        </div>
                    </Box>
                    <Grid container columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box className="fuel-card">
                                <div className="text-fuel">
                                    <span>Fuel Consumption </span>
                                </div>
                                <div className="text-info">
                                    <span className="text-bold">{truckCosts?.mpg} </span>
                                    <span className="text-gallon"> /gallon </span>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="details-form">
                <form onSubmit={handleSubmit(onSubmit)} className="form-messages">
                    <Box className="laod-space" />
                    <Box className="load-form">
                        <Box className="basic-banner space-between">
                            <div className="load-title basic-title flex">
                                <h4 className="load-basic flex">Per Mile Costs</h4>
                                <HtmlToolTip text={'information'} />
                            </div>
                            <CardBtn
                                className={'basic-btn'}
                                editInput={editInput}
                                handleEdit={handleEdit}
                                handleChancel={handleChancel}
                                load={loadSave}
                            />
                        </Box>

                        <div className="row-flex-able">
                            <div className="row-width">
                                <Box className="basic-banner">
                                    <div className="load-subtitle">
                                        <h4 className="subtitile-basic">Tires Cost</h4>
                                    </div>
                                </Box>
                                <div className="row-flex-response">
                                    <div className="input-right">
                                        <CustomInput
                                            name="numberOfTire"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Number of Tires"
                                            placeholder={'Tire number'}
                                        />
                                    </div>
                                    <div className="input-right">
                                        <CustomInput
                                            name="tireCost"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Tire Cost"
                                            placeholder={'Tire cost'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <CustomInput
                                        name="tireChangeMiles"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type="number"
                                        label="Tire change"
                                        placeholder={'Miles'}
                                        step={'numeric'}
                                        lastIcon={'ml'}
                                    />
                                </div>
                            </div>

                            <div className="row-width">
                                <Box className="basic-banner">
                                    <div className="load-subtitle">
                                        <h4 className="subtitile-basic ">Brake Cost</h4>
                                    </div>
                                </Box>
                                <div className="row-flex-response">
                                    <div className="input-right">
                                        <CustomInput
                                            name="numberOfBrake"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Number of Brake"
                                            placeholder={'Brake number'}
                                        />
                                    </div>
                                    <div className="input-right">
                                        <CustomInput
                                            name="brakeCost"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Brake Cost"
                                            placeholder={'Brake cost'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <CustomInput
                                        name="brakeChangeMiles"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type="number"
                                        label="Brake Miles"
                                        placeholder={'Brake miles'}
                                        step={'numeric'}
                                        lastIcon={'ml'}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row-flex-able">
                            <div className="row-width">
                                <Box className="basic-banner">
                                    <div className="load-subtitle">
                                        <h4 className="subtitile-basic">Oil Change Cost</h4>
                                    </div>
                                </Box>
                                <div className="row-flex-response">
                                    <div className="input-right">
                                        <CustomInput
                                            name="oilCost"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Oil Cost"
                                            placeholder={'Oil cost'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <CustomInput
                                        name="oilChangeMiles"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type="number"
                                        label="Miles"
                                        placeholder={'Miles'}
                                        step={'numeric'}
                                        lastIcon={'ml'}
                                    />
                                </div>
                            </div>

                            <div className="row-width">
                                <Box className="basic-banner">
                                    <div className="load-subtitle">
                                        <h4 className="subtitile-basic">Depreciation</h4>
                                    </div>
                                </Box>
                                <div className="row-flex-response">
                                    <div className="input-right">
                                        <CustomInput
                                            name="truckCost"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Truck Cost"
                                            placeholder={'Oil cost'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <div className="input-right">
                                        <CustomInput
                                            name="truckSalePrice"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Sale Price"
                                            placeholder={'Sale price'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <CustomInput
                                        name="truckMilesLimit"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type="number"
                                        label="Truck Miles Limit"
                                        placeholder={'Miles'}
                                        step={'numeric'}
                                        lastIcon={'ml'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row-width">
                            <Box className="basic-banner">
                                <div className="load-subtitle">
                                    <h4 className="subtitile-basic">Other Costs</h4>
                                </div>
                            </Box>
                            <CustomInput
                                name="otherMileCost"
                                control={control}
                                rules={{ required: true }}
                                disabled={editInput}
                                type="number"
                                label="Miles"
                                placeholder={'Per Miles'}
                                step={'numeric'}
                                dollar={true}
                            />
                        </div>

                        {/*<div className='row-width'>*/}
                        {/*    <Box className='basic-banner'>*/}
                        {/*        <div className='load-subtitle'>*/}
                        {/*            <h4 className='subtitile-basic'>Depreciation</h4>*/}
                        {/*        </div>*/}
                        {/*    </Box>*/}
                        {/*    <div className='row-flex-response'>*/}
                        {/*        <div className='input-right'>*/}
                        {/*            <CustomInput*/}
                        {/*                name='truckCost'*/}
                        {/*                control={control}*/}
                        {/*                rules={{ required: true }}*/}
                        {/*                disabled={editInput}*/}
                        {/*                type='number'*/}
                        {/*                label='Truck Cost'*/}
                        {/*                placeholder={'Oil cost'}*/}
                        {/*                step={'numeric'}*/}
                        {/*                dollar={true}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className='input-right'>*/}
                        {/*            <CustomInput*/}
                        {/*                name='truckSalePrice'*/}
                        {/*                control={control}*/}
                        {/*                rules={{ required: true }}*/}
                        {/*                disabled={editInput}*/}
                        {/*                type='number'*/}
                        {/*                label='Sale Price'*/}
                        {/*                placeholder={'Sale price'}*/}
                        {/*                step={'numeric'}*/}
                        {/*                dollar={true}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <CustomInput*/}
                        {/*            name='truckMilesLimit'*/}
                        {/*            control={control}*/}
                        {/*            rules={{ required: true }}*/}
                        {/*            disabled={editInput}*/}
                        {/*            type='number'*/}
                        {/*            label='Truck Miles Limit'*/}
                        {/*            placeholder={'Miles'}*/}
                        {/*            step={'numeric'}*/}
                        {/*            lastIcon={'mi'}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="row-flex-able">
                            <div className="row-width">
                                <Box className="basic-banner space-between">
                                    <div className="load-title basic-title flex">
                                        <h4 className="load-basic flex">Fix Cost</h4>
                                        <HtmlToolTip text={'information'} />
                                    </div>
                                </Box>
                                <div className="load-subtitle">
                                    <h4 className="subtitile-basic">Per Month Costs</h4>
                                </div>

                                <div className="row-flex-response">
                                    <div className="input-right">
                                        <CustomInput
                                            name="insuranceCost"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Insurance"
                                            placeholder={'Insurance cost'}
                                            dollar={true}
                                            step={'numeric'}
                                        />
                                    </div>
                                    <div className="input-right">
                                        <CustomInput
                                            name="parkingCost"
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type="number"
                                            label="Parking"
                                            placeholder={'Parking cost'}
                                            dollar={true}
                                            step={'numeric'}
                                        />
                                    </div>
                                    <CustomInput
                                        name="otherFixCost"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type="number"
                                        label="Other month costs"
                                        placeholder={'Cost '}
                                        dollar={true}
                                        step={'numeric'}
                                    />
                                </div>
                            </div>

                            <div className="row-width">
                                <Box className="basic-banner space-between">
                                    <div className="load-title basic-title flex">
                                        <h4 className="load-basic flex">Fuel Consumption </h4>
                                        <HtmlToolTip text={'information'} />
                                    </div>
                                </Box>
                                <div className="load-subtitle">
                                    <h4 className="subtitile-basic">&nbsp;</h4>
                                </div>
                                <CustomInput
                                    name="mpg"
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={editInput}
                                    type="number"
                                    label="MPG"
                                    placeholder={'Amount'}
                                    step={'numeric'}
                                    dollar={true}
                                />
                            </div>
                        </div>
                    </Box>
                </form>
            </div>
        </div>
    );
};
