import { MinLoader } from 'components/loaders';
import { FindLoad } from 'utils';

export const SubmitButton = ({ title, actionType, type, onClick, classNameWrapper, classNameText, icon }) => {
    const load = FindLoad(actionType);

    return (
        <button
          className={`add-button ${classNameWrapper? classNameWrapper: ""}`}
          disabled={!!load?.length}
          type={type ? type : 'button'} onClick={onClick}>
            {load?.length
              ? <MinLoader color={'white'} />
              :icon
                ? (
                  <div className='button-icon-wrapper'>
                    <img className='button-icon' src={icon} alt='icon' />
                    <span
                      className={`button-text ${classNameText? classNameText: ''}`}>
                          {title}
                    </span>
                  </div>
                )
                :(
                  <span
                className={`button-text ${classNameText? classNameText: ''}`}>
                  {title}
                </span>
                )
            }
        </button>
    );
};
