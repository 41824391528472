/* Action */

import {
  GET_DRIVERS,
  CHANGE_DRIVER_STATUS,
  CREATE_DRIVERS,
  EDIT_DRIVER_DETAILS,
  DELETE_DRIVERS,
  GET_DRIVER_DETAILS,
  EDIT_DRIVERS_RATE,
  GET_DRIVERS_RATE,
  CREATE_DRIVER_DOCUMENT,
  EDIT_DRIVER_DOCUMENT_ARCHIVE,
  DELETE_DRIVER_DOCUMENT,
  GET_DRIVER_DOCUMENT,
  DRIVER_CREATE_FUEL,
  DRIVER_EDIT_FUEL,
  DRIVER_DELETE_FUEL,
  REIVITE_DRIVERS,
  CREATE_DRIVER_TIMESHEET,
  GET_DRIVER_TIMESHEET,
  GET_DRIVER_SINGLE_TIMESHEET,
  UPDATE_DRIVER_TIMESHEET,
  SEND_TO_PROCESS_DRIVER_TIMESHEET,
} from './drivers.type';

/** Driver Actions */
export const createDrivers = (body) => {
    return {
        type: CREATE_DRIVERS,
        payload: { body },
    };
};

export const getDriver = (body, load) => {
    return {
        type: GET_DRIVERS,
        payload: { body, load },
    };
};

export const changeDriverStatus = (id, status) => {
    return {
        type: CHANGE_DRIVER_STATUS,
        payload: { id, status },
    };
};

export const editDriverDetails = (body, id) => {
    return {
        type: EDIT_DRIVER_DETAILS,
        payload: { body, id },
    };
};

export const getDriverDetails = (id, body, load) => {
    return {
        type: GET_DRIVER_DETAILS,
        payload: { id, body, load },
    };
};

export const deleteDriver = (id) => {
    return {
        type: DELETE_DRIVERS,
        payload: id,
    };
};
/** End */

/** Driver Rate */
export const editDriverRate = (body, id) => {
    return {
        type: EDIT_DRIVERS_RATE,
        payload: { body, id },
    };
};

export const getDriverRate = (id, body, load) => {
    return {
        type: GET_DRIVERS_RATE,
        payload: { id, body, load },
    };
};
/** End */

/** Driver Fuel */
export const createFuel = (body, id) => {
    return {
        type: DRIVER_CREATE_FUEL,
        payload: { body, id },
    };
};

export const editFuel = (body, driverId, id) => {
    return {
        type: DRIVER_EDIT_FUEL,
        payload: { body, driverId, id },
    };
};

export const deleteFuel = (driverId, id) => {
    return {
        type: DRIVER_DELETE_FUEL,
        payload: { driverId, id },
    };
};
/** End */

/** Driver Documents */
export const createDriverDocument = (id, body) => {
    return {
        type: CREATE_DRIVER_DOCUMENT,
        payload: { id, body },
    };
};

export const getDriverDocuments = (id) => {
    return {
        type: GET_DRIVER_DOCUMENT,
        payload: { id },
    };
};

export const editDriverDocumentArchive = (id, docId, archive) => {
    return {
        type: EDIT_DRIVER_DOCUMENT_ARCHIVE,
        payload: { id, docId, archive },
    };
};

export const deleteDriverDocument = (id, docId) => {
    return {
        type: DELETE_DRIVER_DOCUMENT,
        payload: { id, docId },
    };
};

/** End */

export const reInvite = (id) => {
    return {
        type: REIVITE_DRIVERS,
        payload: { id },
    };
};

/** Driver Timesheet */

export const getDriverTimesheet = (driverId, params) => {
  return {
    type: GET_DRIVER_TIMESHEET,
    payload: {
      driverId,
      params
    }
  }
}

export const getDriverSingleTimesheet = (id) => {
  return {
    type: GET_DRIVER_SINGLE_TIMESHEET,
    payload: {
      id,
    }
  }
}

export const createDriverTimesheet = (data) => {
    return {
        type: CREATE_DRIVER_TIMESHEET,
        payload: {
          data
      }
    }
}

export const updateDriverTimesheet = (id, data) => {
    return {
        type: UPDATE_DRIVER_TIMESHEET,
        payload: {
          id,
          data
      }
    }
}
export const sendToProcessing = (payrollIds) => {
    return {
        type: SEND_TO_PROCESS_DRIVER_TIMESHEET,
        payload: {
          payrollIds
      }
    }
}

