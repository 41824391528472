import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useModal, DropdownSelect, Loader, Documents, GoBackButton } from 'components';
import { Box, Tab, Tabs, Button } from '@mui/material';
import { driverDocumentsList, dropdownStatus } from './constants';
import { driversActions } from 'store';
import { BasicInfo, DriverRate, DriverFuel, TimesheetDriver } from './fragments';
import { FindLoad, MODAL_NAMES } from 'utils';
import { NotesSection } from '../../fragments';

export const DriverDetails = () => {
    const { driversDetailsList, driversRateList, driverDocuments } = useSelector((state) => ({
        driversDetailsList: state?.drivers?.driversDetailsList,
        driversRateList: state?.drivers?.driversRateList,
        driverDocuments: state?.drivers?.driverDocuments,
    }));

    const { open } = useModal();
    const dispatch = useDispatch();
    const paramsRouter = useParams();
    const loader = FindLoad('GET_DRIVER_DETAILS');
    const [valueTab, setValueTab] = useState('info');
    const [status, setStatus] = useState('');
    const fuelList = driversDetailsList?.fuelCards;
    const rateList = driversDetailsList?.drierRate;

    useEffect(() => {
        dispatch(driversActions.getDriverDetails(paramsRouter.id));
        dispatch(driversActions.getDriverRate(paramsRouter.id));
        dispatch(driversActions.getDriverDocuments(paramsRouter.id));
    }, []);

    useEffect(() => {
        if (driversDetailsList) {
            setStatus(driversDetailsList?.status);
        }
    }, [driversDetailsList]);

    const handleChange = (event, newValueTab) => {
        setValueTab(newValueTab);
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
        const info = {
            ...driversDetailsList,
        };
        info.status = event.target.value;
        if (driversDetailsList?.address?.formattedAddress) {
            info.address = driversDetailsList?.address?.formattedAddress;
        }
        dispatch(driversActions.changeDriverStatus(info?.id, info.status));
    };

    const handleDelete = () => {
        open(MODAL_NAMES.DELETE_MODAL, {
            title: `Driver ${driversDetailsList?.displayId} `,
            subTitle: 'Deleting a driver will permanently remove it from the system',
            actionType: 'DELETE_DRIVERS',
            historyPush: '/drivers',
            handleDelete: () => handleDeleteDriver('DELETE_DRIVERS'),
        });
    };

    const handleDeleteDriver = () => {
        dispatch(driversActions.deleteDriver(paramsRouter.id));
    };

    const handleUploadFile = (dock) => {
        const file = {
            file: {
                ...dock?.file
            },
            status: 'CURRENT',
            type: dock?.type,
        };
        dispatch(driversActions.createDriverDocument(paramsRouter.id, file));
    };

    const handleDeleteUpload = (dock) => {
        dispatch(driversActions.deleteDriverDocument(paramsRouter.id, dock?.id));
    };

    const handleSetStatus = (dock) => {
        const status = dock?.status === 'CURRENT';
        dispatch(driversActions.editDriverDocumentArchive(paramsRouter.id, dock?.id, status));
    };

    if (loader?.length) {
        return <Loader height={'100vh'} />;
    }

    return (
        <div className='driver-info info-container'>
            <div className='driver-header info-header'>
                <div className='info-router'>
                    <GoBackButton title={'Driver'} link={'/drivers'} />
                </div>
                <div className='driver-banner'>
                    <div className='banner-box'>
                        <div className='header-title'>
                            <h4 className='title'>
                                Driver <span>{driversDetailsList?.displayId}</span>
                            </h4>
                        </div>
                        <div className='header-btn'>
                            <div className='wrapper-btn'>
                                <div className='popper-btn'>
                                    <div className='header-select'>
                                        <DropdownSelect
                                            value={status}
                                            setStatus={setStatus}
                                            handleChange={handleChangeStatus}
                                            options={dropdownStatus}
                                            idInput={'status'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='driver-tab'>
                    <Box className='tab-btns'>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={valueTab} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab label='Basic Info' value='info' />
                                <Tab label='Timesheet' value='timesheet' />
                                <Tab label='Notes' value='notes' />
                            </Tabs>
                        </Box>
                    </Box>
                </div>
            </div>
            <div className='driver-main info-main'>
                {valueTab === 'info' && (
                    <Box className='tab-btns'>
                        <BasicInfo params={paramsRouter} detailsList={driversDetailsList} />
                        <DriverRate driversRateList={driversRateList} rateList={rateList} />
                        <DriverFuel fuelList={fuelList} />

                        <div className='document-container'>
                            <Documents
                                docks={driverDocuments?.length ? driverDocuments : null}
                                actionType={'CREATE_DRIVER_DOCUMENT'}
                                deleteType={'DELETE_DRIVER_DOCUMENT'}
                                changeStatus={'EDIT_DRIVER_DOCUMENT_ARCHIVE'}
                                activeStatus={'CURRENT'}
                                typeList={driverDocumentsList}
                                handleDelete={handleDeleteUpload}
                                handleUpload={handleUploadFile}
                                handleSetStatus={handleSetStatus}
                            />
                        </div>

                        <div className='box-footer'>
                            <Button variant='text' color='error' onClick={handleDelete}>
                                <span className='text-error'>Delete Driver</span>
                            </Button>
                        </div>
                    </Box>
                )}

                {valueTab === 'notes' &&
                    <NotesSection type={'driver'} />
                }

                {valueTab === 'timesheet' &&
                  <TimesheetDriver />
                }
            </div>
        </div>
    );
};
