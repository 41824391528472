import React, { useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export const TooltipMain = ({ text, content, lineClamp, allowLength }) => {
  const targetRef = useRef();
  const textLength = text?.length;
  const disableHoverListener = (textLength < allowLength);

  return (
    <div className='main-tooltip-wrapper'>
      <Tooltip
        title={content}
        disableHoverListener={disableHoverListener}
        arrow={true}
      >
        <p
          className='main-tooltip-text'
          style={{ WebkitLineClamp: lineClamp }}
          ref={targetRef}
        >
          {text}
        </p>

      </Tooltip>
    </div>
  );
};


