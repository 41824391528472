import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccordionTable,
  FullTable,
  Loader, MinLoader, NoDataComponent,
  PaginationItem,
  TimesheetRightModal,
} from 'components';
import { TimesheetCard } from 'fragments';
import { AddPayroll } from './fragments';
import { FindLoad, FindSuccess, SendPageSave, useModalBase, useWindowDimensions } from 'utils';
import { Svg } from 'assets/images';

import {
  payrollDriverBody,
  payrollDriverHeader,
  // payrollTimesheetBody,
  // payrollTimesheetHeader,
} from './fragments/constants';

// import { Drawer } from '@mui/material';

import { FilterPayroll } from './fragments';
import { httpRequestsOnSuccessActions, payrollActions } from 'store';
import { timesheetBody, timesheetHeader } from '../drivers/constants';
import { useForm, useWatch } from 'react-hook-form';


const GET_PAYROLL_TIMESHEET = 'GET_PAYROLL_TIMESHEET';
const GET_PAYROLL_SINGLE_TIMESHEET = 'GET_PAYROLL_SINGLE_TIMESHEET';
const CREATE_PAYROLL_TIMESHEET = 'CREATE_PAYROLL_TIMESHEET';
const UPDATE_PAYROLL_TIMESHEET = 'UPDATE_PAYROLL_TIMESHEET';
const SEND_TO_PROCESS_PAYROLL_TIMESHEET = 'SEND_TO_PROCESS_PAYROLL_TIMESHEET';

export const PayrollPage = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedTimesheetList, setSelectedTimesheetList] = useState([]);
  // const [selectedDriverList, setSelectedDriverList] = useState([]);
  const [currentTimesheetId, setCurrenTimesheetId] = useState('');
  const [date, setDate] = useState({
    startDate: null,
    sendData: null,
  });

  const { control } = useForm({
    defaultValues: {},
  });

  const driverId = useWatch({
    control,
    name: 'driverId',
  });


  const dispatch = useDispatch();

  const { timesheetList, singleTimesheet } = useSelector((state) => state?.payrolls);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const { openModal, closeModal } = useModalBase();

  const info = history?.location?.state;

  const loader = FindLoad(GET_PAYROLL_TIMESHEET);
  const rightModalLoader = FindLoad(GET_PAYROLL_SINGLE_TIMESHEET);
  const processLoading = FindLoad(SEND_TO_PROCESS_PAYROLL_TIMESHEET);
  const createdSuccess = FindSuccess(CREATE_PAYROLL_TIMESHEET);
  const updatedSuccess = FindSuccess(UPDATE_PAYROLL_TIMESHEET);
  const processingSuccess = FindSuccess(SEND_TO_PROCESS_PAYROLL_TIMESHEET);

  const sendData = useMemo(() => {
    const filteredInfo = {
      ...info,
    };

    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 10;
    filteredInfo.status = info?.status === 'Processing' ? 'PROCESSED' : 'ACTIVE';

    if (date?.startDate) {
      let startDate = new Date(date?.startDate);
      startDate.setHours('00');
      startDate.setMinutes('00');
      startDate.setSeconds('01');
      filteredInfo.startDate = new Date(startDate + 'Z');
    } else {
      delete filteredInfo.startDate;
    }

    if (date?.endDate) {
      const endDate = new Date(date?.endDate);
      endDate.setHours('23');
      endDate.setMinutes('59');
      endDate.setSeconds('59');
      filteredInfo.endDate = new Date(endDate + 'Z');
    } else {
      delete filteredInfo.endDate;
    }


    delete filteredInfo?.page;
    return filteredInfo;

  }, [info, date]);


  useEffect(() => {
    if (createdSuccess?.type || updatedSuccess?.type || processingSuccess?.type) {
      closeModal();
      dispatch(payrollActions.getPayrollTimesheet(sendData));
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_PAYROLL_TIMESHEET));
      dispatch(httpRequestsOnSuccessActions.removeSuccess(UPDATE_PAYROLL_TIMESHEET));
      dispatch(httpRequestsOnSuccessActions.removeSuccess(SEND_TO_PROCESS_PAYROLL_TIMESHEET));
    }
  }, [createdSuccess, updatedSuccess, processingSuccess]);

  useEffect(() => {
    dispatch(payrollActions.getPayrollTimesheet(sendData));
    closeModal();
  }, [info?.status, info?.page, info?.limit, info?.skip]);


  useEffect(() => {
    if (driverId) {
      dispatch(payrollActions.getPayrollDriverTimesheet(driverId, sendData));
    }
  }, [driverId, info?.status, info?.page]);


  const handleChangeDate = (dates) => {
    const [start, end] = dates;
    setDate({
      startDate: start,
      endDate: end,
    });
  };

  const handleSaveDate = () => {
    dispatch(payrollActions.getPayrollTimesheet(sendData));
    setShowCalendar(false);
    closeModal();
  };

  const handleCancelDate = () => {
    const params = { ...sendData };
    if (params?.startDate) delete params?.startDate;
    if (params?.endDate) delete params?.endDate;

    setDate({
      startDate: null,
      sendData: null,
    });

    dispatch(payrollActions.getPayrollTimesheet(params));
    setShowCalendar(false);
    closeModal();
  };


  const handleCheck = (ev, item, name) => {
    if (name === 'all') {
      const allList = [];
      if (ev?.target?.checked || ev === true) {
        timesheetList?.timesheets.map((i) => allList.push(i?.id));
      } else {
        setSelectedTimesheetList([]);
      }

      setSelectedTimesheetList(allList);
    } else {

      let list = [...selectedTimesheetList];

      if (ev.target.checked) {
        list.push(item?.id);
      } else {
        list = list.filter((f) => f !== item?.id);
      }
      setSelectedTimesheetList(list);
    }
  };


  const handleClickActiveTabs = () => {
    setCurrenTimesheetId('');
  };

  const changePage = (number) => {
    SendPageSave(number, info, history);
    setSelectedTimesheetList([]);
    setCurrenTimesheetId('');
  };

  const sendToProcessingSingle = (id) => {
    dispatch(payrollActions.sendToProcessing([id]));
    setSelectedTimesheetList([]);
    setCurrenTimesheetId('');
  };

  const sendToProcessing = (selectedList) => {
    dispatch(payrollActions.sendToProcessing(selectedList));
    setSelectedTimesheetList([]);
    setCurrenTimesheetId('');
  };

  const getRightModalDetails = (id) => {
    dispatch(payrollActions.getPayrollSingleTimesheet(id));
    setCurrenTimesheetId(id);
  };

  return (
    <div className='payroll-page'>
      <div style={{ width: '100%' }}>
        <div className='payroll-filters-block'>

          {(selectedTimesheetList.length > 0 && selectedTimesheetList.length === timesheetList?.timesheets.length && width >= 768)
            ? <div className='items-are-selected'>
              <div className='items-are-selected-svg-text'>
                <Svg.CloseButton
                  className='items-are-selected-svg'
                  onClick={(e) => handleCheck(e, '', 'all')}
                />

                <p className='items-are-selected-text'>
                  {selectedTimesheetList.length} items are selected.
                </p>
              </div>
              <button
                className='items-are-selected-button'
                disabled={processLoading}
                onClick={() => sendToProcessing(selectedTimesheetList)}
              >
                {processLoading
                  ? <MinLoader />
                  : 'Send to processing'
                }

              </button>
            </div>
            : ((selectedTimesheetList.length > 0 && selectedTimesheetList.length < timesheetList?.timesheets.length) && width < 768)
              ? <div className='select-all-cancel-buttons'>
                <button
                  className='select-all-button'
                  onClick={() => handleCheck(true, {}, 'all')}
                >
                  Select All
                </button>

                <button
                  className='cancel-button'
                  onClick={(e) => handleCheck(e, {}, 'all')}
                >
                  Cancel
                </button>
              </div>
              : ((selectedTimesheetList.length > 0 && selectedTimesheetList.length === timesheetList?.timesheets.length) && width < 768)
                ? <div className='select-all-cancel-buttons'>
                  <button
                    className='select-all-button'
                    onClick={() => sendToProcessing(selectedTimesheetList)}
                  >
                    {processLoading
                      ? <MinLoader />
                      : 'Send to Processing'
                    }
                  </button>

                  <button
                    className='cancel-button'
                    onClick={(e) => handleCheck(e, {}, 'all')}
                  >
                    Cancel
                  </button>
                </div>

                : <FilterPayroll
                  onClickTabs={handleClickActiveTabs}
                  onClickAddButton={() => openModal(<AddPayroll headerText='Add Time' />)}
                  handleChangeDate={handleChangeDate}
                  handleSaveDate={handleSaveDate}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                  control={control}
                  date={date}
                  handleCancelDate={handleCancelDate}
                />
          }
        </div>

        {(width >= 1279) &&
          <div className='main-content-desktop'>
            <FullTable
              handleClickButton={(id) => {
                setCurrenTimesheetId(id);
              }}
              handleClick={(id) => getRightModalDetails(id)}
              head={
                payrollDriverHeader({
                  onChange: handleCheck,
                  checked: ((timesheetList?.timesheets.length > 0) && (selectedTimesheetList.length === timesheetList?.timesheets.length)),
                  active: !(info?.status?.toUpperCase() === 'PROCESSING'),
                })
              }
              body={
                payrollDriverBody({
                  onChange: handleCheck,
                  active: !(info?.status?.toUpperCase() === 'PROCESSING'),
                  selectedDriverList: selectedTimesheetList,
                })
              }
              list={timesheetList?.timesheets}
              loadingType={GET_PAYROLL_TIMESHEET}
            />

            {(currentTimesheetId && width > 1279)
              &&
              <TimesheetRightModal
                singleTimesheet={singleTimesheet}
                loading={rightModalLoader}
                onClose={() => setCurrenTimesheetId('')}
                path='payroll'
                active={!(info?.status?.toUpperCase() === 'PROCESSING')}
                openModal={() => openModal(<AddPayroll headerText='Edit Time' />)}
                onProcessingClick={sendToProcessingSingle}
                actionType={SEND_TO_PROCESS_PAYROLL_TIMESHEET}
              />
            }
          </div>
        }


        {
          (((width < 1279 && width > 767)))
          &&
          <div className='main-content-tablet'>
            <AccordionTable
              handleClickButton={(id) => {
                setCurrenTimesheetId(id);
              }}
              handleClick={(id) => getRightModalDetails(id)}
              head={
                payrollDriverHeader({
                  onChange: handleCheck,
                  checked: ((timesheetList?.timesheets.length > 0) && (selectedTimesheetList.length === timesheetList?.timesheets.length)),
                  active: !(info?.status?.toUpperCase() === 'PROCESSING'),
                })}
              body={
                payrollDriverBody({
                  onChange: handleCheck,
                  active: !(info?.status?.toUpperCase() === 'PROCESSING'),
                  selectedDriverList: selectedTimesheetList,
                })}
              list={timesheetList?.timesheets}
              loadingType={GET_PAYROLL_TIMESHEET}
              accordionDetailsComp={
                <TimesheetRightModal
                  classNameWrapper='timesheet-details-accordion'
                  classNameHeader='timesheet-details-header-accordion'
                  classNameItems='timesheet-details-items-wrapper'
                  classNameNotesWrapper='timesheet-details-notes-wrapper'
                  lineClampNotes={1}
                  loading={rightModalLoader}
                  actionType={SEND_TO_PROCESS_PAYROLL_TIMESHEET}
                  singleTimesheet={singleTimesheet}
                  path='payroll'
                  allowCloseButton={false}
                  openModal={() => openModal(<AddPayroll headerText='Edit Time' />)}
                  active={!(info?.status?.toUpperCase() === 'PROCESSING')}
                  onProcessingClick={sendToProcessingSingle}
                />
              }

            />
          </div>
        }
        {
          (width <= 767)
          &&
          <div className='main-content-mobile'>
            {loader ? <Loader />
              : (timesheetList?.timesheets.length > 0)
                ? timesheetList?.timesheets.map((item) => (
                  <TimesheetCard
                    key={item?.id}
                    item={item}
                    path='payroll'
                    lineClampNotes={3}
                    actionType='SEND_TO_PROCESS_PAYROLL_TIMESHEET'
                    active={!(info?.status?.toUpperCase() === 'PROCESSING')}
                    onChange={(e) => handleCheck(e, item)}
                    checked={selectedTimesheetList.includes(item?.id)}
                    onProcessingClick={sendToProcessingSingle}
                    onEdit={() => {
                      dispatch(payrollActions.getPayrollSingleTimesheet(item?.id));
                      setCurrenTimesheetId(item?.id);
                      openModal(<AddPayroll headerText='Edit Time' />);
                    }}
                  />
                ))
                : <div style={{ height: '100%', width: '100%' }}>
                  <NoDataComponent />
                </div>
            }
          </div>
        }

        {/*{(width > 767) &&*/}

        {/*  <div className='payroll-main-content-tablet'>*/}
        {/*    <AccordionTable*/}
        {/*      handleClick={(id) => {*/}

        {/*      }}*/}
        {/*      head={*/}
        {/*        payrollDriverHeader({*/}
        {/*          onChange: handleCheck,*/}
        {/*          checked: selectedDriverList.length === 2,*/}
        {/*          active: !info?.status || info?.status === 'Active',*/}
        {/*        })}*/}
        {/*      body={*/}
        {/*        payrollDriverBody({*/}
        {/*          onChange: handleCheck,*/}
        {/*          checked: selectedDriverList.length === 2,*/}
        {/*          active: !info?.status || info?.status === 'Active',*/}
        {/*          selectedDriverList: selectedDriverList,*/}
        {/*          path: 'payroll',*/}
        {/*        })}*/}
        {/*      list={[{ id: 1, date: '02.04.2023', totalHours: '56h 41m', totalPay: '40000000' }, {*/}
        {/*        id: 2,*/}
        {/*        date: '02.04.2053',*/}
        {/*        totalHours: '56h 61m',*/}
        {/*        totalPay: '40050000',*/}
        {/*      }]}*/}
        {/*      loadingType={'4454'}*/}
        {/*      accordionDetailsComp={*/}
        {/*        <FullTable*/}
        {/*          handleClick={(id) => {*/}
        {/*            setCurrenTimesheetId(id);*/}
        {/*          }}*/}
        {/*          head={*/}
        {/*            payrollTimesheetHeader({*/}
        {/*              onChange: handleCheck,*/}
        {/*              checked: selectedDriverList.length === 2,*/}
        {/*              active: !(info?.status?.toUpperCase() === 'PROCESSING'),*/}
        {/*            })}*/}
        {/*          body={*/}
        {/*            payrollTimesheetBody(*/}
        {/*              {*/}
        {/*                onChange: handleCheck,*/}
        {/*                checked: selectedDriverList.length === 2,*/}
        {/*                selectedList: selectedDriverList,*/}
        {/*                active: !(info?.status?.toUpperCase() === 'PROCESSING'),*/}
        {/*              },*/}
        {/*            )*/}
        {/*          }*/}
        {/*          // loadingType={ACTION_TYPE}*/}
        {/*          list={[{ id: 1, date: '02.04.2023', totalHours: '56h 41m', totalPay: '40000000' }, {*/}
        {/*            id: 2,*/}
        {/*            date: '02.04.2053',*/}
        {/*            totalHours: '56h 61m',*/}
        {/*            totalPay: '40050000',*/}
        {/*          }]}*/}
        {/*          // listCount={driversList?.count}*/}
        {/*          // noText={title}*/}
        {/*          // handleClickButton={handleClick}*/}
        {/*          // handleClick={(id) => history.push({ pathname: `/driver/${id}` })}*/}
        {/*          className='timesheet-full-table'*/}
        {/*          // classNameTbodyRow={`${currentTimesheetId === 2 ? 'tbody-table-row-active' : ''}`}*/}
        {/*        />*/}
        {/*      }*/}

        {/*    />*/}

        {/*    {(currentTimesheetId && width > 1279) &&*/}
        {/*      <TimesheetRightModal*/}
        {/*        classNameWrapper='payroll-right-modal'*/}
        {/*        closeModal={setCurrenTimesheetId}*/}
        {/*        active={!info?.status || info?.status === 'Active'}*/}
        {/*        openModal={() => openModal(<AddPayroll headerText='Edit Time' />)}*/}
        {/*      />*/}
        {/*    }*/}

        {/*    {(currentTimesheetId && width <= 1279) &&*/}

        {/*      <Drawer*/}
        {/*        anchor={'bottom'}*/}
        {/*        open={!!currentTimesheetId}*/}
        {/*        onClose={() => setCurrenTimesheetId('')}*/}
        {/*      >*/}
        {/*        <TimesheetRightModal*/}
        {/*          classNameWrapper='payroll-drawer-modal'*/}
        {/*          classNameHeader='payroll-drawer-modal-header'*/}
        {/*          classNameItems='payroll-drawer-items-wrapper'*/}
        {/*          classNameNotesWrapper='payroll-drawer-notes-wrapper'*/}
        {/*          lineClampNotes={1}*/}
        {/*          closeModal={setCurrenTimesheetId}*/}
        {/*          active={!(info?.status?.toUpperCase() === 'PROCESSING')}*/}
        {/*          openModal={() => openModal(<AddPayroll headerText='Edit Time' />)}*/}
        {/*        />*/}
        {/*      </Drawer>*/}

        {/*    }*/}

        {/*  </div>*/}
        {/*}*/}
        {/*{*/}
        {/*  (width <= 767)*/}
        {/*  &&*/}
        {/*  <div className='payroll-main-content-mobile'>*/}
        {/*    <TimesheetCard*/}
        {/*      active={!(info?.status?.toUpperCase() === 'PROCESSING')}*/}
        {/*      onChange={(e) => handleCheck(e, 1)}*/}
        {/*      checked={selectedDriverList.includes(1)}*/}
        {/*    />*/}
        {/*    <TimesheetCard*/}
        {/*      active={!(info?.status?.toUpperCase() === 'PROCESSING')}*/}
        {/*      onChange={(e) => handleCheck(e, 2)}*/}
        {/*      checked={selectedDriverList.includes(2)}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
      {!!timesheetList?.timesheets.length && !loader &&
        <div style={{ marginTop: 20, width: '100%' }}>
          <PaginationItem
            listLength={timesheetList?.timesheets?.length}
            page={info?.page}
            handleReturn={(number) => changePage(number)}
            count={timesheetList?.count}
          />
        </div>
      }
    </div>

  )
    ;
};

