import { AddButton, FullTable } from 'components';
import { maintenanceBody, maintenanceHead, ACTION_TYPE } from './constants';
import { useModalBase } from 'utils';
import { AddMaintenance } from './addMaintenance';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { truckActions } from 'store';
import { RightModal } from './rightModal';
import * as Styled from './styled';
import './maintenance.scss';

export const Maintenance = () => {
    const [openRightModal, setOpenRightModal] = useState(false);
    const [maintenanceId, setMaintenanceId] = useState()
    const dispatch = useDispatch();
    const history = useHistory();
    const truck_id = useParams();
    const { maintenance, singleMaintenance } = useSelector(({ truck }) => truck);
    const { openModal } = useModalBase();
    const info = history?.location?.state;

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 10;

        dispatch(truckActions.getTruckMaintenance({ id: truck_id?.id, ...filteredInfo }));

    }, [dispatch, info]);

    useEffect(()=> {
        if (singleMaintenance?.id) {
            setMaintenanceId(singleMaintenance?.id)
        }

    },[singleMaintenance?.id])

    return (
        <div className="right-modal-wrapper">
            <div>
                <div className="flex-end add-service-button">
                    <AddButton
                      addText={'Add Service'}
                      handleAdd=
                        {() => openModal(
                                 <AddMaintenance
                                   classNameWrapper="maintenance-modal-wrapper"
                                   classNameBody="maintenance-modal-body-wrapper"
                                   setOpenRightModal={setOpenRightModal}
                                 />
                        )
                    }
                    />
                </div>
                <FullTable
                    head={maintenanceHead}
                    body={maintenanceBody}
                    loadingType={ACTION_TYPE}
                    list={maintenance.data}
                    listCount={maintenance.count}
                    noText={'Maintenance'}
                    handleClick={(id) => {
                        setOpenRightModal(id);
                        setMaintenanceId(id)
                    }}
                />
                {maintenance?.data &&
                  <Styled.CardContainer className="mobile-view">
                    {maintenance?.data?.map((item, index) => (
                      <div key={index}>
                          <Styled.Card key={index} onClick={() => setOpenRightModal(item.id)}>
                              <div>
                                  <span>Service Name: </span>
                                  <span>{item?.serviceName}</span>
                              </div>
                              <div>
                                  <span>Date: </span>
                                  <span>{item?.serviceDate}</span>
                              </div>
                              <div>
                                  <span>Vendor: </span>
                                  <span>{item?.vendor}</span>
                              </div>
                              <div>
                                  <span>Total Cost: </span>
                                  <span>${item?.totalCost}</span>
                              </div>
                          </Styled.Card>
                      </div>
                    ))}
                </Styled.CardContainer>}

            </div>

          {(openRightModal) &&
            <RightModal
            id={openRightModal}
            open={maintenanceId}
            setOpen={setOpenRightModal}
          />}

        </div>
    );
};
